import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  loadAllPayee,
  openPayeeInModal,
} from "../../redux/ui/form/payeeUISlice";
import PayeeList from "../payees/PayeeList";
import PayeeListPager from "../payees/PayeeListPager";

const Payees = () => {
  const dispatch = useDispatch();

  // Initial data fetching
  useEffect(() => {
    const fetchData = async () => {
      //Load data from database
      await dispatch(loadAllPayee());
    };

    fetchData();
  }, []);

  // Render
  return (
    <div className="container-full-page flat search-page">
      <div className="container-header">
        <Button
          basic
          icon="plus"
          labelPosition="left"
          content="New"
          onClick={() => dispatch(openPayeeInModal())}
        />
      </div>

      <PayeeList />
      <PayeeListPager />
    </div>
  );
};

export default Payees;
