import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultKind } from "../../../entities/Transaction";
import { selectDefaultTransactionFormState } from "../../entities/transactionsSelectors";
import { savePayeeSuccess } from "../../entities/payeeSlice";
import ndApi from "../../../common/ndApi";
import { loadAllAccounts } from "./accountsUISlice";
import { loadRecentTransactions } from "../transaction/recentTransactionSlice";
import { loadFilteredTransactions } from "../transaction/filterTransactionSlice";
import { toast } from "react-toastify";
import {
  loadAccountDetail,
  loadAccountDetailAndTransactions,
  loadAccountTransactions,
} from "../accountdetail/accountDetailUISlice";

// Async Thunk Actions
export const resetTransactionFormState = createAsyncThunk(
  "resetTxForm",
  async (_, { getState, dispatch }) => {
    const initialData = await selectDefaultTransactionFormState(getState());
    await dispatch(fillInTransactionForm(initialData));
  }
);

export const insertTransaction = createAsyncThunk(
  "transaction/insert",
  async (transaction, { getState, dispatch }) => {
    const response = await ndApi
      .post("transaction/insert.php", transaction)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("Insert error: " + err);
      });

    console.log(response.data);
    if (response.data.success === 1 && response.data.nd_api_data > 0) {
      transaction.tx_id = response.data.nd_api_data;
      await dispatch(resetTransactionFormState());
      dispatch(loadAllAccounts());
      dispatch(loadRecentTransactions());
      dispatch(loadFilteredTransactions());
      dispatch(loadAccountDetailAndTransactions());
      toast.success("Transaction added susccessfully");
    } else {
      toast.error("Insert transaction failed");
    }
  }
);

export const updateTransaction = createAsyncThunk(
  "transaction/update",
  async (transaction, { getState, dispatch }) => {
    const response = await ndApi
      .post("transaction/update.php", transaction)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("Insert error: " + err);
      });

    console.log(response.data);
    if (response.data.success === 1 && response.data.nd_api_data > 0) {
      transaction.tx_id = response.data.nd_api_data;
      await dispatch(resetTransactionFormState());
      dispatch(loadAllAccounts());
      dispatch(loadRecentTransactions());
      dispatch(loadFilteredTransactions());
      dispatch(loadAccountDetailAndTransactions());
      toast.success("Transaction updated susccessfully");
    } else {
      toast.error("Update transaction failed");
    }
  }
);

export const deleteTransaction = createAsyncThunk(
  "transaction/delete",
  async (tx_id, { getState, dispatch }) => {
    const response = await ndApi
      .post("transaction/delete.php", { tx_id: tx_id })
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("Insert error: " + err);
      });

    console.log(response.data);
    if (response.data.success === 1 && response.data.nd_api_data > 0) {
      await dispatch(resetTransactionFormState());
      dispatch(loadAllAccounts());
      dispatch(loadRecentTransactions());
      dispatch(loadFilteredTransactions());
      toast.success("Transaction deleted susccessfully");
    } else {
      toast.error("Delete transaction failed");
    }
  }
);

//Initial States
const initialState = {
  tx_type: defaultKind,
  isModalOpen: false,
  isDeleteRequested: false,
  isTxInserting: false,
  isSaving: false,
  isDeleting: false,
};

// Slice
const transactionsUISlice = createSlice({
  name: "transactionsUI",
  initialState,
  reducers: {
    fillInTransactionForm: (state, { payload }) => payload,
    openTransactionInModal: (state, { payload }) => ({
      ...state,
      ...payload,
      isModalOpen: true,
    }),
    duplicateTransaction: (state) => {
      state.tx_id = undefined;
    },
    requestDeleteTransaction: (state, { payload }) => {
      state.isDeleteRequested = payload;
    },
    changeTransactionType: (state, { payload }) => {
      if (state.tx_type !== payload) {
        state.tx_cat_id = null;
        state.tx_payee_id = null;
        state.tx_type = payload;
      }
    },
    changeAccount: (state, { payload }) => {
      state.tx_acc_id = payload;
    },
    changeAmount: (state, { payload }) => {
      state.tx_amount = payload;
      state.tx_linked_amount = payload;
    },
    changeLinkedAccount: (state, { payload }) => {
      state.tx_linked_acc_id = payload;
    },
    changeLinkedAmount: (state, { payload }) => {
      state.tx_linked_amount = payload;
      state.tx_amount = payload;
    },
    changePayee: (state, { payload }) => {
      state.tx_payee_id = payload;
    },
    changeCategory: (state, { payload }) => {
      state.tx_cat_id = payload;
    },
    changeNote: (state, { payload }) => {
      state.tx_note = payload;
    },
    changeDate: (state, { payload }) => {
      state.tx_date = payload;
    },
  },

  extraReducers: {
    [savePayeeSuccess]: (state, { payload }) => {
      state.tx_payee_id = payload.py_id;
    },

    [insertTransaction.pending]: (state) => {
      state.isSaving = true;
    },
    [insertTransaction.fulfilled]: (state) => {
      state.isSaving = false;
    },
    [insertTransaction.rejected]: (state) => {
      state.isSaving = false;
    },

    [updateTransaction.pending]: (state) => {
      state.isSaving = true;
    },
    [updateTransaction.fulfilled]: (state) => {
      state.isSaving = false;
    },
    [updateTransaction.rejected]: (state) => {
      state.isSaving = false;
    },

    [deleteTransaction.pending]: (state) => {
      state.isDeleting = true;
    },
    [deleteTransaction.fulfilled]: (state) => {
      state.isDeleting = false;
    },
    [deleteTransaction.rejected]: (state) => {
      state.isDeleting = false;
    },
  },
});

export default transactionsUISlice.reducer;

export const {
  changeAmount,
  changeAccount,
  changeTransactionType,
  changeLinkedAccount,
  changeLinkedAmount,
  changePayee,
  changeCategory,
  changeNote,
  changeDate,
  fillInTransactionForm,
  openTransactionInModal,
  requestDeleteTransaction,
  duplicateTransaction,
} = transactionsUISlice.actions;
