import React from "react";
import ContentLoader from "react-content-loader";

const LoaderListRtl = (isRtl = false) => {
  return (
    <ContentLoader
      rtl
      speed={2}
      width={400}
      height={180}
      viewBox="0 0 400 180"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {/* <circle cx="10" cy="20" r="8" /> */}
      <rect x="45" y="25" rx="5" ry="5" width="300" height="15" />
      {/* <circle cx="10" cy="50" r="8" /> */}
      <rect x="45" y="65" rx="5" ry="5" width="300" height="15" />
      {/* <circle cx="10" cy="80" r="8" /> */}
      <rect x="45" y="105" rx="5" ry="5" width="300" height="15" />
      {/* <circle cx="10" cy="110" r="8" /> */}
      <rect x="45" y="145" rx="5" ry="5" width="300" height="15" />
    </ContentLoader>
  );
};

export default LoaderListRtl;
