import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Header, Modal } from "semantic-ui-react";
import {
  deleteTransaction,
  duplicateTransaction,
  requestDeleteTransaction,
  resetTransactionFormState,
} from "../../redux/ui/form/transactionsUISlice";
import TransactionForm from "./TransactionForm";

const ModalTransactionForm = (props) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ui.form.transaction.isModalOpen);
  const isDeleteRequested = useSelector(
    (state) => state.ui.form.transaction.isDeleteRequested
  );
  const isDeleting = useSelector(
    (state) => state.ui.form.transaction.isDeleting
  );
  const isSaving = useSelector((state) => state.ui.form.transaction.isSaving);

  const txId = useSelector((state) => state.ui.form.transaction.tx_id);
  const isEdit = txId !== undefined;

  const handleClose = () => {
    dispatch(resetTransactionFormState());
  };

  const handleCancelDelete = () => {
    dispatch(requestDeleteTransaction(false));
  };

  const handleDuplicate = () => {
    dispatch(duplicateTransaction());
  };

  const handleDelete = () => {
    if (isDeleteRequested) {
      dispatch(deleteTransaction(txId));
    } else {
      dispatch(requestDeleteTransaction(true));
    }
  };

  return (
    <Modal
      closeIcon
      size="small"
      className="transaction"
      open={isOpen}
      onClose={handleClose}
    >
      <Header
        icon="file text outline"
        content={isEdit ? "Edit Transaction" : "New Transaction"}
      />
      <Modal.Content>
        <TransactionForm />
      </Modal.Content>
      {isEdit && (
        <Modal.Actions>
          {!isDeleteRequested && (
            <Button
              icon="copy"
              secondary
              content="Duplicate"
              labelPosition="right"
              onClick={() => handleDuplicate()}
              disabled={isSaving || isDeleting}
            />
          )}

          <Button
            negative
            icon="trash"
            content={isDeleteRequested ? "Confirm Delete" : "Delete"}
            labelPosition="right"
            onClick={() => handleDelete()}
            disabled={isSaving || isDeleting}
            loading={isDeleting}
          />

          {isDeleteRequested && (
            <Button
              icon="close"
              content="cancel"
              labelPosition="right"
              onClick={() => handleCancelDelete()}
              disabled={isDeleting}
            />
          )}
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default ModalTransactionForm;
