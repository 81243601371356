import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Form, Progress, Radio } from "semantic-ui-react";
import {
  defaulPayeetDeleteStrategy,
  DeletePayeeStrategyT,
  getDeletePayeeStartegyOptions,
} from "../../entities/Payee";
import { getPayeeAsOptions } from "../../redux/entities/payeeSelectors";
import {
  getPayeeFormState,
  getPayeeModalState,
} from "../../redux/ui/form/payeeUISelectors";
import { removePayee } from "../../redux/ui/form/payeeUISlice";

const PayeeDeleteStrategy = () => {
  const dispatch = useDispatch();

  //Selectors
  const formState = useSelector((state) => getPayeeFormState(state));
  const modalState = useSelector((state) => getPayeeModalState(state));
  const otherPayeeOptions = useSelector((state) =>
    getPayeeAsOptions(state).filter((payee) => payee.key !== formState.py_id)
  );
  const hasMultiplePyees = otherPayeeOptions.length > 0;

  // Local State
  const [state, setState] = useState({
    strategy: defaulPayeetDeleteStrategy,
    moveTo: otherPayeeOptions.length > 0 && otherPayeeOptions[0].key,
  });

  // Funcs
  const getMoveTo = () =>
    state.moveTo || (otherPayeeOptions.length > 0 && otherPayeeOptions[0].key);

  // Events
  const onStrategyChange = (value) => {
    setState({ ...state, strategy: value });
  };
  const onPayeeChange = (value) => {
    setState({ ...state, moveTo: value });
  };
  const onProceed = () => {
    dispatch(
      removePayee({
        deleteStrategy: state.strategy,
        pyId: formState.py_id,
        moveToPyId: state.moveTo,
      })
    );
  };

  // Render
  return (
    <>
      <h3>You are about to delete payee "{formState.py_name}"</h3>
      <p style={{ marginTop: "1em" }}>
        What should we do with transactions linked to this payee?
      </p>

      <Form>
        {getDeletePayeeStartegyOptions(hasMultiplePyees).map((strategy) => (
          <Form.Field key={strategy.key}>
            <Radio
              name="deleteStrategy"
              label={strategy.text}
              value={strategy.value}
              checked={state.strategy === strategy.value}
              onChange={(_, { value }) => onStrategyChange(value)}
              disabled={modalState.isDeleting}
            />
          </Form.Field>
        ))}

        {hasMultiplePyees && state.strategy === DeletePayeeStrategyT.Move && (
          <Form.Field>
            <Dropdown
              selection
              value={getMoveTo()}
              options={otherPayeeOptions}
              onChange={(_, { value }) => onPayeeChange(value)}
              disabled={modalState.isDeleting}
            />
          </Form.Field>
        )}

        <Form.Field>
          <Button
            negative
            labelPosition="right"
            icon="arrow right"
            content="Proceed"
            onClick={() => onProceed()}
            disabled={modalState.isDeleting}
            loading={modalState.isDeleting}
          />
        </Form.Field>
      </Form>
    </>
  );
};

export default PayeeDeleteStrategy;
