import EntityMap from "../../entities/EntityMap";
import { createSlice } from "@reduxjs/toolkit";

//Initial States
const initialState = {
  recent: EntityMap.fromArray([]),
  filter: EntityMap.fromArray([]),
};

//Slice
const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    // saveTransactionSuccess: (state, { payload }) => {
    // const transaction = payload;
    // return EntityMap.set(state, transaction, "tx_id");
    // return {
    //   ...state,
    //   [Expense]: EntityMap.fromArray(categories, "cat_id"),
    // };
    // },
    loadRecentTransactionsSuccess: (state, { payload }) => {
      const transactions = payload;
      return {
        ...state,
        recent: EntityMap.fromArray(transactions, "tx_id"),
      };
    },
    loadFilteredTransactionsSuccess: (state, { payload }) => {
      const transactions = payload;
      return {
        ...state,
        filter: EntityMap.fromArray(transactions, "tx_id"),
      };
    },
  },
});

export default transactionSlice.reducer;

export const {
  loadRecentTransactionsSuccess,
  loadFilteredTransactionsSuccess,
} = transactionSlice.actions;
