import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCategoryRequest,
  resetCategoryForm,
} from "../../redux/ui/form/categoriesUISlice";
import { getCategoryModalState } from "../../redux/ui/form/categoriesUISelectors";
import { Button, Header, Modal } from "semantic-ui-react";
import CategoryForm from "./CategoryForm";
import CategoryDeleteStrategy from "./CategoryDeleteStrategy";

const ModalCategoryForm = () => {
  const dispatch = useDispatch();

  // Selectors
  const modalState = useSelector((state) => getCategoryModalState(state));
  const isEdit =
    useSelector((state) => state.ui.form.category.cat_id) !== undefined;

  // Events
  const reset = () => {
    // if (this.props.modal.isDeleteRunning) return false;
    dispatch(resetCategoryForm());
  };

  // Render funs
  const renderModalActions = () => {
    if (!isEdit) return;

    return modalState.isDeleteRequest ? (
      <Button
        labelPosition="left"
        onClick={() => dispatch(removeCategoryRequest())}
        content="Cancel"
        icon="cancel"
        disabled={modalState.isDeleting}
      />
    ) : (
      <Button
        negative
        labelPosition="right"
        onClick={() => dispatch(removeCategoryRequest())}
        content="Delete"
        icon="trash"
        disabled={modalState.isDeleting || modalState.isSaving}
      />
    );
  };

  // Render
  return (
    <Modal
      closeIcon
      size="small"
      className="account-form"
      open={modalState.isOpen}
      onClose={reset}
    >
      <Header
        icon="file text outline"
        content={isEdit ? "Edit Category" : "New Category"}
      />

      <Modal.Content>
        {modalState.isDeleteRequest ? (
          <CategoryDeleteStrategy />
        ) : (
          <CategoryForm />
        )}
      </Modal.Content>

      <Modal.Actions>{renderModalActions()}</Modal.Actions>
    </Modal>
  );
};

export default ModalCategoryForm;
