import { createSelector } from "reselect";
import EntityMap from "../../entities/EntityMap";

export const getCategoryGroupList = (state, txType) =>
  EntityMap.map(state.entities.categoryGroups[txType], (categoryGroups) => ({
    ...categoryGroups,
  })).sort(
    // sortByName
    // sort by cg_name
    (a, b) => {
      if (a.cg_name < b.cg_name) return -1;
      if (a.cg_name > b.cg_name) return 1;
      return 0;
    }
  );

export const getCategoryGroupOptions = createSelector(
  getCategoryGroupList,
  (catGoups) => {
    // console.log(`catGoups`, catGoups);
    const options = [];
    for (const cg of catGoups) {
      options.push({
        key: cg.cg_id,
        value: cg.cg_id,
        text: cg.cg_name,
      });
    }
    return options;
  }
);
