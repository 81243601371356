import React from "react";
import { Form } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { changeName, savePayee } from "../../redux/ui/form/payeeUISlice";
import { formToDb } from "../../entities/Payee";

const PayeeForm = () => {
  const dispatch = useDispatch();

  // Selectors
  const formState = useSelector((state) => state.ui.form.payee);
  const isSaving = useSelector((state) => state.ui.form.payee.isSaving);

  // Events.
  const handleSubmit = (e) => {
    e.preventDefault();
    const payee = formToDb(formState);
    console.log("formToState(account)", payee);
    dispatch(savePayee(payee));
  };

  return (
    <Form className="account-form" onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Input
          width={16}
          required
          label="Name"
          placeholder="Payee name"
          value={formState.py_name}
          onChange={(e, { value }) => dispatch(changeName(value))}
        />
      </Form.Group>

      <Form.Group>
        <Form.Field width={11}></Form.Field>
        <Form.Button
          width={5}
          primary
          fluid
          loading={isSaving}
          disabled={isSaving}
          content={formState.py_id ? "Save Payee" : "Add Payee"}
        />
      </Form.Group>
    </Form>
  );
};

export default PayeeForm;
