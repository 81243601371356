import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ndApi from "../../../common/ndApi";
import { defaultKind, TransationTypeT } from "../../../entities/Transaction";
import noCache from "../../../utils/noCache";
import {
  loadExpenseCategoriesSuccess,
  loadIncomeCategoriesSuccess,
  removeCategorySuccess,
  saveCategorySuccess,
} from "../../entities/categoriesSlice";
import { saveCategoryGroupSuccess } from "../../entities/categoryGroupSlice";
import { loadFilteredTransactions } from "../transaction/filterTransactionSlice";

const { Expense, Transfer, Income } = TransationTypeT;

// Async Thunk Actions
export const loadAllCategories = createAsyncThunk(
  "category/loadAll",
  async (_, { dispatch }) => {
    const response = await ndApi
      .post(noCache("category/getByTxType.php"), { cg_tx_type: Expense })
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("loadExpenseCategories Axios error: " + err);
      });

    if (response.data.success === 1) {
      const cats = response.data.nd_api_data;
      await dispatch(loadExpenseCategoriesSuccess(cats));
    } else {
      toast.error("loadExpenseCategories failed: " + response.data.error);
    }

    const response2 = await ndApi
      .post("category/getByTxType.php", { cg_tx_type: Income })
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("loadIncomeCategories Axios error: " + err);
      });

    if (response2.data.success === 1) {
      const cats = response2.data.nd_api_data;
      await dispatch(loadIncomeCategoriesSuccess(cats));
    } else {
      toast.error("loadIncomeCategories failed: " + response.data.error);
    }
  }
);

export const saveCategory = createAsyncThunk(
  "category/insertOrUpdate",
  async (cat, { dispatch }) => {
    const isInsertingCategory = cat.cat_id === undefined;
    const queryUrl = isInsertingCategory ? "insert.php" : "update.php";

    const response = await ndApi
      .post("category/" + queryUrl, cat)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("saveCategory Axios error: " + err);
      });

    console.log("response.data", response.data);

    if (response.data.success === 1 && response.data.nd_api_data > 0) {
      if (isInsertingCategory) {
        cat.cat_id = response.data.nd_api_data;
        // await dispatch(saveCategorySuccess());
        toast.success("Category '" + cat.cat_name + "' added susccessfully");
      } else {
        toast.success("Category '" + cat.cat_name + "' updated susccessfully");
      }
      console.log(`Category`, cat);
      // await dispatch(saveCategorySuccess(cat));
      await dispatch(resetCategoryForm());
      await dispatch(loadAllCategories());
    } else {
      toast.error("Category save failed");
    }
  }
);

export const removeCategory = createAsyncThunk(
  "category/remove",
  async ({ catTxType, catId, moveToCatId }, { dispatch }) => {
    const response = await ndApi
      .post("category/deleteMovingTransactions.php", {
        cat_id: catId,
        moveToCatId: moveToCatId,
      })
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("removeCategory Axios error: " + err);
      });

    if (response.data.transactionsMoved.success === 1) {
      toast.success(
        response.data.transactionsMoved.nd_api_data +
          " Transactions-Category links moved susccessfully"
      );
      await dispatch(loadFilteredTransactions());
    } else {
      toast.error("Transactions-Category link move failed");
    }

    if (response.data.categoryDeleted.success === 1) {
      toast.success("Category deleted susccessfully");
      await dispatch(
        removeCategorySuccess({ catId: catId, catTxType: catTxType })
      );
    } else {
      toast.error("Category delete failed");
    }
  }
);

//Initial States
const initialState = {
  cat_name: "",
  cat_cg_id: undefined,
  cg_tx_type: defaultKind,
  completed: false,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
  isModalOpen: false,
  isDeleteRequest: false,
};

// Slice
const categoryUISlice = createSlice({
  name: "categoryUI",
  initialState,
  reducers: {
    resetCategoryForm: () => initialState,

    changeName: (state, { payload }) => {
      state.cat_name = payload;
    },

    changeCategoryGroup: (state, { payload }) => {
      state.cat_cg_id = payload;
    },

    changeCategoryTxType: (state, { payload }) => {
      state.cg_tx_type = payload;
      state.cat_cg_id = null;
    },

    openCategoryInModal: (state, { payload }) => ({
      ...state,
      ...payload,
      isModalOpen: true,
    }),

    removeCategoryRequest: (state) => ({
      ...state,
      isDeleteRequest: !state.isDeleteRequest,
    }),
  },
  extraReducers: {
    [saveCategoryGroupSuccess]: (state, { payload }) => {
      state.cat_cg_id = payload.cg_id;
    },

    [removeCategorySuccess]: () => ({
      ...initialState,
      completed: true,
    }),

    [loadAllCategories.pending]: (state) => {
      state.isLoading = true;
    },
    [loadAllCategories.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [loadAllCategories.rejected]: (state) => {
      state.isLoading = false;
    },

    [saveCategory.pending]: (state) => {
      state.isSaving = true;
    },
    [saveCategory.fulfilled]: (state) => {
      state.isSaving = false;
    },
    [saveCategory.rejected]: (state) => {
      state.isSaving = false;
    },

    [removeCategory.pending]: (state) => {
      state.isDeleting = true;
    },
    [removeCategory.fulfilled]: (state) => {
      state.isDeleting = false;
    },
    [removeCategory.rejected]: (state) => {
      state.isDeleting = false;
    },
  },
});

export default categoryUISlice.reducer;

export const {
  changeCategoryGroup,
  changeCategoryTxType,
  resetCategoryForm,
  changeName,
  openCategoryInModal,
  removeCategoryRequest,
} = categoryUISlice.actions;
