import Currency from "../../entities/Currency";

export const barChartOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += Currency.rupeePaisaString(context.parsed.y);
            // label += new Intl.NumberFormat("en-IN", {
            //   style: "currency",
            //   currency: "INR",
            // }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, values) {
          return Currency.rupeeString(value);
        },
      },
    },
  },
};
