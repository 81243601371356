import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Form, Progress, Radio } from "semantic-ui-react";
import {
  defaultDeleteStrategy,
  DeleteStrategyT,
  getDeleteAccountStartegyOptions,
} from "../../entities/Account";
import { getAccountsAsOptions } from "../../redux/entities/accountsSelectors";
import { removeAccount } from "../../redux/ui/form/accountsUISlice";
import {
  getAccountFormState,
  getModalState,
} from "../../redux/ui/form/accountUISelectors";

const AccountDeleteStrategy = () => {
  const dispatch = useDispatch();

  //Selectors
  const formState = useSelector((state) => getAccountFormState(state));
  const modalState = useSelector((state) => getModalState(state));

  const otherAccountOptions = useSelector((state) =>
    getAccountsAsOptions(state).filter(
      (option) => option.key !== formState.acc_id
    )
  );
  const hasMultipleAccounts = otherAccountOptions.length > 0;

  // Local State
  const [state, setState] = useState({
    strategy: defaultDeleteStrategy,
    moveTo: otherAccountOptions.length > 0 && otherAccountOptions[0].key,
  });

  // Funcs
  const getMoveTo = () =>
    state.moveTo ||
    (otherAccountOptions.length > 0 && otherAccountOptions[0].key);

  // Events
  const onStrategyChange = (value) => {
    setState({ ...state, strategy: value });
  };
  const onAccountChange = (value) => {
    setState({ ...state, moveTo: value });
  };
  const onProceed = () => {
    dispatch(
      removeAccount({
        deleteStrategy: state.strategy,
        accId: formState.acc_id,
        moveToAccId: state.moveTo,
      })
    );
  };

  // Render
  return (
    <>
      <h3>You are about to delete account "{formState.acc_name}"</h3>
      <p style={{ marginTop: "1em" }}>
        What should we do with transactions linked to this account?
      </p>

      <Form>
        {getDeleteAccountStartegyOptions(hasMultipleAccounts).map(
          (strategy) => (
            <Form.Field key={strategy.key}>
              <Radio
                name="deleteStrategy"
                label={strategy.text}
                value={strategy.value}
                checked={state.strategy === strategy.value}
                onChange={(_, { value }) => onStrategyChange(value)}
                disabled={modalState.isDeleting}
              />
            </Form.Field>
          )
        )}

        {hasMultipleAccounts && state.strategy === DeleteStrategyT.Move && (
          <Form.Field>
            <Dropdown
              selection
              value={getMoveTo()}
              options={otherAccountOptions}
              onChange={(_, { value }) => onAccountChange(value)}
              disabled={modalState.isDeleting}
            />
          </Form.Field>
        )}

        <Form.Field>
          {modalState.isDeleteRunning ? (
            <Progress
              value={modalState.itemsProcessed}
              total={modalState.itemsProcessed}
            />
          ) : (
            <Button
              negative
              labelPosition="right"
              icon="arrow right"
              content="Proceed"
              onClick={() => onProceed()}
              disabled={modalState.isDeleting}
              loading={modalState.isDeleting}
            />
          )}
        </Form.Field>
      </Form>
    </>
  );
};

export default AccountDeleteStrategy;
