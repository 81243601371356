import { createSelector } from "reselect";
import EntityMap from "../../entities/EntityMap";
import { rowsPerPage } from "../../entities/Transaction";
import { getPage } from "../ui/accountdetail/accountDetailUISelectors";

export const selectAccountTransactionsState = (state) =>
  state.entities.accountTransactions;

export const selectAccountTransactions = createSelector(
  selectAccountTransactionsState,
  getPage,
  (transactions, page) =>
    EntityMap.map(
      transactions,
      (transaction) => transaction,
      rowsPerPage,
      (page - 1) * rowsPerPage
    )
);

export const getAccountTransactionExpenseTotal = createSelector(
  selectAccountTransactionsState,
  (transactions) =>
    EntityMap.filter(transactions, (tx) => tx.tx_net_amount < 0).reduce(
      (total, tx) => total + tx.tx_amount,
      0
    )
);

export const getAccountTransactionIncomeTotal = createSelector(
  selectAccountTransactionsState,
  (transactions) =>
    EntityMap.filter(transactions, (tx) => tx.tx_net_amount > -1).reduce(
      (total, tx) => total + tx.tx_amount,
      0
    )
);
