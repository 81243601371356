import { configureStore } from "@reduxjs/toolkit";
// import accountReducer from "./entities/accountsSlice";
import entitiyReducers from "./entities/entityReducers";
import settingsReducer from "./settings/settingsSlice";
import uiReducers from "./ui/uiReducers";

export const store = configureStore({
  reducer: {
    entities: entitiyReducers,
    ui: uiReducers,
    settings: settingsReducer,
  },
});
