import Currency from "./Currency";

interface AccountBaseT {
  acc_name: number;
  acc_group: AccountGroupT;
  acc_on_dashboard: boolean;
  acc_archived?: boolean;
}

export interface AccountStateT extends AccountBaseT {
  acc_id: number;
  acc_init_balance: number;
}
export interface AccountDbT extends AccountBaseT {
  acc_id?: number;
  acc_init_balance: number;
}


export interface AccountFormT extends AccountBaseT {
  acc_id?: number;
  acc_init_balance: string;
  completed?: boolean;
  isModalOpen?: boolean;
  isDeleteRequest?: boolean;
  isDeleteRunning?: boolean;
  itemsToProcess?: number;
  itemsProcessed?: number
}


export enum AccountGroupT {
  Cash = 'cash',
  Bank = 'bank',
  Deposit = 'deposit',
  Credit = 'credit',
  Asset = 'asset',
  Person = 'person'
}

export enum DeleteStrategyT {
  Archive,
  Cleanup,
  Move
}

export const defaultDeleteStrategy = DeleteStrategyT.Archive;

const GroupToTextMap: { [code in AccountGroupT]?: string } = {
  [AccountGroupT.Cash]: 'Cash',
  [AccountGroupT.Bank]: 'Bank Account',
  [AccountGroupT.Deposit]: 'Deposit',
  [AccountGroupT.Credit]: 'Credit',
  [AccountGroupT.Asset]: 'Asset',
  [AccountGroupT.Person]: 'Person'
};

export const defaultGroup = AccountGroupT.Cash;

export function getGroupName(code: AccountGroupT) {
  return GroupToTextMap[code];
}

export function getAccountGroupOptions() {
  return Object.entries(GroupToTextMap).map(([code, text]) => ({
    key: code,
    value: code,
    text
  }));
}

export function getDeleteAccountStartegyOptions(hasMultipleAccounts = false) {
  const stratgies = [
    {
      key: DeleteStrategyT.Archive,
      value: DeleteStrategyT.Archive,
      text: 'Archive account, keep transactions as is'
    },
    {
      key: DeleteStrategyT.Cleanup,
      value: DeleteStrategyT.Cleanup,
      text: 'Delete transactions with account'
    }
  ];
  if (hasMultipleAccounts) {
    stratgies.push({
      key: DeleteStrategyT.Move,
      value: DeleteStrategyT.Move,
      text: 'Move transactions to another account'
    });
  }
  
  return stratgies;
}

export function formToState({
  acc_id, acc_init_balance, acc_name, acc_group, acc_on_dashboard, acc_archived
}: AccountFormT) : AccountStateT {
  return {
    acc_id: acc_id || 1 ,
    acc_init_balance: Currency.stringToNumber(acc_init_balance),
    acc_name,
    acc_group,
    acc_on_dashboard,
    acc_archived
  };
}

export function formToDb({
  acc_id, acc_init_balance, acc_name, acc_group, acc_on_dashboard, acc_archived
}: AccountFormT) : AccountDbT {
  return {
    acc_id: acc_id || undefined,
    acc_init_balance: Currency.stringToNumber(acc_init_balance),
    acc_name,
    acc_group,
    acc_on_dashboard,
    acc_archived
  };
}

export function stateToForm(account: AccountStateT): AccountFormT {
  return {
    ...account,
    acc_init_balance: Currency.numberToString(account.acc_init_balance)
  };
}

export function stateToDB({
  acc_id, acc_init_balance, acc_name, acc_group, acc_on_dashboard, acc_archived
}: AccountStateT) {
  return {
    acc_id, acc_init_balance: acc_init_balance, acc_name, acc_group, acc_on_dashboard, acc_archived
  }
}

export function dbToState({
  acc_id, 
  acc_init_balance,
   acc_name, 
   acc_group, 
   acc_on_dashboard, 
   acc_archived
}: AccountDbT): AccountStateT {
  return {
    acc_id: acc_id || -1,
    acc_init_balance: acc_init_balance,
    acc_name,
    acc_group,
    acc_on_dashboard,
    acc_archived
  }
}