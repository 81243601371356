import React from "react";
import { useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import { ReportTypeT } from "../../entities/Report";
import { TransationTypeT } from "../../entities/Transaction";
import ReportCategory from "./ReportCategory";
import ReportIncomeExpense from "./ReportIncomeExpense";
import ReportNetWorth from "./ReportNetWorth";

const ReportContent = () => {
  const isLoading = useSelector((state) => state.ui.report.rpDataIsLoading);
  const reportType = useSelector((state) => state.ui.report.rpType);

  // Render func
  const renderReportByType = () => {
    switch (reportType) {
      case ReportTypeT.ExpenseIncome:
        return <ReportIncomeExpense />;
      case ReportTypeT.NetWorth:
        return <ReportNetWorth />;
      case ReportTypeT.ExpenseCategories:
        return <ReportCategory catType={TransationTypeT.Expense} />;
      case ReportTypeT.IncomeCategories:
        return <ReportCategory catType={TransationTypeT.Income} />;

      default:
        return "Not available";
    }
  };

  // Render
  return (
    <div className="ct-octave mt-report">
      <Loader active={isLoading} />

      {/* {!isLoading && renderReportByType()} */}
      {renderReportByType()}
    </div>
  );
};

export default ReportContent;
