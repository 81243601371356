import format from "date-fns/format";
import React from "react";
import { useDispatch } from "react-redux";
import { Button, Icon, Label } from "semantic-ui-react";
import { dbToState, TransationTypeT } from "../../entities/Transaction";
import { openTransactionInModal } from "../../redux/ui/form/transactionsUISlice";
import Amount from "../Amount";

const { Expense, Transfer, Income } = TransationTypeT;

const TransactionItem = ({ transaction }) => {
  const dispatch = useDispatch();
  const handleEditClick = () => {
    console.log(`dbToState(transaction)`, dbToState(transaction));
    dispatch(openTransactionInModal(dbToState(transaction)));
  };
  const renderArrow = () => {
    const { tx_type, tx_cat_name } = transaction;
    // if (tx_type !== Transfer && !tx_cat_name) return;

    return (
      <Icon
        // size="large"
        color={
          tx_type === Income ? "green" : tx_type === Expense ? "red" : "grey"
        }
        name={
          tx_type === Income
            ? "arrow down"
            : tx_type === Expense
            ? "arrow up"
            : "arrow right"
        }
      />
    );
  };

  // const renderLinkedAmount = () => {
  //   const { tx_linked_amount, tx_cat_name } = transaction;
  // };

  return (
    <div className="transaction-item">
      <div className="transaction-item__date">
        {format(new Date(transaction.tx_date), "dd MMM yyyy")}
      </div>
      <div className="transaction-item__info">
        {/* <Label> */}
        {/* <Icon name="credit card" /> */}
        {transaction.tx_acc_name} {/* </Label> */}
        {renderArrow()}
        {
          transaction.tx_type === Transfer && transaction.tx_linked_acc_name
          // <Label>
          //   <Icon name="credit card" />
          // {transaction.tx_linked_acc_name}{" "}
          // </Label>
          // <Label content={transaction.tx_linked_acc_name} />
        }
        {transaction.tx_cat_name && (
          <Label
            key={transaction.tx_cat_name}
            content={transaction.tx_cat_name}
            // color="orange"
            tag
          />
        )}
        <span className="transaction-item__info__note">
          {transaction.tx_note}
        </span>
      </div>
      <div className="transaction-item__amount">
        <Amount
          value={transaction.tx_net_amount}
          showColor={transaction.tx_type !== Transfer}
        />
        {/* {transaction.tx_type === Transfer && renderLinkedAmount()} */}
      </div>
      <div className="transaction-item__edit">
        <Button
          circular
          basic
          icon={transaction.archived ? "archive" : "pencil"}
          onClick={() => handleEditClick()}
          disabled={transaction.archived}
        />
      </div>
    </div>
  );
};

export default TransactionItem;
