import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ndApi from "../../../common/ndApi";
import { DeletePayeeStrategyT } from "../../../entities/Payee";
import noCache from "../../../utils/noCache";
import { savePayeeSuccess } from "../../entities/payeeSlice";
import {
  insertPayeeSuccess,
  loadPayeeSuccess,
  removePayeeSuccess,
} from "../payee/filteredPayeeSlice";
import { loadFilteredTransactions } from "../transaction/filterTransactionSlice";

// Async Thunk Actions
export const loadAllPayee = createAsyncThunk(
  "payee/getAll",
  async (_, { dispatch, getState }) => {
    const response = await ndApi
      .get(noCache("payee/getAll.php"))
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("loadAllPayee Axios error: " + err);
      });

    if (response.data.success === 1) {
      const payee = response.data.nd_api_data;
      await dispatch(loadPayeeSuccess(payee));
    } else {
      toast.error("loadAllPayee failed: " + response.data.error);
    }
  }
);

// export const insertPayee = createAsyncThunk(
//   "payee/insert",
//   async (payee, { dispatch }) => {
//     const response = await ndApi
//       .post("payee/insert.php", payee)
//       .catch((err) => {
//         console.log("Err: ", err);
//       });

//     console.log(response.data);

//     if (response.data.success === 1 && response.data.nd_api_data > 0) {
//       payee.py_id = response.data.nd_api_data;
//       console.log(`payee`, payee);
//       await dispatch(savePayeeSuccess(payee));
//     }
//   }
// );

export const savePayee = createAsyncThunk(
  "payee/insertOrUpdate",
  async (payee, { dispatch }) => {
    const isInsertingPayee = payee.py_id === undefined;
    const queryUrl = isInsertingPayee ? "insert.php" : "update.php";
    const response = await ndApi
      .post("payee/" + queryUrl, payee)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("savePayee Axios error: " + err);
      });

    console.log("response.data: ", response.data);

    if (response.data.success === 1 && response.data.nd_api_data > 0) {
      if (isInsertingPayee) {
        payee.py_id = response.data.nd_api_data;
        await dispatch(insertPayeeSuccess());
        toast.success("Payee '" + payee.py_name + "' added susccessfully");
      } else {
        toast.success("Payee '" + payee.py_name + "' updated susccessfully");
      }
      console.log(`payee`, payee);
      await dispatch(savePayeeSuccess(payee));
      await dispatch(resetPayeeForm());
    } else if (response.data.success === 0) {
      toast.error("Payee save failed: " + response.data.error);
    } else {
      toast.error("Payee save returned with 0 effected rows ");
    }
  }
);

export const removePayee = createAsyncThunk(
  "payee/remove",
  async ({ deleteStrategy, pyId, moveToPyId }, { dispatch }) => {
    const queryUrl =
      deleteStrategy === DeletePayeeStrategyT.Delete
        ? "delete.php"
        : "deleteMovingTransactions.php";
    const params =
      deleteStrategy === DeletePayeeStrategyT.Delete
        ? { py_id: pyId }
        : { py_id: pyId, moveToPyId: moveToPyId };

    const response = await ndApi
      .post("payee/" + queryUrl, params)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("Axios removePayee error: " + err);
      });

    switch (deleteStrategy) {
      case DeletePayeeStrategyT.Delete:
        if (response.data.success === 1 && response.data.nd_api_data > 0) {
          toast.success("Payee deleted susccessfully");
          await dispatch(removePayeeSuccess(pyId));
        } else {
          toast.error("Payee delete failed");
        }
        break;

      case DeletePayeeStrategyT.Move:
        if (response.data.transactionsMoved.success === 1) {
          toast.success(
            response.data.transactionsMoved.nd_api_data +
              " Transactions-Payee links moved susccessfully"
          );
          await dispatch(loadFilteredTransactions());
        } else {
          toast.error("Transactions-Payee link move failed");
        }
        if (response.data.payeeDeleted.success === 1) {
          toast.success("Payee deleted susccessfully");
          await dispatch(removePayeeSuccess(pyId));
          // await dispatch(getAllAccounts());
        } else {
          toast.error("Payee delete failed");
        }
        break;

      default:
        break;
    }
  }
);

//Initial States
const initialState = {
  py_name: "",
  completed: false,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
  isModalOpen: false,
  isDeleteRequest: false,
};

// Slice
const payeeUISlice = createSlice({
  name: "payeeUI",
  initialState,
  reducers: {
    // Action creator and handler in one function
    changeName: (state, { payload }) => {
      state.py_name = payload;
    },
    openPayeeInModal: (state, { payload }) => ({
      ...state,
      ...payload,
      isModalOpen: true,
    }),
    resetPayeeForm: () => initialState,

    removePayeeRequest: (state) => ({
      ...state,
      isDeleteRequest: !state.isDeleteRequest,
    }),
  },
  extraReducers: {
    [removePayeeSuccess]: () => ({
      ...initialState,
      completed: true,
    }),

    [loadAllPayee.pending]: (state) => {
      state.isLoading = true;
    },
    [loadAllPayee.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [loadAllPayee.rejected]: (state) => {
      state.isLoading = false;
    },

    [savePayee.pending]: (state) => {
      state.isSaving = true;
    },
    [savePayee.fulfilled]: (state) => {
      state.isSaving = false;
    },
    [savePayee.rejected]: (state) => {
      state.isSaving = false;
    },

    [removePayee.pending]: (state) => {
      state.isDeleting = true;
    },
    [removePayee.fulfilled]: (state) => {
      state.isDeleting = false;
    },
    [removePayee.rejected]: (state) => {
      state.isDeleting = false;
    },
  },
});

export default payeeUISlice.reducer;

export const {
  changeName,
  openPayeeInModal,
  resetPayeeForm,
  removePayeeRequest,
} = payeeUISlice.actions;
