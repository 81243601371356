import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ndApi from "../../../common/ndApi";
import { recentListLimit } from "../../../entities/Transaction";
import { loadRecentTransactionsSuccess } from "../../entities/transactionsSlice";

// Async Thunk Actions
export const loadRecentTransactions = createAsyncThunk(
  "transaction/get/recent",
  async (_, { dispatch }) => {
    const response = await ndApi
      .post("transaction/getRecent.php", { limit: recentListLimit })
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("Axios error: " + err);
      });

    console.log(response.data);

    if (response.data.success === 1) {
      const transactions = response.data.nd_api_data;
      await dispatch(loadRecentTransactionsSuccess(transactions));
    } else {
      toast.error("loadRecentTransactions failed: " + response.data.error);
    }
  }
);

//Initial States
const initialState = {
  isLoading: false,
};

//Slice
const recentTransactionSlice = createSlice({
  name: "recentTransaction",
  initialState,
  reducers: {},
  extraReducers: {
    [loadRecentTransactions.pending]: (state) => {
      state.isLoading = true;
    },
    [loadRecentTransactions.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [loadRecentTransactions.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default recentTransactionSlice.reducer;
