import React from "react";
import ContentLoader from "react-content-loader";

const LoaderTwoLine = (isRtl = false) => {
  return (
    <ContentLoader
      speed={2}
      width={150}
      height={40}
      viewBox="0 0 150 40"
      backgroundColor="#f3f3f3"
      // backgroundColor="#ff0000"
      foregroundColor="#ecebeb"
      // foregroundColor="#00ff00"
    >
      <rect x="10" y="5" rx="2" ry="2" width="100" height="15" />
      <rect x="10" y="30" rx="2" ry="2" width="130" height="12" />
    </ContentLoader>
  );
};

export default LoaderTwoLine;
