import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDashboardGroupedAccounts,
  getDashboardGroupedCheckingAccounts,
  getVisibleGroupedAccounts,
} from "../../redux/entities/accountsSelectors";
import AccountGroup from "./AccountGroup";
import { toggleSectionCollapse } from "../../redux/settings/settingsSlice";
import LoaderList from "../LoaderList";

const AccountList = ({ groupedAccounts, openInModal, isDashboard }) => {
  // Selectors
  // const groupsState = useSelector((state) =>
  //   isDashboard
  //     ? getDashboardGroupedCheckingAccounts(state)
  //     : getVisibleGroupedAccounts(state)
  // );
  const collepsedSections = useSelector(
    (state) => state.settings.collapsedSections
  );
  const isLoading = useSelector((state) => state.ui.form.account.isLoading);
  const dispatch = useDispatch();
  const groups = Object.keys(groupedAccounts);
  // console.log("collepsedSections", collepsedSections);

  const toggle = (group) => dispatch(toggleSectionCollapse(group));

  // Render
  if (isLoading) return <LoaderList />;

  return (
    // <div>AccountList</div>
    groups.length > 0 ? (
      <div className="account-widget">
        {groups.map((group) => (
          <AccountGroup
            key={group}
            group={groupedAccounts[group]}
            isCollapsed={collepsedSections.includes(group)}
            toggleGroupCollapse={() => toggle(group)}
            openInModal={openInModal}
          />
        ))}
      </div>
    ) : (
      <div className="transactions-form__empty">
        You don't have any accounts.
      </div>
    )
  );
};

export default AccountList;
