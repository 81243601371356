import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Form, Modal } from "semantic-ui-react";
import { getAccountsAsOptions } from "../redux/entities/accountsSelectors";
import { getAllCategoryOptions } from "../redux/entities/categoriesSelectors";
import { getPayeeAsOptions } from "../redux/entities/payeeSelectors";

const ModalFilterOptions = ({
  isFilterModalOpen,
  appliedAccounts,
  appliedCategories,
  appliedPayees,
  applyFilters,
  toggleFilterModal,
}) => {
  const dispatch = useDispatch();
  // console.log(`appliedAccounts receive`, appliedAccounts);

  // Selectors
  const accountOptions = useSelector((state) => getAccountsAsOptions(state));
  const categoriesOptions = useSelector((state) =>
    getAllCategoryOptions(state)
  );
  const payeeOptions = useSelector((state) => getPayeeAsOptions(state));

  // Local State
  const [selectedFilters, setSelectedFilters] = useState({
    accounts: appliedAccounts,
    categories: appliedCategories,
    payees: appliedPayees,
  });

  useEffect(() => {
    setSelectedFilters({
      accounts: appliedAccounts,
      categories: appliedCategories,
      payees: appliedPayees,
    });

    // console.log("UseEffect");
  }, [appliedAccounts, appliedCategories, appliedPayees]);

  // console.log(`selectedFilters`, selectedFilters);

  // Event handlers
  const handleResetClick = () => {
    setSelectedFilters({ accounts: [], categories: [], payees: [] });
  };

  const handleApplyClick = () => {
    dispatch(applyFilters(selectedFilters));
    dispatch(toggleFilterModal());
  };

  const handleAccountChange = (value) =>
    setSelectedFilters({ ...selectedFilters, accounts: value });
  const handleCategoryChange = (value) =>
    setSelectedFilters({ ...selectedFilters, categories: value });
  const handlePayeeChange = (value) =>
    setSelectedFilters({ ...selectedFilters, payees: value });

  // Render
  return (
    <Modal
      open={isFilterModalOpen}
      onClose={() => dispatch(toggleFilterModal())}
      closeIcon
      size="tiny"
    >
      <Modal.Header>Filter transactions</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Account</label>
              <Dropdown
                multiple
                selection
                search
                closeOnChange
                options={accountOptions}
                value={selectedFilters.accounts}
                onChange={(_, { value }) => handleAccountChange(value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Category</label>
              <Dropdown
                multiple
                selection
                search
                closeOnChange
                options={categoriesOptions}
                value={selectedFilters.categories}
                onChange={(_, { value }) => handleCategoryChange(value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Payee</label>
              <Dropdown
                multiple
                selection
                search
                closeOnChange
                options={payeeOptions}
                value={selectedFilters.payees}
                onChange={(_, { value }) => handlePayeeChange(value)}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Reset" onClick={() => handleResetClick()} />
        <Button content="Apply" onClick={() => handleApplyClick()} positive />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalFilterOptions;
