import React from "react";
import { Icon } from "semantic-ui-react";

const Logo = () => {
  return (
    <div className="logo">
      {/* <img src={logo} alt="Money Tracker" /> */}
      {/* <h2>MT</h2> */}
      <Icon name="money bill alternate outline" size="large" />
    </div>
  );
};

export default Logo;
