import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { openAccountInModal } from "../../redux/ui/form/accountsUISlice";
import AccountList from "../accounts/AccountList";
import ModalAccountForm from "../accounts/ModalAccountForm";
import { loadAllAccounts } from "../../redux/ui/form/accountsUISlice";
import { getVisibleGroupedAccounts } from "../../redux/entities/accountsSelectors";

const Accounts = () => {
  const dispatch = useDispatch();

  const groupedAccounts = useSelector((state) =>
    getVisibleGroupedAccounts(state)
  );

  useEffect(() => {
    //Load Accounts from database
    dispatch(loadAllAccounts());
  }, [dispatch]);

  // if (accId) return <AccountDetail accountId={accId} />;

  return (
    <div className="container-full-page flat">
      <div className="container-header">
        <Button.Group basic>
          <Button
            icon="plus"
            labelPosition="left"
            content="New"
            onClick={() => dispatch(openAccountInModal())}
          />
        </Button.Group>
      </div>

      <div className="accounts-list-wrapper">
        <AccountList groupedAccounts={groupedAccounts} openInModal={true} />
      </div>
      <ModalAccountForm />
    </div>
  );
};

export default Accounts;
