import format from "date-fns/format";
import { createSelector } from "reselect";
import {
  pagerSizeDesktop,
  pagerSizeMobile,
} from "../../../entities/Transaction";
import { toLocalTimestamp } from "../../../utils/timeZone";

export const getDateLabel = (state) => {
  const dateStart = new Date(state.ui.transaction.filter.dateStart);
  const dateEnd = new Date(state.ui.transaction.filter.dateEnd);

  return format(dateStart, "d/M/yy") !== format(dateEnd, "d/M/yy")
    ? `${format(dateStart, "d/M/yy")} - ${format(dateEnd, "d/M/yy")}`
    : format(dateStart, "MMMM, do");
};

export const getFilters = (state) => ({
  date: {
    start: state.ui.transaction.filter.dateStart,
    end: state.ui.transaction.filter.dateEnd,
  },
  accounts: state.ui.transaction.filter.applied.accounts || [],
  categories: state.ui.transaction.filter.applied.categories || [],
  payees: state.ui.transaction.filter.applied.payees || [],
});

export const getPage = (state) => state.ui.transaction.filter.page;

export const getTotalPage = (state) => {
  return Math.ceil(
    state.ui.transaction.filter.totalRows / state.ui.transaction.filter.perPage
  );
};

export const getPagerSize = (state) =>
  state.ui.isMobile ? pagerSizeMobile : pagerSizeDesktop;

// export const getVisiblePages = createSelector(
//   getPage,
//   getLastPage,
//   getPagerSize,
//   (currentPage, lastPage, pagerSize) => {
//     const pages = [];
//     const offset = Math.max(
//       0,
//       lastPage - currentPage - Math.ceil(pagerSize / 2)
//     );
//     const windowRight =
//       lastPage > pagerSize ? Math.max(pagerSize, lastPage - offset) : lastPage;
//     const windowLeft = Math.max(0, windowRight - pagerSize);

//     for (let page = windowLeft; page < windowRight; page++) {
//       pages.push(page);
//     }

//     return pages;
//   }
// );
