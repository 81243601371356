import React from "react";
import { useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";
import { selectAccountTransactions } from "../../redux/entities/accountTransactionsSelectors";
import LoaderList from "../LoaderList";
import ModalTransactionForm from "../transactions/ModalTransactionForm";
import TransactionItem from "../transactions/TransactionItem";
import AccountTransactionItem from "./AccountTransactionItem";

const AccountTransactionList = () => {
  // Selectors
  const transactions = useSelector((state) => selectAccountTransactions(state));
  const isLoadingTx = useSelector(
    (state) => state.ui.accountDetail.isLoadingTx
  );

  return (
    <Segment className="transactions-list__wrapper">
      {isLoadingTx ? (
        <LoaderList />
      ) : transactions.length > 0 ? (
        transactions.map((transaction) => (
          <AccountTransactionItem
            key={transaction.tx_id}
            transaction={transaction}
          />
        ))
      ) : (
        <div className="transactions-list__empty">No transactions found.</div>
      )}
      <ModalTransactionForm />
    </Segment>
  );
};

export default AccountTransactionList;
