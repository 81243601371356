export const getAccountFormState = (state) => state.ui.form.account;

export const getModalState = (state) => ({
  isOpen: state.ui.form.account.isModalOpen,
  isDeleteRequest: state.ui.form.account.isDeleteRequest,
  isDeleting: state.ui.form.account.isDeleting,
  isSaving: state.ui.form.account.isSaving,
  itemsToProcess: state.ui.form.account.itemsToProcess,
  itemsProcessed: state.ui.form.account.itemsProcessed,
});
