import React, { useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "semantic-ui-react";

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 8, 0);
const toMonth = new Date(currentYear + 2, 11);

function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();
  // console.log(`months`, months);

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={i} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year, i) => (
          <option key={i} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}

const ModalDateRangeSelection = ({
  isCalendarOpen,
  changeFilterDate,
  toggleFilterCalendar,
}) => {
  const dispatch = useDispatch();
  const [month, setMonth] = useState(null);
  const [range, setRange] = useState({
    from: null,
    to: null,
  });
  const { from, to } = range;

  const isMobile = useSelector((state) => state.ui.isMobile);

  const handleDayClick = (day) => {
    const dateRange = DateUtils.addDayToRange(day, range);
    setRange(dateRange);
  };

  const handleResetClick = () => {
    setRange({
      from: null,
      to: null,
    });
  };

  const handleApplyClick = () => {
    const { from, to } = range;
    dispatch(
      changeFilterDate({
        dateStart: from.setHours(0, 0, 0),
        dateEnd: (to && to.setHours(23, 59, 59)) || from.setHours(23, 59, 59),
      })
    );
    dispatch(toggleFilterCalendar());
  };

  const handleYearMonthChange = (month) => {
    setMonth(month);
  };

  return (
    <Modal
      open={isCalendarOpen}
      onClose={() => dispatch(toggleFilterCalendar())}
      className="transactions-filter-modal"
      closeIcon
      size="small"
    >
      <Modal.Header>Show transactions in range</Modal.Header>

      <Modal.Content>
        <DayPicker
          className="Selectable"
          fixedWeeks
          enableOutsideDaysClick
          numberOfMonths={isMobile ? 1 : 2}
          selectedDays={[from, { from, to }]}
          modifiers={{ start: from, end: to }}
          month={month}
          onDayClick={(day) => handleDayClick(day)}
          captionElement={
            <YearMonthForm onChange={(month) => handleYearMonthChange(month)} />
          }
        />
      </Modal.Content>

      <Modal.Actions>
        <Button content="Reset" onClick={() => handleResetClick()} />
        <Button
          content="Apply"
          onClick={() => handleApplyClick()}
          positive
          disabled={from === null && to === null}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalDateRangeSelection;
