import React, { useEffect } from "react";
import { Bar, Chart } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { Statistic, Table } from "semantic-ui-react";
import {
  getIncomeExpenseChartData,
  getIncomeExpenseGroupedData,
} from "../../redux/ui/report/reportsUISelectors";
import { loadIncomeExpenseReportData } from "../../redux/ui/report/reportsUISlice";
import Amount from "../Amount";
import { barChartOptions } from "./BarChartOptions";
import ReportIncomeExpenseGroup from "./ReportIncomeExpenseGroup";

const ReportIncomeExpense = () => {
  const dispatch = useDispatch();
  // Selectors
  const data = useSelector((state) => state.ui.report.rpData);
  const showChart = useSelector((state) => state.ui.report.showChart);
  const groupedData = useSelector((state) =>
    getIncomeExpenseGroupedData(state)
  );
  const groups = Object.keys(groupedData);

  const chartData = useSelector((state) => getIncomeExpenseChartData(state));

  console.log(`chartData`, chartData);

  useEffect(() => {
    const fetchData = async () => {
      //Load Accounts from database
      await dispatch(loadIncomeExpenseReportData());
    };

    fetchData();
  }, [dispatch]);

  const income = data ? data.map((item) => item.income) : [];
  const expense = data ? data.map((item) => item.expense) : [];

  const totalIncome = income.reduce((sum, cur) => sum + cur, 0);
  const totalExpense = expense.reduce((sum, cur) => sum + cur, 0);

  return (
    <>
      <div className="mt-report-stats">
        <Statistic.Group size="mini">
          <Statistic>
            <Statistic.Value>
              <Amount value={totalIncome} />
            </Statistic.Value>
            <Statistic.Label>Net income</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>
              <Amount value={totalExpense} />
            </Statistic.Value>
            <Statistic.Label>Net expense</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>
              <Amount value={totalIncome + totalExpense} />
            </Statistic.Value>
            <Statistic.Label>Difference</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </div>

      {groups.length > 0 ? (
        <>
          {showChart && (
            <Bar
              // className="ct-octave mt-report-expense-income"
              type="bar"
              data={chartData}
              options={barChartOptions}
            />
          )}

          <div className="report-table">
            <Table size="small" unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>Month</Table.HeaderCell>
                  <Table.HeaderCell width={4} textAlign="right">
                    Income
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4} textAlign="right">
                    Expense
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4} textAlign="right">
                    Difference
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {groups.map((group) => (
                  <ReportIncomeExpenseGroup
                    key={group}
                    group={groupedData[group]}
                  />
                ))}
              </Table.Body>
            </Table>
          </div>
        </>
      ) : (
        <div className="report-item-list__empty">Report is empty.</div>
      )}
    </>
  );
};

export default ReportIncomeExpense;
