import React from "react";
import { useSelector } from "react-redux";
import { selectRecentTransactions } from "../../redux/entities/transactionsSelectors";
import TransactionList from "./TransactionList";

const RecentTransactions = () => {
  const recentTransactions = useSelector((state) =>
    selectRecentTransactions(state)
  );
  const isLoading = useSelector(
    (state) => state.ui.transaction.recent.isLoading
  );

  return (
    <TransactionList transactions={recentTransactions} isLoading={isLoading} />
  );
};

export default RecentTransactions;
