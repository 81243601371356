import React from "react";
import AccountItem from "./AccountItem";
import Amount from "../Amount";

const AccountGroup = ({
  group,
  isCollapsed,
  toggleGroupCollapse,
  openInModal,
}) => {
  return (
    <div className="account-widget-group">
      <div
        className="account-widget-group__header"
        onClick={toggleGroupCollapse}
      >
        <span className="account-widget-group__name">{group.name}</span>
        <span className="account-widget-group__total">
          <Amount value={group.total} />
        </span>
      </div>

      {!isCollapsed &&
        group.accounts.map((account) => (
          // <div>Account Item</div>
          <AccountItem
            key={account.acc_id}
            account={account}
            openInModal={openInModal}
          />
        ))}
    </div>
  );
};

export default AccountGroup;
