import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "semantic-ui-react";
import {
  getPayeePage,
  getTotalPayeePage,
} from "../../redux/ui/payee/filteredPayeeSelectors";
import { changePayeePage } from "../../redux/ui/payee/filteredPayeeSlice";
import ListPager from "../ListPager";

const PayeeListPager = () => {
  const dispatch = useDispatch();
  // Selectors
  const currentPage = useSelector((state) => getPayeePage(state));
  const totalPages = useSelector((state) => getTotalPayeePage(state));

  // Events
  const handlePaginationChange = (activePage) => {
    dispatch(changePayeePage(activePage));
  };

  // Render
  if (totalPages < 2) return null;
  return (
    <div className="transactions-list-pager">
      <ListPager
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePaginationChange}
      />
    </div>
  );
};

export default PayeeListPager;
