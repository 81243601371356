import axios from "axios";
import { globalConfig } from "./config";

export default axios.create({
  baseURL: window.config.apiUrl,
  // baseURL: "http://localhost/ndmoneytracker_v02/api",
  // baseURL: "https://ndsoftwares.co.in/moneytracker/api",
});

// const ndApi = axios.create({
//   // baseURL: "http://localhost/ndmoneytracker/api",
//   baseURL: "https://ndsoftwares.co.in/moneytracker/api",

//   // headers: {
//   //   "Cache-Control": "no-cache",
//   //   // Pragma: "no-cache",
//   //   // Expires: "0",
//   // },
// });

// ndApi.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     config.params = { ...config.params, timestamp: new Date.now() };
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// //   return axiosInstance;
// // };

// export default ndApi;
