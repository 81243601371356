import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button } from "semantic-ui-react";
import {
  DateFilterRangeT,
  defaultDateFilterRange,
  getDateFilterRangeEnd,
  getDateFilterRangeStart,
  getDateRangeFilterOptions,
} from "../../entities/transaction/DateFilterRange";
import { openTransactionInModal } from "../../redux/ui/form/transactionsUISlice";
import { getDateLabel } from "../../redux/ui/transaction/filterTransactionSelectors";
import {
  changeFilterDate,
  toggleFilterCalendar,
  applyFilters,
  toggleFilterModal,
  changeFilterDateRangeType,
} from "../../redux/ui/transaction/filterTransactionSlice";
import AppliedTransactionFilter from "./AppliedTransactionFilter";
import ModalDateRangeSelection from "../ModalDateRangeSelection";
import ModalFilterOptions from "../ModalFilterOptions";

let lastValue = defaultDateFilterRange;

const TransactionFilterOptions = ({ selectedAccId }) => {
  const dispatch = useDispatch();

  // Selectors
  const options = getDateRangeFilterOptions();
  const dateRangeType = useSelector(
    (state) => state.ui.transaction.filter.filterDateRangeType
  );
  const isFilterModalOpen = useSelector(
    (state) => state.ui.transaction.filter.isFilterModalOpen
  );
  const isCalendarOpen = useSelector(
    (state) => state.ui.transaction.filter.isCalendarOpen
  );

  const appliedAccounts =
    useSelector((state) => state.ui.transaction.filter.applied.accounts) || [];
  const appliedCategories =
    useSelector((state) => state.ui.transaction.filter.applied.categories) ||
    [];
  const appliedPayees =
    useSelector((state) => state.ui.transaction.filter.applied.payees) || [];

  // Events
  const handleDateRange = (value) => {
    // if (value === lastValue && value !== DateFilterRangeT.custom) return;
    if (value === DateFilterRangeT.custom) {
      dispatch(toggleFilterCalendar());
    } else {
      dispatch(
        changeFilterDate({
          dateStart: getDateFilterRangeStart(value),
          dateEnd: getDateFilterRangeEnd(value),
        })
      );
      dispatch(changeFilterDateRangeType(value));
    }

    lastValue = value;
  };

  // Render
  return (
    <>
      <div className="container-header">
        <Button.Group basic fluid>
          <Button
            icon="plus"
            labelPosition="left"
            content="New"
            onClick={() =>
              dispatch(
                openTransactionInModal({
                  ...(selectedAccId ? { tx_acc_id: selectedAccId } : {}),
                })
              )
            }
          />
          <Dropdown
            button
            className="icon"
            options={options}
            defaultValue={dateRangeType}
            onChange={(_, { value }) => handleDateRange(value)}
            // text={dateRangeLabel}
            labeled
            icon="calendar"
          />
          <Button icon="filter" onClick={() => dispatch(toggleFilterModal())} />
        </Button.Group>
        {/* {console.log(`appliedAccounts send`, appliedAccounts)} */}
        <ModalDateRangeSelection
          isCalendarOpen={isCalendarOpen}
          changeFilterDate={changeFilterDate}
          toggleFilterCalendar={toggleFilterCalendar}
        />
        <ModalFilterOptions
          isFilterModalOpen={isFilterModalOpen}
          appliedAccounts={appliedAccounts}
          appliedCategories={appliedCategories}
          appliedPayees={appliedPayees}
          applyFilters={applyFilters}
          toggleFilterModal={toggleFilterModal}
        />
      </div>
      <AppliedTransactionFilter
        appliedAccounts={appliedAccounts}
        appliedCategories={appliedCategories}
        appliedPayees={appliedPayees}
        applyFilters={applyFilters}
      />
    </>
  );
};

export default TransactionFilterOptions;
