import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import format from "date-fns/format";
import { toast } from "react-toastify";
import ndApi from "../../../common/ndApi";
import {
  defaultDate,
  defaultReportType,
  ReportTypeT,
} from "../../../entities/Report";
import { TransationTypeT } from "../../../entities/Transaction";
import {
  defaultDateFilterRange,
  defaultEnd,
  defaultStart,
} from "../../../entities/transaction/DateFilterRange";
import {
  getCategoryReportFilter,
  getReportFilterDate,
  getReportFilters,
  getReportTypeState,
} from "./reportsUISelectors";

// Async Thunk Actions
export const loadIncomeExpenseReportData = createAsyncThunk(
  "report/loadIncomeExpenseData",
  async (_, { getState, dispatch }) => {
    const filters = getReportFilters(getState());
    const response = await ndApi
      .post("report/getIncomeExpenseReportData.php", filters)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("loadIncomeExpenseData Axios error: " + err);
      });

    console.log("loadIncomeExpenseData", response.data);

    if (response.data.success === 1) {
      const reportData = response.data.nd_api_data;
      await dispatch(loadReportDataSuccess(reportData));
    } else {
      toast.error("loadIncomeExpenseReportData failed: " + response.data.error);
    }
  }
);

export const loadNetWorthReportData = createAsyncThunk(
  "report/loadNetWorthReportData",
  async (_, { getState, dispatch }) => {
    const filters = getReportFilterDate(getState());
    const response = await ndApi
      .post("report/getNetWorthReportData.php", filters)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("loadNetWorthReportData Axios error: " + err);
      });

    // console.log("loadNetWorthReportData", response.data);

    if (response.data.success === 1) {
      const reportData = response.data.nd_api_data;
      await dispatch(loadReportDataSuccess(reportData));
    } else {
      toast.error("loadNetWorthReportData failed: " + response.data.error);
    }
  }
);

export const loadCategoryReportData = createAsyncThunk(
  "report/loadCategoryReportData",
  async (catType, { getState, dispatch }) => {
    const filters = getCategoryReportFilter(getState(), catType);
    const response = await ndApi
      .post("report/getCategoryReportData.php", filters)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("loadCategoryReportData Axios error: " + err);
      });

    console.log("loadCategoryReportData", response.data);

    if (response.data.success === 1) {
      const reportData = response.data.nd_api_data;
      await dispatch(loadReportDataSuccess(reportData));
    } else {
      toast.error("loadCategoryReportData failed: " + response.data.error);
    }
  }
);

export const changeFilterDate = createAsyncThunk(
  "report/changeFilterDate",
  async (payload, { dispatch }) => {
    await dispatch(changeFilterDateState(payload));
    await dispatch(loadReportData());
  }
);

export const applyFilters = createAsyncThunk(
  "report/applyFilters",
  async (payload, { dispatch }) => {
    await dispatch(applyFiltersState(payload));
    await dispatch(loadReportData());
  }
);

export const loadReportData = createAsyncThunk(
  "report/loadReportData",
  async (_, { dispatch, getState }) => {
    const reportType = getReportTypeState(getState());
    switch (reportType) {
      case ReportTypeT.ExpenseIncome:
        await dispatch(loadIncomeExpenseReportData());
        break;
      case ReportTypeT.NetWorth:
        await dispatch(loadNetWorthReportData());
        break;
      case ReportTypeT.ExpenseCategories:
        await dispatch(loadCategoryReportData(TransationTypeT.Expense));
        break;
      case ReportTypeT.IncomeCategories:
        await dispatch(loadCategoryReportData(TransationTypeT.Income));
        break;
      default:
        break;
    }
  }
);

// Initial State
const initialState = {
  dateStart: format(defaultStart(), "yyyy-MM-dd"),
  dateEnd: format(defaultEnd(), "yyyy-MM-dd"),
  rpDataIsLoading: false,
  rpData: [],
  rpType: defaultReportType,
  showChart: true,
  rpTimespan: defaultDateFilterRange,
  appliedFilters: {},
  isCalendarOpen: false,
  isFilterModalOpen: false,
};

// Slice
const reportsUISlice = createSlice({
  name: "reportsUISlice",
  initialState,
  reducers: {
    applyFiltersState: (state, { payload }) => ({
      ...state,
      appliedFilters: payload,
    }),

    toggleFilterCalendar: (state) => ({
      ...state,
      isCalendarOpen: !state.isCalendarOpen,
    }),

    toggleFilterModal: (state) => ({
      ...state,
      isFilterModalOpen: !state.isFilterModalOpen,
    }),

    toggleShowChart: (state) => ({
      ...state,
      showChart: !state.showChart,
    }),

    changeFilterDateState: (state, { payload }) => ({
      ...state,
      dateStart: format(payload.dateStart, "yyyy-MM-dd"),
      dateEnd: format(payload.dateEnd, "yyyy-MM-dd"),
      isLoading: true,
    }),

    changeReportType: (state, { payload }) => ({ ...state, rpType: payload }),

    changeReportTimespan: (state, { payload }) => ({
      ...state,
      rpTimespan: payload,
    }),
    changeReportAccounts: (state, { payload }) => ({
      ...state,
      rpAccounts: payload,
    }),
    changeReportExcludedCategories: (state, { payload }) => ({
      ...state,
      rpCategories: payload,
    }),
    // moveReportDateBackwards: (state) => ({
    //   ...state,
    //   rpDate: moveDateBackwards(state.rpDate, state.rpTimespan),
    //   rpData: {
    //     ...state.rpData,
    //     netWorthEnd: state.rpData.netWorthStart,
    //   },
    //   rpNetWorthStack: [state.rpDate.netWorthEnd, ...state.rpNetWorthStack],
    // }),
    // moveReportDateForwards: (state) => ({
    //   ...state,
    //   rpDate: moveDateForwards(state.rpDate, state.rpTimespan),
    //   rpData: {
    //     ...state.rpData,
    //     netWorthEnd: state.rpNetWorthStack[0],
    //   },
    //   rpNetWorthStack: state.rpNetWorthStack.slice(1),
    // }),

    loadReportDataSuccess: (state, { payload }) => ({
      ...state,
      rpDataIsLoading: false,
      rpData: payload,
    }),
  },
  extraReducers: {
    //loadReportData actions
    [loadIncomeExpenseReportData.pending]: (state) => {
      state.rpDataIsLoading = true;
    },
    [loadIncomeExpenseReportData.fulfilled]: (state) => {
      state.rpDataIsLoading = false;
    },
    [loadIncomeExpenseReportData.rejected]: (state) => {
      state.rpDataIsLoading = false;
    },
  },
});

export const {
  applyFiltersState,
  toggleFilterCalendar,
  toggleFilterModal,
  toggleShowChart,
  changeFilterDateState,
  changeReportType,
  changeReportTimespan,
  loadReportDataSuccess,
  changeReportExcludedCategories,
  changeReportAccounts,
} = reportsUISlice.actions;
export default reportsUISlice.reducer;
