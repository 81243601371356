import React from "react";
import { useSelector } from "react-redux";
import { selectFilterTransactions } from "../../redux/entities/transactionsSelectors";
import TransactionList from "./TransactionList";

const FilteredTransactions = () => {
  // Selectors
  const filteredTransactions = useSelector((state) =>
    selectFilterTransactions(state)
  );
  const isLoading = useSelector(
    (state) => state.ui.transaction.filter.isLoading
  );

  return (
    <TransactionList
      transactions={filteredTransactions}
      isLoading={isLoading}
    />
  );
};

export default FilteredTransactions;
