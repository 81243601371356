export const getFilters = (state, accId) => ({
  startDt: state.ui.accountDetail.dateStart,
  endDt: state.ui.accountDetail.dateEnd,
  accId: state.ui.accountDetail.accId,
});

export const getAccountDetailState = (state) => ({
  accId: state.ui.accountDetail.accId,
  accName: state.ui.accountDetail.accName,
  accGroup: state.ui.accountDetail.accGroup,
  accBal: state.ui.accountDetail.accBal,
});

export const getPage = (state) => state.ui.accountDetail.page;

export const getTotalPage = (state) => {
  return Math.ceil(
    state.ui.accountDetail.totalRows / state.ui.accountDetail.perPage
  );
};
