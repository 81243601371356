import { defaultGroup, DeleteStrategyT } from "../../../entities/Account";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ndApi from "../../../common/ndApi";
import { loadAccountsSuccess } from "../../entities/accountsSlice";
import { selectTransactionFormState } from "../../entities/transactionsSelectors";
import { resetTransactionFormState } from "./transactionsUISlice";
import { toast } from "react-toastify";
import { loadFilteredTransactions } from "../transaction/filterTransactionSlice";
import noCache from "../../../utils/noCache";

// Async Thunk Actions
export const loadAllAccounts = createAsyncThunk(
  "account/getAll", // Type
  async (_, { dispatch, getState }) => {
    // PayLoad Creater Function

    const response = await ndApi
      .get(noCache("account/getAll.php"))
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("loadAllAccounts Axios error: " + err);
      });

    if (response.data.success === 1) {
      const accounts = response.data.nd_api_data;
      await dispatch(loadAccountsSuccess(accounts));
      const transactionForm = await selectTransactionFormState(getState());
      if (!transactionForm.tx_acc_id && accounts.length > 0) {
        await dispatch(resetTransactionFormState());
      }
    }

    return response.data.nd_api_data;
  }
);

export const saveAccount = createAsyncThunk(
  "account/insert", // Type
  async (account, { dispatch }) => {
    const isInsertingAcc = account.acc_id === undefined;
    const queryUrl = isInsertingAcc ? "insert.php" : "update.php";

    const response = await ndApi
      .post("account/" + queryUrl, account)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("saveAccount Axios error: " + err);
      });

    console.log(response.data);

    if (response.data.success === 1 && response.data.nd_api_data > 0) {
      if (isInsertingAcc) {
        toast.success("Account '" + account.acc_name + "' added susccessfully");
      } else {
        toast.success(
          "Account '" + account.acc_name + "' updated susccessfully"
        );
      }
      await dispatch(loadAllAccounts());
    } else if (response.data.success === 0) {
      toast.error("Account save failed: " + response.data.error);
    } else {
      toast.error("Account save returned with 0 effected rows ");
    }

    // return account;
    // return response.data;
  }
);

// export const updateAccount = createAsyncThunk(
//   "account/update", // Type
//   async (account, { dispatch }) => {
//     // PayLoad Creater Function

//     const response = await ndApi
//       .post("account/update.php", account)
//       .catch((err) => {
//         console.log("Err: ", err);
//         toast.error("Axios Update error: " + err);
//       });

//     console.log(response.data);

//     if (response.data.success === 1 && response.data.nd_api_data > 0) {
//       toast.success("Account updated susccessfully");
//       await dispatch(loadAllAccounts());
//     } else {
//       toast.error("Update account failed");
//     }

//     // return account;
//     return response.data;
//   }
// );

export const removeAccount = createAsyncThunk(
  "account/remove",
  async ({ deleteStrategy, accId, moveToAccId }, { dispatch }) => {
    let params = {};
    let queryUrl = "";
    switch (deleteStrategy) {
      case DeleteStrategyT.Archive:
        params = { acc_id: accId };
        queryUrl = "archive.php";
        break;
      case DeleteStrategyT.Move:
        params = { acc_id: accId, other_acc_id: moveToAccId };
        queryUrl = "deleteMovingTransactions.php";
        break;
      case DeleteStrategyT.Cleanup:
        params = { acc_id: accId };
        queryUrl = "deleteWithTransactions.php";
        break;

      default:
        break;
    }
    // console.log(`deleteStrategy:`, deleteStrategy);
    // console.log(`accId:`, accId);
    const response = await ndApi
      .post("account/" + queryUrl, params)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("removeAccount Axios error: " + err);
      });

    console.log(response.data);

    switch (deleteStrategy) {
      case DeleteStrategyT.Archive:
        if (response.data.success === 1 && response.data.nd_api_data > 0) {
          toast.success("Account archived susccessfully");
          await dispatch(removeAccountSuccess());
          await dispatch(loadAllAccounts());
        } else {
          toast.error("Account archive failed");
        }
        break;

      case DeleteStrategyT.Move:
        if (response.data.transactionsMoved.success === 1) {
          toast.success(
            response.data.transactionsMoved.nd_api_data +
              " transactions moved susccessfully"
          );
          await dispatch(loadFilteredTransactions());
        } else {
          toast.error("transactions move failed");
        }
        if (response.data.accountDeleted.success === 1) {
          toast.success("Account deleted susccessfully");
          await dispatch(removeAccountSuccess());
          await dispatch(loadAllAccounts());
        } else {
          toast.error("Account delete failed");
        }
        break;

      case DeleteStrategyT.Cleanup:
        if (response.data.transactionsDeleted.success === 1) {
          toast.success(
            response.data.transactionsDeleted.nd_api_data +
              " transactions deleted susccessfully"
          );
          await dispatch(loadFilteredTransactions());
        } else {
          toast.error("transactions delete failed");
        }
        if (response.data.accountDeleted.success === 1) {
          toast.success("Account deleted susccessfully");
          await dispatch(removeAccountSuccess());
          await dispatch(loadAllAccounts());
        } else {
          toast.error("Account delete failed");
        }
        break;

      default:
        break;
    }
  }
);

//Initial States
const initialState = {
  acc_name: "",
  acc_group: defaultGroup,
  acc_init_balance: 0,
  acc_on_dashboard: true,
  acc_archived: false,
  completed: false,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
  isModalOpen: false,
  isDeleteRequest: false,
  itemsToProcess: Infinity,
  itemsProcessed: 0,
};

// Slice
const accountUISlice = createSlice({
  name: "accountUI",
  initialState,
  reducers: {
    // Action creator and handler in one function
    changeName: (state, { payload }) => {
      state.acc_name = payload;
    },
    changeGroup: (state, { payload }) => {
      state.acc_group = payload;
    },
    changeBalance: (state, { payload }) => {
      state.acc_init_balance = payload;
    },
    toggleOnDashboard: (state) => {
      state.acc_on_dashboard = !state.acc_on_dashboard;
    },
    toggleArchived: (state) => {
      state.acc_archived = !state.acc_archived;
    },

    openAccountInModal: (state, { payload }) => ({
      ...state,
      ...payload,
      isModalOpen: true,
    }),

    resetAccountForm: () => initialState,

    removeAccountSuccess: () => ({
      ...initialState,
      completed: true,
      isDeletingAcc: false,
    }),
    saveAccountSuccess: () => ({
      ...initialState,
      completed: true,
    }),

    // fillInAccountForm: (state, { payload }) => ({
    //   ...state,
    //   ...stateToForm(payload)
    // }),

    removeAccountRequest: (state) => ({
      ...state,
      isDeleteRequest: !state.isDeleteRequest,
    }),

    removeAccountStart: (state, action) => ({
      ...state,
      isDeletingAcc: true,
      itemsToProcess: action.payload,
    }),

    removeAccountFailure: (state) => ({
      ...state,
      isDeletingAcc: false,
    }),

    removeAccountItemProcessed: (state, action) => ({
      ...state,
      itemsProcessed: action.payload,
    }),
  },

  extraReducers: {
    // Not auto-generate action types or action creators for reducers included in this parameter
    // handles pre defined actions
    //getAllAccounts actions
    [loadAllAccounts.pending]: (state) => {
      state.isLoading = true;
    },

    [loadAllAccounts.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },

    [loadAllAccounts.rejected]: (state) => {
      state.isLoading = false;
    },

    // insertAccount actions
    [saveAccount.pending]: (state) => {
      state.isSaving = true;
    },
    [saveAccount.fulfilled]: () => {
      return {
        ...initialState,
        completed: true,
      };
    },
    [saveAccount.rejected]: (state) => {
      state.isSaving = false;
    },

    // Remove Account actions
    [removeAccount.pending]: (state) => {
      state.isDeleting = true;
    },
    [removeAccount.fulfilled]: () => {
      return {
        ...initialState,
        completed: true,
      };
    },
    [removeAccount.rejected]: (state) => {
      state.isDeleting = false;
    },
  },
});

export default accountUISlice.reducer;

export const {
  changeName,
  changeGroup,
  changeBalance,
  toggleOnDashboard,
  openAccountInModal,
  resetAccountForm,
  removeAccountRequest,
  removeAccountSuccess,
} = accountUISlice.actions;
