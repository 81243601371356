export const rowsPerPayeePage = 10;

interface PayeeBaseT {
  py_name: string;
}

export interface PayeeFormT extends PayeeBaseT {
  py_id?: number;
}

export interface PayeeDbT extends PayeeBaseT {
  py_id?: number;
}

export interface PayeeStateT extends PayeeBaseT {
  py_id: number;
}



export function formToDb({
  py_id, 
  py_name
}: PayeeFormT): PayeeDbT {
  return {
    py_id,
    py_name
  };
}

export function dbToState({
  py_id, 
  py_name
}: PayeeDbT): PayeeStateT {
  return {
    py_id : py_id || -1,
    py_name
  };
}


export enum DeletePayeeStrategyT {
  Delete,
  Move
}
export const defaulPayeetDeleteStrategy = DeletePayeeStrategyT.Delete;

export function getDeletePayeeStartegyOptions(hasMultiplePayees = false) {
  const stratgies = [
    
    {
      key: DeletePayeeStrategyT.Delete,
      value: DeletePayeeStrategyT.Delete,
      text: 'Delete only payee, keep transactions unlinked'
    }
  ];
  if (hasMultiplePayees) {
    stratgies.push({
      key: DeletePayeeStrategyT.Move,
      value: DeletePayeeStrategyT.Move,
      text: 'Delete payee, link transactions to another payee'
    });
  }
  
  return stratgies;
}
