import EntityMap from "../../entities/EntityMap";
import { createSlice } from "@reduxjs/toolkit";
import { TransationTypeT } from "../../entities/Transaction";

const { Expense, Transfer, Income } = TransationTypeT;

//Initial States
const initialState = {
  [Expense]: EntityMap.fromArray([]),
  [Transfer]: EntityMap.fromArray([]),
  [Income]: EntityMap.fromArray([]),
};

//Slice
const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    // Action creator and handler in one function
    // Will handle the action type `'accounts/loadAccountsSuccess'`
    loadExpenseCategoriesSuccess: (state, { payload }) => {
      const categories = payload;
      return {
        ...state,
        [Expense]: EntityMap.fromArray(categories, "cat_id"),
      };
    },
    loadIncomeCategoriesSuccess: (state, { payload }) => {
      const categories = payload;
      return {
        ...state,
        [Income]: EntityMap.fromArray(categories, "cat_id"),
      };
    },

    saveCategorySuccess: (state, { payload }) => {
      const cat = payload;
      // return EntityMap.set(state, cat, "py_id");
      return {
        ...state,
        [cat.cg_tx_type]: EntityMap.set(state[cat.cg_tx_type], cat, "cat_id"),
      };
    },

    removeCategorySuccess: (state, { payload }) => {
      // return EntityMap.remove(state, payee, "py_id");
      console.log(`catId: `, payload.catId);
      return {
        ...state,
        [payload.catTxType]: EntityMap.remove(
          state[payload.catTxType],
          payload.catId,
          "cat_id"
        ),
      };
    },
  },
});

export default categoriesSlice.reducer;

export const {
  loadExpenseCategoriesSuccess,
  loadIncomeCategoriesSuccess,
  saveCategorySuccess,
  removeCategorySuccess,
} = categoriesSlice.actions;
