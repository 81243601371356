import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ndApi from "../../common/ndApi";
import pick from "lodash/pick";
import noCache from "../../utils/noCache";

export const loadSettings = createAsyncThunk(
  "settings/get", // Type
  async (_, { dispatch }) => {
    // PayLoad Creater Function
    const response = await ndApi
      .get(noCache("settings/getAll.php"))
      .catch((err) => {
        console.log("Err: ", err);
      });

    console.log(response.data);

    // return account;
    if (response.data.success === 1) {
      const settings = response.data.nd_api_data;
      await dispatch(loadSettingsSeccess(settings));
    }
    return response.data.nd_api_data;
  }
);

// export const saveSettings = createAsyncThunk(
//   "settings/save", // Type
//   async ({ settingName, settingValue }) => {
//     // PayLoad Creater Function
//     const response = await ndApi
//       .post("settings/save.php", {
//         setting_name: settingName,
//         setting_value: settingValue,
//       })
//       .catch((err) => {
//         console.log("Err: ", err);
//       });

//     console.log("response.data", response.data);

//     // if (response.data.nd_api_data > 0)
//     // return account;
//     return response.data.nd_api_data;
//   }
// );

export const completeSetup = createAsyncThunk(
  "settings/completeSetup", // Type
  async (_, { dispatch }) => {
    // PayLoad Creater Function
    const response = await ndApi
      .post("settings/save.php", {
        setting_name: "isSetupComplete",
        setting_value: "true",
      })
      .catch((err) => {
        console.log("Err: ", err);
      });

    console.log("response.data", response.data);

    if (response.data.success === 1 && response.data.nd_api_data > 0) {
      await dispatch(loadSettings());
    }

    return response.data.nd_api_data;
  }
);

const initialState = {
  isSetupComplete: "false",
  isSetupCompleting: false,
  isLoaded: false,
  statusLoadingSettings: "idle",
  collapsedSections: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    loadSettingsSeccess: (state, { payload }) => ({
      ...state,
      isLoaded: true,
      ...pick(payload, Object.keys(state)),
    }),
    // setupCompleted: (state, { payload }) => ({
    //   ...state,
    //   isSetupComplete: true,
    // }),
    toggleSectionCollapse: (state, { payload }) => ({
      ...state,
      collapsedSections: state.collapsedSections.includes(payload)
        ? state.collapsedSections.filter((section) => section !== payload)
        : state.collapsedSections.concat(payload),
    }),
  },

  extraReducers: {
    // handle actions of createAsyncThunk

    [loadSettings.pending]: (state) => {
      state.statusLoadingSettings = "pending";
    },

    [loadSettings.fulfilled]: (state, { payload }) => {
      state.statusLoadingSettings = "fulfilled";
    },

    [loadSettings.rejected]: (state) => {
      state.statusLoadingSettings = "rejected";
    },

    [completeSetup.pending]: (state) => {
      state.isSetupCompleting = true;
    },

    [completeSetup.fulfilled]: (state) => {
      state.isSetupCompleting = false;
    },

    [completeSetup.rejected]: (state) => {
      state.isSetupCompleting = false;
    },
  },
});

export default settingsSlice.reducer;

export const { loadSettingsSeccess, toggleSectionCollapse } =
  settingsSlice.actions;
