import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  defaulCategorytDeleteStrategy,
  DeleteCategoryStrategyT,
  getDeleteCategoryStartegyOptions,
} from "../../entities/Category";
import {
  getAllCategoryOptions,
  getCategoryOptions,
} from "../../redux/entities/categoriesSelectors";
import {
  getCategoryFormState,
  getCategoryModalState,
} from "../../redux/ui/form/categoriesUISelectors";
import { Button, Dropdown, Form, Radio } from "semantic-ui-react";
import { removeCategory } from "../../redux/ui/form/categoriesUISlice";

const CategoryDeleteStrategy = () => {
  const dispatch = useDispatch();

  //Selectors
  const formState = useSelector((state) => getCategoryFormState(state));
  const modalState = useSelector((state) => getCategoryModalState(state));
  const otherCatOptions = useSelector((state) =>
    getAllCategoryOptions(state).filter((cat) => cat.key !== formState.cat_id)
  );
  const hasMultipleCats = otherCatOptions.length > 0;

  // Local State
  const [state, setState] = useState({
    strategy: defaulCategorytDeleteStrategy,
    moveTo: otherCatOptions.length > 0 && otherCatOptions[0].key,
  });

  // Funcs
  const getMoveTo = () =>
    state.moveTo || (otherCatOptions.length > 0 && otherCatOptions[0].key);

  // Events
  const onStrategyChange = (value) => {
    setState({ ...state, strategy: value });
  };
  const onPayeeChange = (value) => {
    setState({ ...state, moveTo: value });
  };
  const onProceed = () => {
    dispatch(
      removeCategory({
        deleteStrategy: state.strategy,
        catGroup: formState.cat_group,
        catId: formState.cat_id,
        moveToCatId: state.moveTo,
      })
    );
  };

  return (
    <>
      <h3>You are about to delete category "{formState.cat_name}"</h3>
      <p style={{ marginTop: "1em" }}>
        What should we do with transactions linked to this category?
      </p>

      <Form>
        {getDeleteCategoryStartegyOptions(hasMultipleCats).map((strategy) => (
          <Form.Field key={strategy.key}>
            <Radio
              name="deleteStrategy"
              label={strategy.text}
              value={strategy.value}
              checked={state.strategy === strategy.value}
              onChange={(_, { value }) => onStrategyChange(value)}
              disabled={modalState.isDeleting}
            />
          </Form.Field>
        ))}

        {hasMultipleCats && state.strategy === DeleteCategoryStrategyT.Move && (
          <Form.Field>
            <Dropdown
              selection
              value={getMoveTo()}
              options={otherCatOptions}
              onChange={(_, { value }) => onPayeeChange(value)}
              disabled={modalState.isDeleting}
            />
          </Form.Field>
        )}

        <Form.Field>
          <Button
            negative
            labelPosition="right"
            icon="arrow right"
            content="Proceed"
            onClick={() => onProceed()}
            disabled={modalState.isDeleting}
            loading={modalState.isDeleting}
          />
        </Form.Field>
      </Form>
    </>
  );
};

export default CategoryDeleteStrategy;
