import { createDraftSafeSelector } from "@reduxjs/toolkit";
import format from "date-fns/format";
import { defaultKind, rowsPerPage } from "../../entities/Transaction";
import { getAccountsAsOptions } from "./accountsSelectors";
import { createSelector } from "reselect";
import EntityMap from "../../entities/EntityMap";
import { getPage } from "../ui/transaction/filterTransactionSelectors";

export const selectTransactionFormState = (state) => state.ui.form.transaction;
export const selectRecentTransactionFormState = (state) =>
  state.entities.transactions.recent;
export const selectFilteredTransactionFormState = (state) =>
  state.entities.transactions.filter;

export const selectRecentTransactions = createSelector(
  selectRecentTransactionFormState,
  (transactions) => EntityMap.map(transactions, (transaction) => transaction)
);

export const selectFilterTransactions = createSelector(
  selectFilteredTransactionFormState,
  getPage,
  (transactions, page) =>
    EntityMap.map(
      transactions,
      (transaction) => transaction,
      rowsPerPage,
      (page - 1) * rowsPerPage
    )
);

export const selectDefaultAccountId = createSelector(
  getAccountsAsOptions,
  (options) => options.length > 0 && options[0].key
);

export const selectDefaultLinkedAccountId = createSelector(
  getAccountsAsOptions,
  selectDefaultAccountId,
  (options, defaultAccountId) =>
    options.length > 1 ? options[1].key : defaultAccountId
);

export const selectDefaultTransactionFormState = createSelector(
  selectDefaultAccountId,
  selectDefaultLinkedAccountId,
  (accountId, linkedAccountId) => {
    return {
      isModalOpen: false,
      isDeleteRequested: false,
      tx_type: defaultKind,
      tx_acc_id: accountId || undefined,
      tx_amount: "",
      tx_linked_acc_id: linkedAccountId || undefined,
      tx_linked_amount: "",
      tx_payee_id: null,
      tx_cat_id: null,
      tx_date: format(new Date(), "yyyy-MM-dd"),
      tx_note: "",
    };
  }
);

export const getFilterTransactionTotal = (txType) =>
  createSelector(selectFilteredTransactionFormState, (transactions) =>
    EntityMap.filter(transactions, (tx) => tx.tx_type === txType).reduce(
      (total, tx) => total + tx.tx_amount,
      0
    )
  );
