import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "semantic-ui-react";
import {
  getTotalPage,
  getPage,
} from "../../redux/ui/transaction/filterTransactionSelectors";
import { changeFilterPage } from "../../redux/ui/transaction/filterTransactionSlice";
import ListPager from "../ListPager";

const TransactionListPager = () => {
  const dispatch = useDispatch();
  // Selectors
  const currentPage = useSelector((state) => getPage(state));
  const totalPages = useSelector((state) => getTotalPage(state));
  const isMobile = useSelector((state) => state.ui.isMobile);

  // Events
  const handlePaginationChange = (activePage) => {
    dispatch(changeFilterPage(activePage));
  };

  // Render
  if (totalPages < 2) return null;

  return (
    <div className="transactions-list-pager">
      <ListPager
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePaginationChange}
      />
      {/* <Pagination
        activePage={currentPage}
        totalPages={totalPages}
        boundaryRange={1}
        siblingRange={isMobile ? 1 : 2}
        size={isMobile ? "mini" : 2}
        prevItem={isMobile ? null : undefined}
        nextItem={isMobile ? null : undefined}
        firstItem={null}
        lastItem={null}
        onPageChange={(e, { activePage }) => handlePaginationChange(activePage)}
      /> */}
    </div>
  );
};

export default TransactionListPager;
