import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { loadAllCategories } from "../../redux/ui/form/categoriesUISlice";
import { loadAllAccounts } from "../../redux/ui/form/accountsUISlice";
import { loadAllPayee } from "../../redux/ui/form/payeeUISlice";
import { applyFilters } from "../../redux/ui/transaction/filterTransactionSlice";
import FilteredTransactions from "../transactions/FilteredTransactions";
import TransactionFilterOptions from "../transactions/TransactionFilterOptions";
import TransactionListFooter from "../transactions/TransactionListFooter";
import TransactionListPager from "../transactions/TransactionListPager";

const Transactions = () => {
  const dispatch = useDispatch();

  // Parameter
  const { accountId } = useParams();
  const accId = parseInt(accountId);

  // Initial data fetching
  useEffect(() => {
    const fetchData = async () => {
      //Load data from database
      await dispatch(loadAllAccounts());
      await dispatch(loadAllPayee());
      await dispatch(loadAllCategories());
      // await dispatch(getFilteredTransactions());
    };

    fetchData();
    dispatch(applyFilters({ accounts: accountId ? [accId] : [] }));
  }, []);

  // Render
  return (
    <div className="container-full-page flat search-page">
      <TransactionFilterOptions selectedAccId={accId} />
      <TransactionListPager />
      <FilteredTransactions />
      <TransactionListPager />
      <TransactionListFooter />
    </div>
  );
};

export default Transactions;
