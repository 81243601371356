import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header, Icon } from "semantic-ui-react";
import { toggleSidebar } from "../redux/ui/isSidebarOpenSlice";
import Logo from "./Logo";

const AppHeader = ({ label }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.ui.isMobile);

  return (
    <header>
      <Header>
        {isMobile && (
          <Icon name="bars" onClick={() => dispatch(toggleSidebar())} />
        )}
        {!isMobile && <Logo />}
        <Header.Content as="h2">{label}</Header.Content>
        {/* {isAuthenticated &&
          (isSyncRunning ? (
            <Icon name="refresh" loading />
          ) : (
            <Icon
              name="refresh"
              color={hasPendingChanges ? "olive" : undefined}
              onClick={sync}
              style={{ cursor: "pointer" }}
            />
          ))} */}
      </Header>
    </header>
  );
};

export default AppHeader;
