import React from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { useSelector } from "react-redux";
import {
  getAllCategoriesList,
  getCategoryList,
} from "../../redux/entities/categoriesSelectors";
import CategoryItem from "./CategoryItem";
import ModalCategoryForm from "./ModalCategoryForm";
import LoaderList from "../LoaderList";

const CategoryList = ({ activeKind }) => {
  // Selectors
  const categories = useSelector((state) => getCategoryList(state, activeKind));
  const isLoading = useSelector((state) => state.ui.form.category.isLoading);
  // console.log(`categories`, categories);

  return (
    <Segment className="transactions-list__wrapper">
      {/* <Dimmer inverted active={isLoading}>
        <Loader active inline="centered" />
      </Dimmer> */}
      {isLoading ? (
        <LoaderList />
      ) : categories.length > 0 ? (
        categories.map((cat) => (
          // cat.cat_name
          <CategoryItem key={cat.cat_id} cat={cat} />
        ))
      ) : (
        <div className="transactions-list__empty">No payee found.</div>
      )}

      <ModalCategoryForm />
    </Segment>
  );
};

export default CategoryList;
