import { createSlice } from "@reduxjs/toolkit";

const isMobile = () => window.matchMedia("(max-width: 768px)").matches;

const initialState = isMobile();

const isMobileSlice = createSlice({
  name: "isMobile",
  initialState,
  reducers: {
    windowResize: () => isMobile(),
  },
});

export default isMobileSlice.reducer;

export const { windowResize } = isMobileSlice.actions;
