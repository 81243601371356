
import addMonths from "date-fns/addMonths";
import addYears from "date-fns/addYears";
import endOfMonth from "date-fns/endOfMonth";
import endOfYear from "date-fns/endOfYear";
import format from "date-fns/format";
import startOfMonth from "date-fns/startOfMonth";
import startOfYear from "date-fns/startOfYear";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
import ExpenseIncomeReportData from "./report/ExpenseIncomeReportData";
import { TransactionStateT, TransationTypeT } from "./Transaction";


export enum ReportTypeT {
  ExpenseIncome = 'expense_income',
  NetWorth = 'net_worth',
  IncomeCategories = 'income_categories',
  ExpenseCategories = 'expense_categories'
}

export enum ReportTimespanT {
  Yearly = 'yearly',
  Monthly = 'monthly'
}

interface DateRangeT {
  start: string;
  end: string;
}

export interface ReportDatasetT {
  label: string,
  data: number[],
  backgroundColor: string
}

export interface ReportDataT {
  labels: string[];
  datasets: ReportDatasetT[];
  netWorthStart?: number;
  netWorthEnd?: number;
}

export interface ReportStateT {
  rpType:  ReportTypeT;
  rpData: ReportDataT;
  rpDate: DateRangeT;
  rpTimespan: ReportTimespanT;
  rpAccounts: number[];
  rpCategories: number[];
  rpNetWorthStack: number[];
  rpDataIsLoading: boolean;
}

const { Yearly, Monthly } = ReportTimespanT;
export const defaultReportType = ReportTypeT.ExpenseIncome;
export const defaultTimespan = Yearly;

export function defaultDate(timespan = defaultTimespan) {
  return dateRange(new Date(), timespan);
}

export function moveDateBackwards({start}: DateRangeT, timespan: ReportTimespanT){
  return dateRange(timespan===Yearly ? subYears(new Date(start), 1) : subMonths(new Date(start), 1), timespan)
}
export function moveDateForwards({start}: DateRangeT, timespan: ReportTimespanT){
  return dateRange(timespan===Yearly ? addYears(new Date(start), 1) : addMonths(new Date(start), 1), timespan)
}

export function getReportTypeOptions() {
  return [
    {
      key: ReportTypeT.ExpenseIncome,
      value: ReportTypeT.ExpenseIncome,
      text: 'Expense & Income'
    },
    {
      key: ReportTypeT.ExpenseCategories,
      value: ReportTypeT.ExpenseCategories,
      text: 'Expense by Categories'
    },
    {
      key: ReportTypeT.IncomeCategories,
      value: ReportTypeT.IncomeCategories,
      text: 'Income by Categories'
    },
    {
      key: ReportTypeT.NetWorth,
      value: ReportTypeT.NetWorth,
      text: 'Net Worth'
    }
  ];
}

export function getTimespanOptions() {
  return [
    {
      key: Yearly,
      value: Yearly,
      text: 'Yearly'
    },
    {
      key: Monthly,
      value: Monthly,
      text: 'Monthly'
    }
  ];
}

export function getTimespanLabel(date: Date, timespan: ReportTimespanT) {
  return format(date, timespan === Yearly ? 'yyyy' : 'MMM, yyyy');
}

function dateRange(date: Date | number, timespan: ReportTimespanT): DateRangeT {
  const getStartDt = timespan === Yearly ? startOfYear : startOfMonth;
  const getEndDt = timespan === Yearly ? endOfYear : endOfMonth;
  const tomorrow = new Date(date);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return {
    start: format(getStartDt(tomorrow), "yyyy-MM-dd"),
    end: format(getEndDt(tomorrow), "yyyy-MM-dd")
  }
}

export function rpTransactionFilters({ rpDate, rpAccounts, rpCategories }: ReportStateT) {
  return { date: rpDate, accounts: rpAccounts, categories: rpCategories};
}

type DataLoaderFn = (
  reportState: ReportStateT,
  transactions: TransactionStateT[],
  netWorthEnd: number
) => ReportDataT;

type DataLoaderMapT = {[kind in ReportTypeT]: DataLoaderFn}

const DataLoaderMap: DataLoaderMapT = {
  [ReportTypeT.ExpenseIncome]: ExpenseIncomeReportData,
  [ReportTypeT.IncomeCategories]: ExpenseIncomeReportData,
  [ReportTypeT.ExpenseCategories]: ExpenseIncomeReportData,
  [ReportTypeT.NetWorth]: ExpenseIncomeReportData
}

export const populateReportData: DataLoaderFn = (reportState, ...rest) => {
  return DataLoaderMap[reportState.rpType](reportState, ...rest);
}