import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPage,
  getTotalPage,
} from "../../redux/ui/accountdetail/accountDetailUISelectors";
import { changeFilterPage } from "../../redux/ui/accountdetail/accountDetailUISlice";
import ListPager from "../ListPager";

const AccountTransactionListPager = () => {
  const dispatch = useDispatch();
  // Selectors
  const currentPage = useSelector((state) => getPage(state));
  const totalPages = useSelector((state) => getTotalPage(state));
  const isMobile = useSelector((state) => state.ui.isMobile);
  const isLoadingTx = useSelector(
    (state) => state.ui.accountDetail.isLoadingTx
  );

  // Events
  const handlePaginationChange = (activePage) => {
    dispatch(changeFilterPage(activePage));
  };

  // Render
  if (totalPages < 2 || isLoadingTx) return null;

  return (
    <div className="transactions-list-pager">
      <ListPager
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePaginationChange}
      />
    </div>
  );
};

export default AccountTransactionListPager;
