import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Header, Modal } from "semantic-ui-react";
import AccountForm from "./AccountForm";
import {
  removeAccountRequest,
  resetAccountForm,
} from "../../redux/ui/form/accountsUISlice";
import { getModalState } from "../../redux/ui/form/accountUISelectors";
import AccountDeleteStrategy from "./AccountDeleteStrategy";

const ModalAccountForm = () => {
  const dispatch = useDispatch();

  // Selectors
  const modalState = useSelector((state) => getModalState(state));
  const isEdit =
    useSelector((state) => state.ui.form.account.acc_id) !== undefined;

  const reset = () => {
    if (modalState.isDeleteRunning) return false;
    dispatch(resetAccountForm());
  };

  // Render funs
  const renderModalActions = () => {
    if (!isEdit) return;

    return modalState.isDeleteRequest ? (
      <Button
        labelPosition="left"
        onClick={() => dispatch(removeAccountRequest())}
        content="Cancel"
        icon="cancel"
        disabled={modalState.isDeleting}
      />
    ) : (
      <Button
        negative
        labelPosition="right"
        onClick={() => dispatch(removeAccountRequest())}
        content="Delete"
        icon="trash"
        disabled={modalState.isDeleting || modalState.isSaving}
      />
    );
  };

  // Render
  return (
    <Modal
      closeIcon
      size="small"
      className="account-form"
      open={modalState.isOpen}
      onClose={reset}
    >
      <Header
        icon="file text outline"
        content={isEdit ? "Edit Account" : "New Account"}
      />

      <Modal.Content>
        {modalState.isDeleteRequest ? (
          <AccountDeleteStrategy />
        ) : (
          <AccountForm />
        )}
      </Modal.Content>

      <Modal.Actions>{renderModalActions()}</Modal.Actions>
    </Modal>
  );
};

export default ModalAccountForm;
