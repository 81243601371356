import React from "react";
import { Table } from "semantic-ui-react";
import Amount from "../Amount";
import ReportIncomeExpenseItem from "./ReportIncomeExpenseItem";

const ReportIncomeExpenseGroup = ({ group, showBalance }) => {
  return (
    <>
      <Table.Row className="report-item-list-group__header">
        <Table.Cell>{group.name}</Table.Cell>
        <Table.Cell textAlign="right">
          <Amount value={group.totalIncome} />
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Amount value={group.totalExpense} />
        </Table.Cell>
        <Table.Cell textAlign="right">
          {showBalance ? (
            ""
          ) : (
            <Amount value={group.totalIncome + group.totalExpense} />
          )}
        </Table.Cell>
      </Table.Row>

      {group.items.map((item) => (
        <ReportIncomeExpenseItem
          key={item}
          item={item}
          showBalance={showBalance}
        />
      ))}
    </>
  );
};

export default ReportIncomeExpenseGroup;
