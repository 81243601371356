
interface CategoryBaseT {
  cat_name: string;
  cat_cg_id: number;
}

export interface CategoryFormT extends CategoryBaseT {
  cat_id?: number;
}

export interface CategoryDbT extends CategoryBaseT {
  cat_id?: number;
}

export interface CategoryStateT extends CategoryBaseT {
  cat_id: number;
}

export function formToDb({
  cat_id, 
  cat_name,
  cat_cg_id
}: CategoryFormT): CategoryDbT {
  return {
    cat_id,
    cat_name,
    cat_cg_id
  };
}

export function dbToState({
  cat_id, 
  cat_name,
  cat_cg_id
}: CategoryDbT): CategoryStateT {
  return {
    cat_id : cat_id || -1,
    cat_name,
    cat_cg_id
  };
}

export enum DeleteCategoryStrategyT {
  Move
}
export const defaulCategorytDeleteStrategy = DeleteCategoryStrategyT.Move;

export function getDeleteCategoryStartegyOptions(hasMultipleCategories = false) {
  const stratgies = [];
  if (hasMultipleCategories) {
    stratgies.push({
      key: DeleteCategoryStrategyT.Move,
      value: DeleteCategoryStrategyT.Move,
      text: 'Delete category, link transactions to another category'
    });
  }
  
  return stratgies;
}