import { combineReducers } from "redux";
import accountDetailUISlice from "./accountdetail/accountDetailUISlice";
import formReducers from "./form/formReducers";
import isMobileSlice from "./isMobileSlice";
import isSidebarOpenSlice from "./isSidebarOpenSlice";
import payeeReducer from "./payee/payeeReducer";
import reportsUISlice from "./report/reportsUISlice";
import transactionReducers from "./transaction/transactionReducers";

export default combineReducers({
  form: formReducers,
  accountDetail: accountDetailUISlice,
  transaction: transactionReducers,
  payee: payeeReducer,
  report: reportsUISlice,
  isMobile: isMobileSlice,
  isSidebarOpen: isSidebarOpenSlice,
});
