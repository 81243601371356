import React from "react";
import { useSelector } from "react-redux";
import { Pagination } from "semantic-ui-react";

const ListPager = ({ currentPage, totalPages, onPageChange }) => {
  // Selectors
  const isMobile = useSelector((state) => state.ui.isMobile);

  return (
    <Pagination
      activePage={currentPage}
      totalPages={totalPages}
      boundaryRange={1}
      siblingRange={isMobile ? 1 : 2}
      size={isMobile ? "mini" : 2}
      prevItem={isMobile ? null : undefined}
      nextItem={isMobile ? null : undefined}
      firstItem={null}
      lastItem={null}
      onPageChange={(e, { activePage }) => onPageChange(activePage)}
    />
  );
};

export default ListPager;
