import EntityMap from "../../entities/EntityMap";
import { createSlice } from "@reduxjs/toolkit";

//Initial States
const initialState = EntityMap.fromArray([]);

//Slice
const accountSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    // Action creator and handler in one function
    // Will handle the action type `'accounts/loadAccountsSuccess'`
    loadAccountsSuccess: (state, { payload }) => {
      // console.log("loadAccountsSuccess.payload", payload);
      const accounts = payload;
      // console.log("loadAccountsSuccess", accounts);
      return EntityMap.fromArray(accounts, "acc_id");
    },
    insertAccountSuccess: (state, { payload }) => {
      const account = payload;
      return EntityMap.set(state, account, "acc_id");
    },
    updateAccountSuccess: (state, { payload }) => {
      const account = payload;
      return EntityMap.set(state, account, "acc_id");
    },
    removeAccountSuccess: (state, { payload }) => {
      const accountId = payload;
      return EntityMap.remove(state, accountId);
    },
    saveAccountFailure: (state, { payload }) => {
      const accountId = payload;
      return EntityMap.remove(state, accountId);
    },
  },
  extraReducers: {
    // Not auto-generate action types or action creators for reducers included in this parameter
    // handles pre defined actions
  },
});

export default accountSlice.reducer;

export const {
  loadAccountsSuccess,
  insertAccountSuccess: saveAccountSuccess,
  updateAccountSuccess,
  removeAccountSuccess,
  saveAccountFailure,
} = accountSlice.actions;
