import React from "react";
import { useDispatch } from "react-redux";
import { Route, Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import { toggleSidebar } from "../redux/ui/isSidebarOpenSlice";
import routes from "../router/routes";

const SidebarMenu = ({ isOpen }) => {
  const dispatch = useDispatch();

  return (
    <nav
      className={isOpen ? "open" : "closed"}
      onClick={() => dispatch(toggleSidebar())}
    >
      <Menu fluid color="blue" vertical icon="labeled">
        {routes.map(
          (route) =>
            route.showInMenu && (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                children={({ match }) => (
                  <Menu.Item
                    as={Link}
                    to={route.link || route.path}
                    active={!!match}
                  >
                    <Icon name={route.icon} />
                    {route.label}
                  </Menu.Item>
                )}
              />
            )
        )}
      </Menu>
    </nav>
  );
};

export default SidebarMenu;
