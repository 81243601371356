import React from "react";
import { Table } from "semantic-ui-react";
import Amount from "../Amount";

const ReportCategoryGroup = ({ group }) => {
  return (
    <>
      <Table.Row className="report-item-list-group__header">
        <Table.Cell>{group.name}</Table.Cell>
        <Table.Cell textAlign="right">
          <Amount value={group.totalGroupAmount} />
        </Table.Cell>
      </Table.Row>

      {group.items.map((item) => (
        <Table.Row>
          <Table.Cell>{item.cat_full_name}</Table.Cell>
          <Table.Cell textAlign="right">
            <Amount value={item.total_amount} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ReportCategoryGroup;
