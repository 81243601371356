import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Loader } from "semantic-ui-react";
import { toggleSectionCollapse } from "../redux/settings/settingsSlice";

const CollapsibleSection = ({
  name,
  label,
  LabelComponent,
  children,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const collapsed = useSelector((state) => state.settings.collapsedSections);

  return (
    <div className="section">
      <div
        className={
          collapsed.includes(name)
            ? "section__header"
            : "section__header active"
        }
        onClick={() => dispatch(toggleSectionCollapse(name))}
      >
        <Icon name="dropdown" />
        <h3>{label}</h3>
        <Loader active={isLoading} inline size="small" />
        {LabelComponent && <LabelComponent />}
      </div>
      {!collapsed.includes(name) && (
        <div className="section__body">{children}</div>
      )}
    </div>
  );
};

export default CollapsibleSection;
