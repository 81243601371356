import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

const isSidebarOpenSlice = createSlice({
  name: "isSidebarOpenSlice",
  initialState,
  reducers: {
    toggleSidebar: (state) => !state,
  },
});

export default isSidebarOpenSlice.reducer;

export const { toggleSidebar } = isSidebarOpenSlice.actions;
