import { createSlice } from "@reduxjs/toolkit";
import { rowsPerPayeePage } from "../../../entities/Payee";
// import { removePayeeSuccess } from "../form/payeeUISlice";

const initialState = {
  isLoading: false,
  totalRows: 0,
  page: 0,
  perPage: rowsPerPayeePage,
};

const filteredPayeeSlice = createSlice({
  name: "filteredPayee",
  initialState,
  reducers: {
    changePayeePage: (state, { payload }) => ({
      ...state,
      page: payload,
    }),
    insertPayeeSuccess: (state) => ({
      ...state,
      totalRows: state.totalRows + 1,
      isLoading: false,
    }),
    loadPayeeSuccess: (state, { payload }) => ({
      ...state,
      page: 1,
      totalRows: payload.length,
      isLoading: false,
    }),
    removePayeeSuccess: (state) => ({
      ...state,
      totalRows: state.totalRows - 1,
      isLoading: false,
    }),
  },
  extraReducers: {},
});

export default filteredPayeeSlice.reducer;

export const {
  changePayeePage,
  insertPayeeSuccess,
  loadPayeeSuccess,
  removePayeeSuccess,
} = filteredPayeeSlice.actions;
