import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown, Form, Input, Segment } from "semantic-ui-react";
import TransactionFormAccount from "./TransactionFormAccount";
import TransactionFormHeader from "./TransactionFormHeader";
import {
  TransationTypeT,
  getTransactionTypeLabel,
  formToDb,
} from "../../entities/Transaction";
import {
  changeAmount,
  changeAccount,
  changePayee,
  changeCategory,
  changeNote,
  changeDate,
  changeLinkedAccount,
  changeLinkedAmount,
  insertTransaction,
  updateTransaction,
} from "../../redux/ui/form/transactionsUISlice";
import { getPayeeAsOptions } from "../../redux/entities/payeeSelectors";
import { savePayee } from "../../redux/ui/form/payeeUISlice";
import {
  getCategoryList,
  getCategoryOptions,
} from "../../redux/entities/categoriesSelectors";

const { Expense, Transfer, Income } = TransationTypeT;

// Func Component
const NoAccounts = () => (
  <div className="transactions-form__empty">You don't have any accounts.</div>
);

// Func Component
const TransactionForm = () => {
  const dispatch = useDispatch();

  // Local State
  const [catError, setCatError] = useState(false);

  // Selectors
  const formState = useSelector((state) => state.ui.form.transaction);
  const isAddingPayee = useSelector((state) => state.ui.form.payee.isSaving);
  const payeeOptions = useSelector((state) => getPayeeAsOptions(state));
  const isLoadingPayee = useSelector((state) => state.ui.form.payee.isLoading);
  const isLoadingCats = useSelector(
    (state) => state.ui.form.category.isLoading
  );
  const categoryOptions = useSelector((state) =>
    getCategoryOptions(state, state.ui.form.transaction.tx_type)
  );

  // Funcs
  const getGridClassName = () =>
    formState.tx_type === Transfer
      ? "transaction-form-grid single-line"
      : "transaction-form-grid";

  const validateForm = () => {
    if (formState.tx_type !== Transfer && !formState.tx_cat_id) {
      setCatError(true);
      return false;
    }
    return true;
  };

  // Events
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const transaction = formToDb(formState);
      console.log("formToDb(formState)", transaction);
      if (transaction.tx_id) {
        dispatch(updateTransaction(transaction));
      } else {
        dispatch(insertTransaction(transaction));
      }
    }
  };

  // Render
  return !formState.tx_acc_id ? (
    <NoAccounts />
  ) : (
    <>
      <TransactionFormHeader
        withTransfer={!!formState.tx_linked_acc_id}
        activeKind={formState.tx_type}
      />

      <Segment attached="bottom">
        <Form className="transaction-form" onSubmit={handleSubmit}>
          <TransactionFormAccount
            label={formState.tx_type === Income ? "To" : "From"}
            accountId={formState.tx_acc_id}
            amount={formState.tx_amount}
            onAccountChange={(_, { value }) => dispatch(changeAccount(value))}
            onAmountChange={(_, { value }) => dispatch(changeAmount(value))}
          />
          {formState.tx_type === Transfer && (
            <TransactionFormAccount
              label="To"
              accountId={formState.tx_linked_acc_id}
              amount={formState.tx_linked_amount}
              onAccountChange={(_, { value }) =>
                dispatch(changeLinkedAccount(value))
              }
              onAmountChange={(_, { value }) =>
                dispatch(changeLinkedAmount(value))
              }
            />
          )}

          <div className={getGridClassName()}>
            <div className="transaction-form-grid__column-wide">
              {formState.tx_type !== Transfer && (
                <>
                  <div className="transaction-form-grid__field">
                    <Form.Field required>
                      <label>Category</label>
                      <Dropdown
                        error={catError}
                        selection
                        search
                        // allowAdditions
                        placeholder="Choose existing Category or add new"
                        value={formState.tx_cat_id}
                        options={categoryOptions}
                        loading={isLoadingCats}
                        onChange={(_, { value }) => {
                          setCatError(false);
                          dispatch(changeCategory(value));
                        }}
                      />
                    </Form.Field>
                  </div>
                  <div className="transaction-form-grid__field">
                    <Form.Field>
                      <label>Payee</label>
                      <Dropdown
                        selection
                        clearable
                        search
                        allowAdditions
                        closeOnChange
                        placeholder="Choose existing payee or add new"
                        value={formState.tx_payee_id}
                        options={payeeOptions}
                        onChange={(_, { value }) =>
                          dispatch(changePayee(value))
                        }
                        onAddItem={(_, { value }) =>
                          dispatch(savePayee({ py_name: value }))
                        }
                        loading={isAddingPayee || isLoadingPayee}
                      />
                    </Form.Field>
                  </div>
                </>
              )}
              <div className="transaction-form-grid__field">
                <Form.Field>
                  <Input
                    placeholder="Note"
                    value={formState.tx_note}
                    onChange={(_, { value }) => dispatch(changeNote(value))}
                  />
                </Form.Field>
              </div>
            </div>

            <div className="transaction-form-grid__column-narrow">
              <div className="transaction-form-grid__field">
                <Form.Field>
                  <Input
                    required
                    fluid
                    type="date"
                    value={formState.tx_date}
                    onChange={(_, { value }) => dispatch(changeDate(value))}
                  />
                </Form.Field>
              </div>
              <div className="transaction-form-grid__field">
                <Button
                  primary
                  fluid
                  loading={formState.isSaving}
                  disabled={
                    parseFloat(formState.tx_amount) === 0 || formState.isSaving
                  }
                >
                  {formState.tx_id ? "Save" : "Add"}{" "}
                  {getTransactionTypeLabel(formState.tx_type)}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Segment>
    </>
  );
};

export default TransactionForm;
