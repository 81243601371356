import { combineReducers } from "redux";
import accountsSlice from "./accountsSlice";
import accountTransactionsSlice from "./accountTransactionsSlice";
import categoriesSlice from "./categoriesSlice";
import categoryGroupSlice from "./categoryGroupSlice";
import payeeSlice from "./payeeSlice";
import transactionsSlice from "./transactionsSlice";

export default combineReducers({
  accounts: accountsSlice,
  accountTransactions: accountTransactionsSlice,
  categories: categoriesSlice,
  categoryGroups: categoryGroupSlice,
  payee: payeeSlice,
  transactions: transactionsSlice,
});
