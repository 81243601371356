import { createSlice } from "@reduxjs/toolkit";
import EntityMap from "../../entities/EntityMap";

//Initial States
const initialState = EntityMap.fromArray([]);

const accountTransactionsSlice = createSlice({
  name: "accountTransactions",
  initialState,
  reducers: {
    loadAccountTransactonsSuccess: (state, { payload }) => {
      // console.log("loadAccountsSuccess.payload", payload);
      const transactions = payload;
      // console.log("loadAccountsSuccess", accounts);
      return EntityMap.fromArray(transactions, "tx_id");
    },
  },
});

export const { loadAccountTransactonsSuccess } =
  accountTransactionsSlice.actions;
export default accountTransactionsSlice.reducer;
