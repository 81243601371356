import React from "react";
import { Button } from "semantic-ui-react";
import { dbToState } from "../../entities/Payee";
import { openPayeeInModal } from "../../redux/ui/form/payeeUISlice";
import { useDispatch } from "react-redux";

const PayeeItem = ({ payee }) => {
  const dispatch = useDispatch();

  const handleEditClick = () => {
    console.log(`dbToState(payee)`, dbToState(payee));
    dispatch(openPayeeInModal(dbToState(payee)));
  };

  return (
    <div className="account-widget-account">
      <div className="account-widget-account__name">{payee.py_name}</div>
      <div className="account-widget-account__edit">
        <Button basic circular icon="pencil" onClick={handleEditClick} />
      </div>
    </div>
  );
};

export default PayeeItem;
