const Currency = {

  stringToNumber(value: string): number{
    return parseFloat(value);  
  },
  
  numberToString(value: number): string {
    return value.toString();
  },

  rupeePaisaString(value: number): string {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
    // return value.toLocaleString("en-IN",{
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2
    // });
  },

  rupeeString(value: number): string {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
    // return value.toLocaleString("en-IN",{
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2
    // });
  },

  getRupee(value: number): string {
    const formattedString = this.rupeePaisaString(value);
    const splittedString = formattedString.split(".")
    return splittedString[0] ? splittedString[0] : "0"
    
  },

  getPaisa(value: number): string {
    const formattedString = this.rupeePaisaString(value);
    const splittedString = formattedString.split(".")
    return splittedString[1] ? splittedString[1] : "00"
    
  }

};

export default Currency;