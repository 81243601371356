import format from "date-fns/format";
import range from "lodash/range";
import { createSelector } from "reselect";
import { getTimespanLabel } from "../../../entities/Report";

export const getReportState = (state) => state.ui.report;

export const getReportDataState = (state) => state.ui.report.rpData;
export const getReportTypeState = (state) => state.ui.report.rpType;

export const getSelectedTimespanLabel = (state) => {
  const startDate = new Date(state.ui.report.rpDate.start);
  startDate.setDate(startDate.getDate() + 1);
  return getTimespanLabel(startDate, state.ui.report.rpTimespan);
};

export const getReportFilters = (state) => ({
  date: {
    start: state.ui.report.dateStart,
    end: state.ui.report.dateEnd,
  },
  accounts: state.ui.report.appliedFilters.accounts || [],
  categories: state.ui.report.appliedFilters.categories || [],
  payees: state.ui.report.appliedFilters.payees || [],
});

export const getReportFilterDate = (state) => ({
  startDt: state.ui.report.dateStart,
  endDt: state.ui.report.dateEnd,
});

export const getCategoryReportFilter = (state, catType) => ({
  startDt: state.ui.report.dateStart,
  endDt: state.ui.report.dateEnd,
  catType: catType,
});

export const getReportFilterDateLabel = (state) => {
  const dateStart = new Date(state.ui.report.dateStart);
  const dateEnd = new Date(state.ui.report.dateEnd);

  return format(dateStart, "d/M/yy") !== format(dateEnd, "d/M/yy")
    ? `${format(dateStart, "d/M/yy")} - ${format(dateEnd, "d/M/yy")}`
    : format(dateStart, "MMMM, do");
};

export const getIncomeExpenseGroupedData = createSelector(
  getReportDataState,
  (items) => {
    const groupedData = items.reduce((groupedItems, item) => {
      const group = item.year;
      if (!groupedItems[group]) {
        groupedItems[group] = {
          name: item.year,
          items: [],
          totalIncome: 0,
          totalExpense: 0,
        };
      }

      groupedItems[group].items.push(item);
      groupedItems[group].totalIncome += item.income;
      groupedItems[group].totalExpense += item.expense;
      groupedItems[group].balance += item.balance;

      return groupedItems;
    }, {});

    return groupedData;
  }
);

export const getCategoryGroupedData = createSelector(
  getReportDataState,
  (items) => {
    const groupedData = items.reduce((groupedItems, item) => {
      const group = item.cat_group;
      if (!groupedItems[group]) {
        groupedItems[group] = {
          name: item.cat_group,
          items: [],
          totalGroupAmount: 0,
        };
      }

      groupedItems[group].items.push(item);
      groupedItems[group].totalGroupAmount += item.total_amount;

      return groupedItems;
    }, {});

    return groupedData;
  }
);

export const getIncomeExpenseChartData = (state) => {
  // Variables
  const data = getReportDataState(state);
  // console.log(`range(0, 0)`, range(0, 0));
  const labels = range(0, data.length).map(
    (i) => `${data[i].monthName} ${data[i].year}`
  );
  const datasets = [
    {
      type: "bar",
      label: "Incomes",
      data: range(0, data.length).map((i) => data[i].income),
      backgroundColor: "rgba(7, 176, 24,0.2)",
      borderColor: "rgba(7, 176, 24,1)",
      borderWidth: 1,
      stack: "Stack 0",
    }, // income series
    {
      type: "bar",
      label: "Expenses",
      data: range(0, data.length).map((i) => Math.abs(data[i].expense)),
      backgroundColor: "rgba(207, 8, 12,0.2)",
      borderColor: "rgba(207, 8, 12,1)",
      borderWidth: 1,
      stack: "Stack 1",
    }, // expense series
    {
      type: "line",
      label: "Difference",
      data: range(0, data.length).map((i) => data[i].income + data[i].expense),
      backgroundColor: "rgba(22, 85, 186,0.2)",
      borderColor: "rgba(22, 85, 186,1)",
      borderWidth: 3,
      stack: "Stack 2",
    }, // difference series
  ];

  return {
    labels,
    datasets,
  };
};

export const getNetWorthChartData = (state) => {
  // Variables
  const data = getReportDataState(state);
  // console.log(`range(0, 0)`, range(0, 0));
  const labels = range(0, data.length).map(
    (i) => `${data[i].monthName} ${data[i].year}`
  );
  const datasets = [
    {
      type: "line",
      label: "Net Worth",
      data: range(0, data.length).map((i) => data[i].balance),
      backgroundColor: "rgba(22, 85, 186,0.2)",
      borderColor: "rgba(22, 85, 186,1)",
      borderWidth: 3,
      stack: "Stack 1",
    }, // NetWorth series
  ];

  return {
    labels,
    datasets,
  };
};

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

var getRandomColor = () => {
  var r = getRndInteger(155, 255);
  var g = getRndInteger(155, 255);
  var b = getRndInteger(155, 255);
  return "rgb(" + r + "," + g + "," + b + ")";
};

export const getCategoryChartData = (state) => {
  // Variables
  const data = getReportDataState(state);
  // console.log(`range(0, 0)`, range(0, 0));
  const labels = range(0, data.length).map((i) => data[i].cat_full_name);
  const datasets = [
    {
      label: "Category Amounts",
      data: range(0, data.length).map((i) => data[i].total_amount),
      backgroundColor: range(0, data.length).map((i) => getRandomColor()),
      borderColor: "rgba(112, 112, 112,1)",
      borderWidth: 1,
    }, // Category Amounts series
  ];

  return {
    labels,
    datasets,
  };
};
