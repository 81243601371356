import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import InitialSetup from "./components/initialsetup/InitialSetup";
import { loadSettings } from "./redux/settings/settingsSlice";
import { windowResize } from "./redux/ui/isMobileSlice";
import throttle from "lodash/throttle";
import { toggleSidebar } from "./redux/ui/isSidebarOpenSlice";
import SidebarMenu from "./components/SidebarMenu";
import AppHeader from "./components/AppHeader";
import routes from "./router/routes";
import { ToastContainer, toast } from "react-toastify";

const App = () => {
  const dispatch = useDispatch();
  // Selectors
  const isSetupComplete = useSelector(
    (state) => state.settings.isSetupComplete
  );
  const isLoaded = useSelector((state) => state.settings.isLoaded);
  const isSidebarOpen = useSelector((state) => state.ui.isSidebarOpen);
  const isMobile = useSelector((state) => state.ui.isMobile);
  const statusLoadingSettings = useSelector(
    (state) => state.settings.statusLoadingSettings
  );

  // Initial settings and Data fetching
  useEffect(() => {
    const resizeListener = () => {
      dispatch(windowResize());
    };
    // set resize listener with delay
    window.addEventListener("resize", throttle(resizeListener, 500));

    // load initial settings
    dispatch(loadSettings());
  }, [dispatch]);

  // Render funcs
  const renderLoadingText = () => {
    let loadingText = "";
    if (statusLoadingSettings === "pending") {
      loadingText = "Loading initial settings";
    } else if (
      (statusLoadingSettings === "fulfilled" && !isLoaded) ||
      statusLoadingSettings === "rejected"
    ) {
      loadingText =
        "Failed to load initial settings, Check your internet connection and refresh the page";
    } else {
      loadingText = "";
    }

    return loadingText;
  };

  // Sidebar navigation routes
  const renderNavigationRoutes = () => {
    if (window.location.pathname.endsWith("index.html")) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <Dimmer
          page
          active={isMobile && isSidebarOpen}
          onClick={() => dispatch(toggleSidebar())}
          style={{ zIndex: 100 }}
        />
        <SidebarMenu isOpen={!isMobile || isSidebarOpen} />
        {flatten(routes).map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <React.Fragment>
                <AppHeader label={route.label} />
                <div className="container">
                  <route.component {...props} />
                </div>
              </React.Fragment>
            )}
          />
        ))}
      </>
    );
  };

  function flatten(routes) {
    let flatRoutes = [];
    routes.forEach((route) => {
      if (route.routes) {
        flatRoutes.push({ ...route, exact: true });
        flatRoutes.push(...flatten(route.routes));
      } else {
        flatRoutes.push(route);
      }
    });

    return flatRoutes;
  }

  // Render
  return (
    <div>
      {!isLoaded ? (
        <Loader active content="">
          {renderLoadingText()}
        </Loader>
      ) : (
        <Router>
          <Switch>
            {/* <Route path="/auth" exact={true} component={SignIn} /> */}
            {isSetupComplete !== "true" ? (
              <Route component={InitialSetup} />
            ) : (
              <Route render={renderNavigationRoutes} />
            )}
          </Switch>
        </Router>
      )}
      <ToastContainer
        autoClose={3000}
        position={isMobile ? "bottom-center" : "top-right"}
        limit={isMobile ? 1 : 6}
        theme="colored"
      />
    </div>
  );
};

export default App;
