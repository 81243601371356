import subDays from 'date-fns/subDays';
import startOfToday from 'date-fns/startOfToday';
import endOfToday from 'date-fns/endOfToday';
import startOfYesterday from 'date-fns/startOfYesterday';
import endOfYesterday from 'date-fns/endOfYesterday';
import startOfMonth from 'date-fns/startOfMonth';
import startOfYear from 'date-fns/startOfYear';
import subMonths from 'date-fns/subMonths';
import endOfMonth from 'date-fns/endOfMonth';
import subYears from 'date-fns/subYears';
import endOfYear from 'date-fns/endOfYear';

export enum DateFilterRangeT {
  today,
  yesterday,
  last7Days,
  last30Days,
  thisMonth,
  lastMonth,
  thisYear,
  lastYear,
  allTime,
  custom
}

interface DateFilterItemT {
  text: string;
  start: () => Date;
  end: () => Date;
}

function daysFromToday(days: number) {
  return () => subDays(startOfToday(), days);
}

function firstDayOfThisMonth() {
  const date = new Date();

  return startOfMonth(date);
}

function firstDayOfThisYear() {
  const date = new Date();

  return startOfYear(date);
}


function firstDayOfLastMonth() {
  const date = subMonths(new Date(), 1);

  return startOfMonth(date);
}
function lastDayOfLastMonth() {
  const date = subMonths(new Date(), 1);

  return endOfMonth(date);
}

function firstDayOfLastYear() {
  const date = subYears(new Date(), 1);

  return startOfYear(date);
}
function lastDayOfLastYear() {
  const date = subYears(new Date(), 1);

  return endOfYear(date);
}

function firstDateOfAllTime() {
  return startOfYear(new Date("1900-01-01"))
}

const last7Days = daysFromToday(7);
const last30Days = daysFromToday(30);

const DateFilterRangeMap: { [range in DateFilterRangeT]: DateFilterItemT } = {
  [DateFilterRangeT.today]: {
    text: 'Today',
    start: startOfToday,
    end: endOfToday
  },
  [DateFilterRangeT.yesterday]: {
    text: 'Yesterday',
    start: startOfYesterday,
    end: endOfYesterday
  },
  [DateFilterRangeT.last7Days]: {
    text: 'Last 7 days',
    start: last7Days,
    end: endOfToday
  },
  [DateFilterRangeT.last30Days]: {
    text: 'Last 30 days',
    start: last30Days,
    end: endOfToday
  },
  [DateFilterRangeT.thisMonth]: {
    text: 'This month',
    start: firstDayOfThisMonth,
    end: endOfToday
  },
  [DateFilterRangeT.lastMonth]: {
    text: 'Last month',
    start: firstDayOfLastMonth,
    end: lastDayOfLastMonth
  },
  [DateFilterRangeT.thisYear]: {
    text: 'This Year',
    start: firstDayOfThisYear,
    end: endOfToday
  },
  [DateFilterRangeT.lastYear]: {
    text: 'Last Year',
    start: firstDayOfLastYear,
    end: lastDayOfLastYear
  },
  [DateFilterRangeT.allTime]: {
    text: 'All Time',
    start: firstDateOfAllTime,
    end: endOfToday
  },
  [DateFilterRangeT.custom]: {
    text: 'Custom date',
    start: startOfToday,
    end: endOfToday
  }
  
  
};

export const defaultDateFilterRange = DateFilterRangeT.allTime;
export const defaultStart = DateFilterRangeMap[defaultDateFilterRange].start;
export const defaultEnd = DateFilterRangeMap[defaultDateFilterRange].end;

export function getDateFilterRangeStart(key: DateFilterRangeT) {
  return DateFilterRangeMap[key].start();
}

export function getDateFilterRangeEnd(key: DateFilterRangeT) {
  return DateFilterRangeMap[key].end();
}

export function getDateRangeFilterOptions() {
  return Object.entries(DateFilterRangeMap).map(([key, { text }]) => ({
    key,
    value: parseInt(key),
    text
  }));
}
