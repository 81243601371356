import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import {
  getReportTypeOptions,
  getTimespanOptions,
  ReportTypeT,
} from "../../entities/Report";
import {
  DateFilterRangeT,
  defaultDateFilterRange,
  getDateFilterRangeEnd,
  getDateFilterRangeStart,
  getDateRangeFilterOptions,
} from "../../entities/transaction/DateFilterRange";
import { getReportFilterDateLabel } from "../../redux/ui/report/reportsUISelectors";
import {
  applyFilters,
  changeFilterDate,
  changeReportTimespan,
  changeReportType,
  toggleFilterCalendar,
  toggleFilterModal,
  toggleShowChart,
} from "../../redux/ui/report/reportsUISlice";
import { getDateLabel } from "../../redux/ui/transaction/filterTransactionSelectors";
import ModalDateRangeSelection from "../ModalDateRangeSelection";
import ModalFilterOptions from "../ModalFilterOptions";
import AppliedTransactionFilter from "../transactions/AppliedTransactionFilter";

let lastValue = defaultDateFilterRange;

const ReportFilterOptions = () => {
  const dispatch = useDispatch();
  // Selectors
  const reportType = useSelector((state) => state.ui.report.rpType);
  const reportTimespan = useSelector((state) => state.ui.report.rpTimespan);
  const showChart = useSelector((state) => state.ui.report.showChart);
  const options = getDateRangeFilterOptions();
  const dateRangeLabel = useSelector((state) =>
    getReportFilterDateLabel(state)
  );
  const isMobile = useSelector((state) => state.ui.isMobile);
  const isFilterModalOpen = useSelector(
    (state) => state.ui.report.isFilterModalOpen
  );
  const appliedAccounts =
    useSelector((state) => state.ui.report.appliedFilters.accounts) || [];
  const appliedCategories =
    useSelector((state) => state.ui.report.appliedFilters.categories) || [];
  const appliedPayees =
    useSelector((state) => state.ui.report.appliedFilters.payees) || [];

  const isCalendarOpen = useSelector((state) => state.ui.report.isCalendarOpen);

  // Variables
  const reportTypeOptions = getReportTypeOptions();
  const reportTimespanOptions = getTimespanOptions();

  //Events
  const changeType = async (value) => {
    await dispatch(changeReportType(value));
    // dispatch(loadReportData());
  };
  const handleDateRange = (value) => {
    if (value === lastValue && value !== DateFilterRangeT.custom) return;
    if (value === DateFilterRangeT.custom) {
      dispatch(toggleFilterCalendar());
    } else {
      dispatch(
        changeFilterDate({
          dateStart: getDateFilterRangeStart(value),
          dateEnd: getDateFilterRangeEnd(value),
        })
      );
      dispatch(changeReportTimespan(value));
      lastValue = value;
    }
  };

  // Render
  return (
    <>
      <div className="container-header">
        <Button.Group basic fluid={isMobile}>
          <Dropdown
            button
            value={reportType}
            options={reportTypeOptions}
            onChange={(_, { value }) => changeType(value)}
          />
          <Button toggle onClick={() => dispatch(toggleShowChart())}>
            <Icon name={showChart ? "hide" : "unhide"} />
            {showChart ? "Hide Chart" : "Show Chart"}
          </Button>
        </Button.Group>

        <Button.Group basic fluid>
          <Dropdown
            button
            className="icon"
            options={options}
            defaultValue={reportTimespan}
            onChange={(_, { value }) => handleDateRange(value)}
            on
            // text={dateRangeLabel}
            labeled
            icon="calendar"
          />

          {reportType === ReportTypeT.ExpenseIncome && (
            <Button
              icon="filter"
              onClick={() => dispatch(toggleFilterModal())}
            />
          )}
        </Button.Group>

        <ModalDateRangeSelection
          isCalendarOpen={isCalendarOpen}
          changeFilterDate={changeFilterDate}
          toggleFilterCalendar={toggleFilterCalendar}
        />

        <ModalFilterOptions
          isFilterModalOpen={isFilterModalOpen}
          appliedAccounts={appliedAccounts}
          appliedCategories={appliedCategories}
          appliedPayees={appliedPayees}
          applyFilters={applyFilters}
          toggleFilterModal={toggleFilterModal}
        />
      </div>
      <AppliedTransactionFilter
        appliedAccounts={appliedAccounts}
        appliedCategories={appliedCategories}
        appliedPayees={appliedPayees}
        applyFilters={applyFilters}
      />
    </>
  );
};

export default ReportFilterOptions;
