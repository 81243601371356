import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import format from "date-fns/format";
import { toast } from "react-toastify";
import ndApi from "../../../common/ndApi";
import { rowsPerPage } from "../../../entities/Transaction";
import {
  defaultDateFilterRange,
  defaultEnd,
  defaultStart,
} from "../../../entities/transaction/DateFilterRange";
import { loadFilteredTransactionsSuccess } from "../../entities/transactionsSlice";
import { getFilters } from "./filterTransactionSelectors";

// Async Thunk Actions
export const loadFilteredTransactions = createAsyncThunk(
  "transaction/loadFilteredTransactions",
  async (_, { getState, dispatch }) => {
    const filters = getFilters(getState());
    console.log(`filters`, filters);

    const response = await ndApi
      .post("transaction/getFiltered.php", filters)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("Axios error: " + err);
      });

    console.log(response.data);

    if (response.data.success === 1) {
      const transactions = response.data.nd_api_data;
      await dispatch(loadFilteredTransactionsSuccess(transactions));
    } else {
      toast.error("loadFilteredTransactions failed: " + response.data.error);
    }
  }
);

export const loadReportFilteredTransactions = createAsyncThunk(
  "transaction/loadReportFilteredTransactions",
  async (filters, { getState, dispatch }) => {
    // console.log(`filters`, filters);

    const response = await ndApi
      .post("transaction/getFiltered.php", filters)
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("Axios error: " + err);
      });

    console.log(response.data);

    if (response.data.success === 1) {
      const transactions = response.data.nd_api_data;
      return transactions;
    } else {
      return [];
    }
  }
);

export const changeFilterDate = createAsyncThunk(
  "transaction/changeFilterDate",
  async (payload, { dispatch }) => {
    await dispatch(changeFilterDateState(payload));
    await dispatch(loadFilteredTransactions());
  }
);

export const applyFilters = createAsyncThunk(
  "transaction/applyFilters",
  async (payload, { dispatch }) => {
    await dispatch(applyFiltersState(payload));
    await dispatch(loadFilteredTransactions());
  }
);

//Initial States
const initialState = {
  dateStart: format(defaultStart(), "yyyy-MM-dd"),
  dateEnd: format(defaultEnd(), "yyyy-MM-dd"),
  filterDateRangeType: defaultDateFilterRange,
  isCalendarOpen: false,
  isFilterModalOpen: false,
  applied: {},
  isLoading: false,
  totalRows: 0,
  page: 1,
  perPage: rowsPerPage,
};

const filterTransactionSlice = createSlice({
  name: "filterTransaction",
  initialState,
  reducers: {
    toggleFilterCalendar: (state) => ({
      ...state,
      isCalendarOpen: !state.isCalendarOpen,
    }),

    changeFilterDateRangeType: (state, { payload }) => ({
      ...state,
      filterDateRangeType: payload,
    }),

    changeFilterDateState: (state, { payload }) => ({
      ...state,
      dateStart: format(payload.dateStart, "yyyy-MM-dd"),
      dateEnd: format(payload.dateEnd, "yyyy-MM-dd"),
      isLoading: true,
    }),
    toggleFilterModal: (state) => ({
      ...state,
      isFilterModalOpen: !state.isFilterModalOpen,
    }),
    changeFilterPage: (state, { payload }) => ({
      ...state,
      page: payload,
    }),

    applyFiltersState: (state, { payload }) => ({ ...state, applied: payload }),
  },
  extraReducers: {
    [loadFilteredTransactionsSuccess]: (state, { payload }) => {
      const totalPages = Math.ceil(payload.length / state.perPage);
      return {
        ...state,
        totalRows: payload.length,
        page: state.page > totalPages ? totalPages : state.page,
      };
    },

    [loadFilteredTransactions.pending]: (state) => {
      state.isLoading = true;
    },
    [loadFilteredTransactions.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [loadFilteredTransactions.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default filterTransactionSlice.reducer;

export const {
  toggleFilterCalendar,
  changeFilterDateState,
  changeFilterDateRangeType,
  toggleFilterModal,
  applyFiltersState,
  changeFilterPage,
} = filterTransactionSlice.actions;
