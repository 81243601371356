import { combineReducers } from "redux";
import accountsUISlice from "./accountsUISlice";
import categoriesUISlice from "./categoriesUISlice";
import categoryGroupUISlice from "./categoryGroupUISlice";
import payeeUISlice from "./payeeUISlice";
import transactionsUISlice from "./transactionsUISlice";

export default combineReducers({
  account: accountsUISlice,
  transaction: transactionsUISlice,
  payee: payeeUISlice,
  category: categoriesUISlice,
  categoryGroups: categoryGroupUISlice,
});
