import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { dbToState } from "../../entities/Category";
import { getTransactionTypeLabel } from "../../entities/Transaction";
import { openCategoryInModal } from "../../redux/ui/form/categoriesUISlice";

const CategoryItem = ({ cat }) => {
  const dispatch = useDispatch();

  const handleEditClick = () => {
    // console.log(`dbToState(payee)`, dbToState(payee));
    dispatch(openCategoryInModal(dbToState(cat)));
  };
  return (
    <div className="account-widget-account">
      <div className="account-widget-account__name">{cat.cat_full_name}</div>
      <div
        className="account-widget-account__balance"
        style={{ color: "lightgrey" }}
      >
        {getTransactionTypeLabel(cat.cg_tx_type)}
      </div>
      <div className="account-widget-account__edit">
        <Button basic circular icon="pencil" onClick={handleEditClick} />
      </div>
    </div>
  );
};

export default CategoryItem;
