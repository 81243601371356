import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  loadAccountDetail,
  loadAccountTransactions,
  setAccountId,
} from "../../redux/ui/accountdetail/accountDetailUISlice";
import { loadAllAccounts } from "../../redux/ui/form/accountsUISlice";
import { loadAllCategories } from "../../redux/ui/form/categoriesUISlice";
import { loadAllPayee } from "../../redux/ui/form/payeeUISlice";
import AccountTransactionFilterOptions from "../accountdetail/AccountTransactionFilterOptions";
import AccountTransactionList from "../accountdetail/AccountTransactionList";
import AccountTransactionListFooter from "../accountdetail/AccountTransactionListFooter";
import AccountTransactionListPager from "../accountdetail/AccountTransactionListPager";

const AccountDetail = () => {
  const dispatch = useDispatch();

  // Parameter
  const { accountId } = useParams();
  const accId = parseInt(accountId);
  console.log(`accId`, accId);

  useEffect(() => {
    const fetchData = async () => {
      //Load Account from database
      dispatch(loadAllAccounts());
      dispatch(loadAllPayee());
      dispatch(loadAllCategories());
      await dispatch(setAccountId(accId));
      dispatch(loadAccountDetail());
      dispatch(loadAccountTransactions());
    };
    fetchData();
  }, [dispatch, accId]);

  // Events

  return (
    <div className="container-full-page flat">
      <AccountTransactionFilterOptions />
      <AccountTransactionListPager />
      <AccountTransactionList />
      <AccountTransactionListPager />
      <AccountTransactionListFooter />
    </div>
  );
};

export default AccountDetail;
