import React from "react";
import Currency from "../entities/Currency";

const Amount = ({ value, showColor = true, showCents = true }) => {
  return (
    <span
      className={`mono ${showColor && (value >= 0 ? "positive" : "negative")}`}
    >
      {/* {console.log("getRupee", Currency.getRupee(value))}
      {console.log("getPaisa", Currency.getPaisa(value))} */}
      {Currency.getRupee(value)}
      <span style={{ fontSize: "0.7em" }}>{`.${Currency.getPaisa(
        value
      )}`}</span>
    </span>
  );
};

export default Amount;
