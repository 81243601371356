import React from "react";
import { useDispatch } from "react-redux";
import { Menu } from "semantic-ui-react";
import {
  getTransactionTypeLabel,
  TransationTypeT,
} from "../../entities/Transaction";
import { changeTransactionType } from "../../redux/ui/form/transactionsUISlice";

const TransactionFormHeader = ({ withTransfer, activeKind }) => {
  const { Expense, Transfer, Income } = TransationTypeT;
  const kinds = withTransfer
    ? [Expense, Transfer, Income]
    : [Expense, Transfer, Income];

  const dispatch = useDispatch();

  return (
    <Menu attached="top" widths={kinds.length}>
      {kinds.map((kind) => (
        <Menu.Item
          key={kind}
          color={kind === Expense ? "red" : kind === Income ? "green" : "black"}
          name={getTransactionTypeLabel(kind)}
          active={kind === activeKind}
          onClick={() => dispatch(changeTransactionType(kind))}
        />
      ))}
    </Menu>
  );
};

export default TransactionFormHeader;
