import React from "react";
import { Table } from "semantic-ui-react";
import Amount from "../Amount";

const ReportIncomeExpenseItem = ({ item, showBalance }) => {
  return (
    // <div className="report-item-list-item">
    //   <span className="report-item-list-item__name">{item.monthName}</span>
    //   <div className="report-item-list-item__column">
    //     <Amount value={item.income} />
    //   </div>
    //   <div className="report-item-list-item__column">
    //     <Amount value={item.expense} />
    //   </div>
    // </div>
    <Table.Row>
      <Table.Cell>{item.monthName}</Table.Cell>
      <Table.Cell textAlign="right">
        <Amount value={item.income} />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Amount value={item.expense} />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Amount
          value={showBalance ? item.balance : item.income + item.expense}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ReportIncomeExpenseItem;
