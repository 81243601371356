import React from "react";
import { useSelector } from "react-redux";
import { getNetBalance } from "../../redux/entities/accountsSelectors";
import Amount from "../Amount";

const NetBalance = () => {
  //Selectors
  const netBalance = useSelector((state) => getNetBalance(state));

  return (
    <div className="net-worth__total">
      <Amount value={netBalance} />
    </div>
  );
};

export default NetBalance;
