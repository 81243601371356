import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import format from "date-fns/format";
import { toast } from "react-toastify";
import ndApi from "../../../common/ndApi";
import { rowsPerPage } from "../../../entities/Transaction";
import {
  defaultDateFilterRange,
  defaultEnd,
  defaultStart,
} from "../../../entities/transaction/DateFilterRange";
import { loadAccountTransactonsSuccess } from "../../entities/accountTransactionsSlice";
import { getFilters } from "./accountDetailUISelectors";

// Async Thunk Actions

export const changeAccount = createAsyncThunk(
  "accountDetail/changeAccount",
  async (accId, { dispatch, getState }) => {
    await dispatch(setAccountId(accId));
    dispatch(loadAccountDetailAndTransactions());
  }
);

export const changeFilterDate = createAsyncThunk(
  "accountDetail/changeFilterDate",
  async (payload, { dispatch, getState }) => {
    await dispatch(changeFilterDateState(payload));
    dispatch(loadAccountTransactions());
  }
);

export const loadAccountDetailAndTransactions = createAsyncThunk(
  "loadAccountDetailAndTransactions",
  async (_, { dispatch, getState }) => {
    dispatch(loadAccountDetail());
    dispatch(loadAccountTransactions());
  }
);

export const loadAccountDetail = createAsyncThunk(
  "accountDetail/loadAccountDetail",
  async (_, { getState, dispatch }) => {
    const accId = getState().ui.accountDetail.accId;
    const response = await ndApi
      .post("account/getDetail.php", { acc_id: accId })
      .catch((err) => {
        console.log("Err: ", err);
        toast.error("Axios error: " + err);
      });

    if (response.data.success === 1) {
      if (response.data.nd_api_data.length > 0) {
        const accDetailData = response.data.nd_api_data[0];
        // console.log(`accDetailData`, accDetailData);
        await dispatch(loadAccountDetailSuccess(accDetailData));
      }
    } else {
      toast.error("loadAccountDetail failed: " + response.data.error);
    }
  }
);

export const loadAccountTransactions = createAsyncThunk(
  "accountDetail/loadAccountTransactions",
  async (_, { getState, dispatch }) => {
    const filters = getFilters(getState());
    console.log(`filters`, filters);

    const response = await ndApi
      .post("account/getAccountTransactions.php", filters)
      .catch((err) => {
        console.log("Err: ", err);
      });

    console.log(response.data);

    if (response.data.success === 1) {
      const transactions = response.data.nd_api_data;
      await dispatch(loadAccountTransactonsSuccess(transactions));
    } else {
      toast.error("loadAccountTransactions failed: " + response.data.error);
    }
  }
);

// Initial State
const initialState = {
  accId: -1,
  accName: "",
  accGroup: "",
  accBal: 0,
  dateStart: format(defaultStart(), "yyyy-MM-dd"),
  dateEnd: format(defaultEnd(), "yyyy-MM-dd"),
  filterDateRangeType: defaultDateFilterRange,
  totalRows: 0,
  page: 1,
  perPage: rowsPerPage,
  isLoadingDetail: false,
  isLoadingTx: false,
  isTxModalOpen: false,
  isCalendarOpen: false,
};

// Slice
const accountDetailUISlice = createSlice({
  name: "accountDetailUI",
  initialState,
  reducers: {
    toggleFilterCalendar: (state) => {
      state.isCalendarOpen = !state.isCalendarOpen;
    },
    changeFilterDateRangeType: (state, { payload }) => ({
      ...state,
      filterDateRangeType: payload,
    }),
    changeFilterDateState: (state, { payload }) => ({
      ...state,
      dateStart: format(payload.dateStart, "yyyy-MM-dd"),
      dateEnd: format(payload.dateEnd, "yyyy-MM-dd"),
      isLoading: true,
    }),
    setAccountId: (state, { payload }) => {
      state.accId = payload;
    },
    loadAccountDetailSuccess: (state, { payload }) => {
      state.accName = payload.acc_name;
      state.accGroup = payload.acc_group;
      state.accBal = payload.acc_curr_balance;
    },
    changeFilterPage: (state, { payload }) => ({
      ...state,
      page: payload,
    }),
  },
  extraReducers: {
    [loadAccountTransactonsSuccess]: (state, { payload }) => {
      const totalPages = Math.ceil(payload.length / state.perPage);
      return {
        ...state,
        totalRows: payload.length,
        page: state.page > totalPages ? totalPages : state.page,
      };
    },

    //loadAccountDetail actions
    [loadAccountDetail.pending]: (state) => {
      state.isLoadingDetail = true;
    },
    [loadAccountDetail.fulfilled]: (state) => {
      state.isLoadingDetail = false;
    },
    [loadAccountDetail.rejected]: (state) => {
      state.isLoadingDetail = false;
    },

    //loadAccountTransactions actions
    [loadAccountTransactions.pending]: (state) => {
      state.isLoadingTx = true;
    },
    [loadAccountTransactions.fulfilled]: (state) => {
      state.isLoadingTx = false;
    },
    [loadAccountTransactions.rejected]: (state) => {
      state.isLoadingTx = false;
    },
  },
});

export const {
  setAccountId,
  toggleFilterCalendar,
  changeFilterDateState,
  changeFilterDateRangeType,
  changeFilterPage,
  loadAccountDetailSuccess,
} = accountDetailUISlice.actions;

export default accountDetailUISlice.reducer;
