import { ReportDataT, ReportStateT, ReportTimespanT, ReportTypeT } from "../Report";
import { TransactionStateT, TransationTypeT } from "../Transaction";
import range from 'lodash/range';
import format from "date-fns/format";
import getDaysInMonth from "date-fns/getDaysInMonth";

const { Expense, Income } = TransationTypeT;

export default function ExpenseIncomeReportData(
  reportState: ReportStateT,
  transactions: TransactionStateT[]
) : ReportDataT {

  const labels = 
    reportState.rpTimespan === ReportTimespanT.Yearly
      ? range(0,12).map(month => format(new Date().setMonth(month), "MMM"))
      : range(1, getDaysInMonth(new Date(reportState.rpDate.start)) + 1).map(day => `${day}`.padStart(2,'0'));

  // const data = [
  //   new Array(labels.length).fill(0), // income series
  //   new Array(labels.length).fill(0)  // expense series
  // ];

  const datasets = [
    {
      label: "Incomes",
      data: new Array(labels.length).fill(0),
      backgroundColor: "rgba(7, 176, 24,0.2)",
      borderColor: "rgba(7, 176, 24,1)",
      borderWidth: 1,
      stack: "Stack 0"
    }, // income series
    {
      label: "Expenses",
      data: new Array(labels.length).fill(0),
      backgroundColor: "rgba(207, 8, 12,0.2)",
      borderColor: "rgba(207, 8, 12,1)",
      borderWidth: 1,
      stack: "Stack 1"
    }  // expense series
  ];

  // const excluded = new Set(reportState.rpExcludeCategories);

  for (const tx of transactions) {
    if (tx.tx_type !== Expense && tx.tx_type !== Income) continue;

    const period = format(new Date(tx.tx_date), 
      reportState.rpTimespan === ReportTimespanT.Yearly ? 'M': 'd');

    const txAmount = Math.abs(tx.tx_amount);

    datasets[tx.tx_type === Income ? 0 : 1].data[parseInt(period)-1] += txAmount;
    // if (excluded.has(tx.tx_cat_id)){
    //   datasets[0].data[parseInt(period)-1] -= txAmount;
    //   datasets[1].data[parseInt(period)-1] -= txAmount;
    // }
  }

  return {
    labels,
    datasets
  };
}