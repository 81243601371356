import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Header, Modal } from "semantic-ui-react";
import { getPayeeModalState } from "../../redux/ui/form/payeeUISelectors";
import {
  removePayeeRequest,
  resetPayeeForm,
} from "../../redux/ui/form/payeeUISlice";
import PayeeDeleteStrategy from "./PayeeDeleteStrategy";
import PayeeForm from "./PayeeForm";

const ModalPayeeForm = () => {
  const dispatch = useDispatch();

  // Selectors
  const modalState = useSelector((state) => getPayeeModalState(state));
  const isEdit =
    useSelector((state) => state.ui.form.payee.py_id) !== undefined;

  const reset = () => {
    // if (this.props.modal.isDeleteRunning) return false;
    dispatch(resetPayeeForm());
  };

  // Render funs
  const renderModalActions = () => {
    if (!isEdit) return;

    return modalState.isDeleteRequest ? (
      <Button
        labelPosition="left"
        onClick={() => dispatch(removePayeeRequest())}
        content="Cancel"
        icon="cancel"
        disabled={modalState.isDeleting}
      />
    ) : (
      <Button
        negative
        labelPosition="right"
        onClick={() => dispatch(removePayeeRequest())}
        content="Delete"
        icon="trash"
        disabled={modalState.isDeleting || modalState.isSaving}
      />
    );
  };

  // Render
  return (
    <Modal
      closeIcon
      size="small"
      className="account-form"
      open={modalState.isOpen}
      onClose={reset}
    >
      <Header
        icon="file text outline"
        content={isEdit ? "Edit Payee" : "New Payee"}
      />

      <Modal.Content>
        {modalState.isDeleteRequest ? <PayeeDeleteStrategy /> : <PayeeForm />}
      </Modal.Content>

      <Modal.Actions>{renderModalActions()}</Modal.Actions>
    </Modal>
  );
};

export default ModalPayeeForm;
