import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Menu } from "semantic-ui-react";
import {
  getTransactionTypeLabel,
  TransationTypeT,
} from "../../entities/Transaction";
import {
  changeCategoryTxType,
  loadAllCategories,
  openCategoryInModal,
} from "../../redux/ui/form/categoriesUISlice";
import { loadAllCategoryGroups } from "../../redux/ui/form/categoryGroupUISlice";
import CategoryList from "../categories/CategoryList";

const Categories = () => {
  const dispatch = useDispatch();

  const { Expense, Transfer, Income } = TransationTypeT;
  const types = [Expense, Income];

  // Selectors
  const formState = useSelector((state) => state.ui.form.category);

  // Initial data fetching
  useEffect(() => {
    const fetchData = async () => {
      //Load data from database
      await dispatch(loadAllCategories());
      await dispatch(loadAllCategoryGroups());
    };

    fetchData();
  }, []);

  // Events

  // Render
  return (
    <div className="container-full-page flat search-page">
      <div className="container-header">
        {/* <Button.Group basic fluid> */}
        <Button
          basic
          icon="plus"
          labelPosition="left"
          content="New"
          onClick={() => dispatch(openCategoryInModal())}
        />
        {/* </Button.Group> */}
      </div>

      <Menu attached="top" widths={types.length}>
        {types.map((kind) => (
          <Menu.Item
            key={kind}
            icon={kind === Expense ? "arrow up" : "arrow down"}
            color={
              kind === Expense ? "red" : kind === Income ? "green" : "black"
            }
            name={getTransactionTypeLabel(kind)}
            active={kind === formState.cg_tx_type}
            onClick={() => dispatch(changeCategoryTxType(kind))}
          />
        ))}
      </Menu>

      <CategoryList activeKind={formState.cg_tx_type} />
    </div>
  );
};

export default Categories;
