import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ndApi from "../../../common/ndApi";
import { TransationTypeT } from "../../../entities/Transaction";
import noCache from "../../../utils/noCache";
import {
  loadExpenseCategoryGroupSuccess,
  loadIncomeCategoryGroupSuccess,
  saveCategoryGroupSuccess,
} from "../../entities/categoryGroupSlice";

const { Expense, Transfer, Income } = TransationTypeT;

// Async Thunk Actions
export const loadAllCategoryGroups = createAsyncThunk(
  "categoryGroup/getAll",
  async (_, { dispatch }) => {
    const response = await ndApi
      .post(noCache("categorygroup/getByTxType.php"), { cg_tx_type: Expense })
      .catch((err) => {
        console.log("Err: ", err);
      });

    console.log(`loadAllCategoryGroups`, response);
    if (response.data.success === 1) {
      const catGroups = response.data.nd_api_data;
      await dispatch(loadExpenseCategoryGroupSuccess(catGroups));
    }

    const response2 = await ndApi
      .post(noCache("categorygroup/getByTxType.php"), { cg_tx_type: Income })
      .catch((err) => {
        console.log("Err: ", err);
      });

    console.log(`loadAllCategoryGroups`, response2);
    if (response2.data.success === 1) {
      const catGroups = response2.data.nd_api_data;
      await dispatch(loadIncomeCategoryGroupSuccess(catGroups));
    }
  }
);

export const saveCategoryGroup = createAsyncThunk(
  "categoryGroup/insertOrUpdate",
  async (cg, { dispatch }) => {
    const isInsertingCg = cg.cg_id === undefined;
    const queryUrl = isInsertingCg ? "insert.php" : "update.php";

    const response = await ndApi
      .post("categorygroup/" + queryUrl, cg)
      .catch((err) => {
        console.log("Err: ", err);
      });

    console.log("response.data: ", response.data);

    if (response.data.success === 1 && response.data.nd_api_data > 0) {
      if (isInsertingCg) {
        cg.cg_id = response.data.nd_api_data;
        toast.success(
          "Category Group '" + cg.cg_name + "' added susccessfully"
        );
      } else {
        toast.success(
          "Category Group '" + cg.cg_name + "' updated susccessfully"
        );
      }
      await dispatch(saveCategoryGroupSuccess(cg));
    } else if (response.data.success === 0) {
      toast.error("Category Group save failed: " + response.data.error);
    } else {
      toast.error("Category Group save returned with 0 effected rows ");
    }
  }
);

//Initial States
const initialState = {
  isSaving: false,
  isLoading: false,
  isDeleting: false,
  isModalOpen: false,
  isDeleteRequest: false,
};

const categoryGroupUISlice = createSlice({
  name: "categoryGroupUI",
  initialState,
  reducers: {},
  extraReducers: {
    [loadAllCategoryGroups.pending]: (state) => {
      state.isLoading = true;
    },
    [loadAllCategoryGroups.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [loadAllCategoryGroups.rejected]: (state) => {
      state.isLoading = false;
    },

    [saveCategoryGroup.pending]: (state) => {
      state.isSaving = true;
    },
    [saveCategoryGroup.fulfilled]: (state) => {
      state.isSaving = false;
    },
    [saveCategoryGroup.rejected]: (state) => {
      state.isSaving = false;
    },
  },
});

// export const {} = categoryGroupUISlice.actions;
export default categoryGroupUISlice.reducer;
