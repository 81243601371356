import React from "react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import { TransationTypeT } from "../../entities/Transaction";
import {
  getAccountTransactionExpenseTotal,
  getAccountTransactionIncomeTotal,
} from "../../redux/entities/accountTransactionsSelectors";
import Amount from "../Amount";
import LoaderListRtl from "../LoaderListRtl";

const AccountTransactionListFooter = () => {
  // Selectors
  const filterIncomeTotal = useSelector((state) =>
    getAccountTransactionIncomeTotal(state)
  );
  const filterExpenseTotal =
    useSelector((state) => getAccountTransactionExpenseTotal(state)) * -1;
  const isLoadingTx = useSelector(
    (state) => state.ui.accountDetail.isLoadingTx
  );

  return (
    <div className="transactions-list-footer">
      {isLoadingTx ? (
        <LoaderListRtl />
      ) : (
        <Table fixed basic="very" unstackable>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Total income</Table.Cell>
              <Table.Cell textAlign="right">
                <Amount value={filterIncomeTotal} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Total expense</Table.Cell>
              <Table.Cell textAlign="right">
                <Amount value={filterExpenseTotal} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell />
              <Table.Cell textAlign="right">
                <Amount value={filterIncomeTotal + filterExpenseTotal} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </div>
  );
};

export default AccountTransactionListFooter;
