import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadAllAccounts } from "../../redux/ui/form/accountsUISlice";
import { loadAllCategories } from "../../redux/ui/form/categoriesUISlice";
import { loadAllPayee } from "../../redux/ui/form/payeeUISlice";
import ReportContent from "../reports/ReportContent";
import ReportFilterOptions from "../reports/ReportFilterOptions";

const Reports = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      //Load Accounts from database
      await dispatch(loadAllAccounts());
      await dispatch(loadAllPayee());
      await dispatch(loadAllCategories());
      // await dispatch(loadIncomeExpenseReportData());
    };

    fetchData();
  }, [dispatch]);

  return (
    <div className="container-full-page flat">
      <ReportFilterOptions />
      <ReportContent />
      {/* <ReportFilter /> */}
    </div>
  );
};

export default Reports;
