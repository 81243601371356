import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { Statistic, Table } from "semantic-ui-react";
import Currency from "../../entities/Currency";
import {
  getIncomeExpenseGroupedData,
  getNetWorthChartData,
} from "../../redux/ui/report/reportsUISelectors";
import { loadNetWorthReportData } from "../../redux/ui/report/reportsUISlice";
import Amount from "../Amount";
import { barChartOptions } from "./BarChartOptions";
import ReportIncomeExpenseGroup from "./ReportIncomeExpenseGroup";

const ReportNetWorth = () => {
  const dispatch = useDispatch();
  // Selectors
  const data = useSelector((state) => state.ui.report.rpData);
  const showChart = useSelector((state) => state.ui.report.showChart);
  const income = data ? data.map((item) => item.income) : [];
  const expense = data ? data.map((item) => item.expense) : [];
  const chartData = useSelector((state) => getNetWorthChartData(state));
  const groupedData = useSelector((state) =>
    getIncomeExpenseGroupedData(state)
  );
  const groups = Object.keys(groupedData);
  // console.log(`chartData`, chartData);

  useEffect(() => {
    const fetchData = async () => {
      //Load Accounts from database
      await dispatch(loadNetWorthReportData());
    };

    fetchData();
  }, [dispatch]);

  return (
    <>
      {/* <div className="mt-report-stats">
        <Statistic.Group size="mini">
          <Statistic>
            <Statistic.Value>
              <Amount value={income.reduce((sum, cur) => sum + cur, 0)} />
            </Statistic.Value>
            <Statistic.Label>Total income</Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>
              <Amount value={expense.reduce((sum, cur) => sum + cur, 0)} />
            </Statistic.Value>
            <Statistic.Label>Total expense</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </div> */}

      {data.length > 0 ? (
        <>
          {showChart && (
            <Bar
              // className="ct-octave mt-report-expense-income"
              type="bar"
              data={chartData}
              options={barChartOptions}
            />
          )}

          <div className="report-table">
            <Table size="small" unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>Month</Table.HeaderCell>
                  <Table.HeaderCell width={4} textAlign="right">
                    Income
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4} textAlign="right">
                    Expense
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4} textAlign="right">
                    Balance
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {groups.map((group) => (
                  <ReportIncomeExpenseGroup
                    key={group}
                    group={groupedData[group]}
                    showBalance={true}
                  />
                ))}
              </Table.Body>
            </Table>
          </div>
        </>
      ) : (
        <div className="report-item-list__empty">Report is empty.</div>
      )}
    </>
  );
};

export default ReportNetWorth;
