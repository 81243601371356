
export interface DynamicConfig {
  apiUrl: string;
  environment: "DEV"|"TEST"|"PROD"
}

export const defaultConfig: DynamicConfig = {
  apiUrl:"http://localhost/ndmoneytracker_v02/api",
  environment:"DEV"
}

class GlobalConfig {
  config: DynamicConfig = defaultConfig;
  notDefinedYet = true;

  public get(): DynamicConfig {
    if (this.notDefinedYet) {
      throw new Error("Global config has not been defined yet.");
    } else {
      return this.config;
    }
  }

  public set(value: DynamicConfig): void {
    if (this.notDefinedYet) {
      this.config = value;
      this.notDefinedYet = false;
    } else {
      throw new Error("Global config has already been defined");
    }
  }
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = "config.json"