import React from "react";
import { useSelector } from "react-redux";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
import LoaderList from "../LoaderList";
import ModalTransactionForm from "./ModalTransactionForm";
import TransactionItem from "./TransactionItem";

const TransactionList = ({ transactions, isLoading }) => {
  const renderTransaction = (transaction) => (
    <TransactionItem key={transaction.tx_id} transaction={transaction} />
  );

  // Render
  // if (isLoading) return <LoaderAccountList />;

  return (
    <Segment className="transactions-list__wrapper">
      {/* <Dimmer inverted active={isLoading}>
        <Loader active inline="centered" />
      </Dimmer> */}
      {isLoading ? (
        <LoaderList />
      ) : transactions.length > 0 ? (
        transactions.map(renderTransaction)
      ) : (
        <div className="transactions-list__empty">No transactions found.</div>
      )}
      <ModalTransactionForm />
    </Segment>
  );
};

export default TransactionList;
