import React, { useState, useEffect } from "react";
import { Form, Checkbox, Input, Button } from "semantic-ui-react";
import { formToDb, getAccountGroupOptions } from "../../entities/Account";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBalance,
  changeGroup,
  changeName,
  toggleOnDashboard,
  saveAccount,
  updateAccount,
} from "../../redux/ui/form/accountsUISlice";

const AccountForm = () => {
  const dispatch = useDispatch();

  // Selectors
  const accGroupsOptions = getAccountGroupOptions();
  const formState = useSelector((state) => state.ui.form.account);
  const isSaving = useSelector((state) => state.ui.form.account.isSaving);

  // useEffect(() => {
  //   setAccGroupsOptions(getAccountGroupOptions());
  // }, []);

  // Inserting a new account into the Database.
  const handleSubmit = (e) => {
    e.preventDefault();
    const account = formToDb(formState);
    console.log("formToState(account)", account);

    dispatch(saveAccount(account));
  };

  return (
    <Form className="account-form" onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Input
          width={9}
          required
          label="Name"
          placeholder="Account name"
          value={formState.acc_name}
          onChange={(e, { value }) => dispatch(changeName(value))}
        />
        <Form.Select
          width={7}
          label="Group"
          value={formState.acc_group}
          options={accGroupsOptions}
          onChange={(e, { value }) => dispatch(changeGroup(value))}
        />
      </Form.Group>
      <Form.Group style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Form.Field width={9} style={{ paddingTop: "0.5em" }}>
          <Checkbox
            label="Show on Dashboard"
            checked={formState.acc_on_dashboard}
            onChange={() => dispatch(toggleOnDashboard())}
          />
        </Form.Field>
        <Form.Field className="input-right" width={7}>
          {/* {formState.acc_id ? (
            <Amount
              value={Currency.stringToNumber(formState.acc_init_balance)}
            />
          ) : ( */}
          <Input
            fluid
            type="number"
            placeholder="Balance"
            labelPosition="right"
            label="INR"
            step="1"
            value={formState.acc_init_balance || ""}
            onChange={(e, { value }) => dispatch(changeBalance(value))}
          />
          {/* )} */}
        </Form.Field>
      </Form.Group>
      {/* <BalanceTable {...this.props} /> */}
      <Form.Group>
        <Form.Field width={9}></Form.Field>
        <Form.Button
          width={7}
          primary
          fluid
          loading={isSaving}
          disabled={isSaving}
          content={formState.acc_id ? "Save Account" : "Add Account"}
        />
      </Form.Group>
    </Form>
  );
};

export default AccountForm;
