import EntityMap from "../../entities/EntityMap";
import { createSlice } from "@reduxjs/toolkit";

import {
  loadPayeeSuccess,
  removePayeeSuccess,
} from "../ui/payee/filteredPayeeSlice";

//Initial States
const initialState = EntityMap.fromArray([]);

//Slice
const payeeSlice = createSlice({
  name: "payee",
  initialState,
  reducers: {
    // Action creator and handler in one function
    // Will handle the action type `'accounts/loadAccountsSuccess'`

    savePayeeSuccess: (state, { payload }) => {
      const payee = payload;
      return EntityMap.set(state, payee, "py_id");
    },
  },
  extraReducers: {
    [removePayeeSuccess]: (state, { payload }) => {
      const payee_id = payload;
      return EntityMap.remove(state, payee_id, "py_id");
    },
    [loadPayeeSuccess]: (state, { payload }) => {
      const payee = payload;
      return EntityMap.fromArray(payee, "py_id");
    },
  },
});

export default payeeSlice.reducer;

export const { savePayeeSuccess } = payeeSlice.actions;
