import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import Amount from "../Amount";
import { useDispatch } from "react-redux";
import { openAccountInModal } from "../../redux/ui/form/accountsUISlice";
import { stateToForm } from "../../entities/Account";

const AccountItem = ({ account, openInModal }) => {
  const dispatch = useDispatch();
  const handleEditClick = () => {
    // console.log("stateToForm(account)", stateToForm(account));
    dispatch(openAccountInModal(stateToForm(account)));
    // this.props.openAccountInModal(stateToForm(this.props.account));
  };

  return (
    <div className="account-widget-account">
      <div className="account-widget-account__name">
        <Link to={`/accountdetail/${account.acc_id}`}>{account.acc_name}</Link>
      </div>
      <div className="account-widget-account__balance">
        <Amount value={account.acc_curr_balance} />
      </div>
      {openInModal && (
        <div className="account-widget-account__edit">
          <Button basic circular icon="pencil" onClick={handleEditClick} />
        </div>
      )}
    </div>
  );
};

export default AccountItem;
