import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import AccountList from "../accounts/AccountList";
import { loadAllAccounts } from "../../redux/ui/form/accountsUISlice";
import CollapsibleSection from "../CollapsibleSection";
import TransactionForm from "../transactions/TransactionForm";
import { loadAllPayee } from "../../redux/ui/form/payeeUISlice";
import { loadAllCategories } from "../../redux/ui/form/categoriesUISlice";
import { loadRecentTransactions } from "../../redux/ui/transaction/recentTransactionSlice";
import RecentTransactions from "../transactions/RecentTransactions";
import { selectPayeeByPage } from "../../redux/entities/payeeSelectors";
import {
  getDashboardGroupedAssetAccounts,
  getDashboardGroupedCheckingAccounts,
} from "../../redux/entities/accountsSelectors";
import NetBalance from "../dashboard/NetBalance";
import NetAssets from "../dashboard/NetAssets";

const Dashboard = () => {
  const dispatch = useDispatch();
  const payeeList = useSelector((state) => selectPayeeByPage(state));
  const isLoadingAcc = useSelector((state) => state.ui.form.account.isLoading);
  const isLoadingTxn = useSelector(
    (state) => state.ui.transaction.recent.isLoading
  );
  const groupedCheckingAccounts = useSelector((state) =>
    getDashboardGroupedCheckingAccounts(state)
  );
  const groupedAssetAccounts = useSelector((state) =>
    getDashboardGroupedAssetAccounts(state)
  );
  // console.log(`payeeList`, payeeList);

  useEffect(() => {
    // const fetchData = async () => {
    //   //Load Accounts from database

    // };

    // fetchData();
    dispatch(loadAllAccounts());
    dispatch(loadAllPayee());
    dispatch(loadAllCategories());
    dispatch(loadRecentTransactions());
  }, [dispatch]);

  return (
    <div className="container-full-page">
      <Grid>
        <Grid.Row>
          <Grid.Column computer={6} mobile={16}>
            <CollapsibleSection
              name="net_balance"
              label="Net Balance"
              LabelComponent={NetBalance}
              isLoading={isLoadingAcc}
            >
              <AccountList
                groupedAccounts={groupedCheckingAccounts}
                isDashboard
              />
            </CollapsibleSection>

            <CollapsibleSection
              name="net_assets"
              label="Net Assets"
              LabelComponent={NetAssets}
              isLoading={isLoadingAcc}
            >
              <AccountList groupedAccounts={groupedAssetAccounts} isDashboard />
            </CollapsibleSection>
          </Grid.Column>

          <Grid.Column computer={10} mobile={16}>
            <CollapsibleSection name="add_tx" label="New Transaction">
              <TransactionForm />
            </CollapsibleSection>
            <CollapsibleSection
              name="recent_tx"
              label="Recent Transactions"
              isLoading={isLoadingTxn}
            >
              <RecentTransactions />
            </CollapsibleSection>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Dashboard;
