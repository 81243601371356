import { createDraftSafeSelector } from "@reduxjs/toolkit";
import EntityMap from "../../entities/EntityMap";
import sortByName from "../../utils/sortByName";
import { AccountGroupT, getGroupName } from "../../entities/Account";
import { createSelector } from "reselect";

export const getAccountsList = (state) =>
  EntityMap.map(state.entities.accounts, (account) => ({ ...account })).sort(
    // sort by acc_name
    (a, b) => {
      if (a.acc_name < b.acc_name) return -1;
      if (a.acc_name > b.acc_name) return 1;
      return 0;
    }
  );

export const getAccountsNameMap = createSelector(getAccountsList, (accounts) =>
  accounts.reduce((acc, account) => {
    acc[account.acc_id] = account.acc_name;
    return acc;
  }, {})
);

export const getVisibleAccountsList = createSelector(
  getAccountsList,
  (accounts) => accounts.filter((account) => !account.acc_archived)
);

export const getCheckingAccountsList = createSelector(
  getVisibleAccountsList,
  (accounts) =>
    accounts.filter((account) => account.acc_group !== AccountGroupT.Asset)
);

export const getAssetAccountsList = createSelector(
  getVisibleAccountsList,
  (accounts) =>
    accounts.filter((account) => account.acc_group === AccountGroupT.Asset)
);

export const getDashboardCheckingAccountsList = createSelector(
  getCheckingAccountsList,
  (accounts) => accounts.filter((account) => account.acc_on_dashboard)
);

export const getDashboardAssetAccountsList = createSelector(
  getAssetAccountsList,
  (accounts) => accounts.filter((account) => account.acc_on_dashboard)
);

const groupAccounts = (accounts) => {
  const grouped = accounts.reduce((grouped, account) => {
    const group = account.acc_group;
    if (!grouped[group]) {
      grouped[group] = {
        name: getGroupName(group),
        accounts: [],
        total: 0,
      };
    }

    grouped[group].accounts.push(account);
    grouped[group].total += account.acc_curr_balance;

    return grouped;
  }, {});

  for (const group of Object.keys(grouped)) {
    grouped[group].accounts.sort(sortByName);
  }

  return grouped;
};

export const getVisibleGroupedAccounts = createSelector(
  getVisibleAccountsList,
  groupAccounts
);

export const getDashboardGroupedCheckingAccounts = createSelector(
  getDashboardCheckingAccountsList,
  groupAccounts
);

export const getDashboardGroupedAssetAccounts = createSelector(
  getDashboardAssetAccountsList,
  groupAccounts
);

export const getAccountsAsOptions = createSelector(
  getVisibleGroupedAccounts,
  (groups) => {
    const options = [];
    for (const group of Object.keys(groups)) {
      for (const account of groups[group].accounts) {
        options.push({
          key: account.acc_id,
          value: account.acc_id,
          text: account.acc_name,
          description: getGroupName(group),
        });
      }
    }
    return options;
  }
);

export const getNetBalance = createSelector(getAccountsList, (accounts) =>
  accounts
    .filter((account) => account.acc_group !== AccountGroupT.Asset)
    .reduce((netWorth, account) => netWorth + account.acc_curr_balance, 0)
);

export const getNetAssets = createSelector(getAccountsList, (accounts) =>
  accounts
    .filter((account) => account.acc_group === AccountGroupT.Asset)
    .reduce((netWorth, account) => netWorth + account.acc_curr_balance, 0)
);
