import React, { useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import {
  getCategoryChartData,
  getCategoryGroupedData,
} from "../../redux/ui/report/reportsUISelectors";
import { loadCategoryReportData } from "../../redux/ui/report/reportsUISlice";
import ReportCategoryGroup from "./ReportCategoryGroup";

const ReportCategory = ({ catType }) => {
  const dispatch = useDispatch();
  // Selectors
  const data = useSelector((state) => state.ui.report.rpData);
  const showChart = useSelector((state) => state.ui.report.showChart);
  const groupedData = useSelector((state) => getCategoryGroupedData(state));
  const groups = Object.keys(groupedData);

  const chartData = useSelector((state) => getCategoryChartData(state));
  console.log(`chartData`, chartData);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
    },
    layout: {
      padding: 50,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      //Load Accounts from database
      await dispatch(loadCategoryReportData(catType));
    };

    fetchData();
  }, [dispatch, catType]);

  return groups.length > 0 ? (
    <>
      {showChart && (
        <Pie
          // className="ct-octave mt-report-expense-income"
          data={chartData}
          options={options}
        />
      )}

      <div className="report-table">
        <Table size="small" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={8}>Category</Table.HeaderCell>

              <Table.HeaderCell width={8} textAlign="right">
                Total Amount
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {groups.map((group) => (
              <ReportCategoryGroup key={group} group={groupedData[group]} />
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  ) : (
    <div className="report-item-list__empty">Report is empty.</div>
  );
};

export default ReportCategory;
