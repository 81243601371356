import React, { useState } from "react";
import { Dropdown, Form, Menu, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCategoryGroup,
  changeCategoryTxType,
  changeName,
  saveCategory,
} from "../../redux/ui/form/categoriesUISlice";
import {
  getTransactionTypeLabel,
  TransationTypeT,
} from "../../entities/Transaction";
import { formToDb } from "../../entities/Category";
import { getCategoryGroupOptions } from "../../redux/entities/categoryGroupSelectors";
import { saveCategoryGroup } from "../../redux/ui/form/categoryGroupUISlice";
import { toast } from "react-toastify";

const CategoryForm = () => {
  const dispatch = useDispatch();

  const { Expense, Transfer, Income } = TransationTypeT;
  const types = [Expense, Income];

  // Selectors
  const formState = useSelector((state) => state.ui.form.category);
  const isAddingCg = useSelector(
    (state) => state.ui.form.categoryGroups.isSaving
  );
  const isSaving = useSelector((state) => state.ui.form.category.isSaving);
  const catGroupOptions = useSelector((state) =>
    getCategoryGroupOptions(state, state.ui.form.category.cg_tx_type)
  );

  // Local State
  const [catError, setCatError] = useState(false);

  // Funcs
  const validateForm = () => {
    if (!formState.cat_cg_id || formState.cat_cg_id == null) {
      toast.error("Please select Category Group");
      setCatError(true);
      return false;
    }
    return true;
  };

  // Events.
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const cat = formToDb(formState);
      console.log("formToState(cat)", cat);
      dispatch(saveCategory(cat));
    }
  };

  // Render
  return (
    <>
      <Menu attached="top" widths={types.length}>
        {types.map((kind) => (
          <Menu.Item
            key={kind}
            icon={kind === Expense ? "arrow up" : "arrow down"}
            color={
              kind === Expense ? "red" : kind === Income ? "green" : "black"
            }
            name={getTransactionTypeLabel(kind)}
            active={kind === formState.cg_tx_type}
            onClick={() => dispatch(changeCategoryTxType(kind))}
          />
        ))}
      </Menu>

      <Segment attached="bottom">
        <Form className="account-form" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Field width={6}>
              <label required>Category Group</label>
              <Dropdown
                error={catError}
                selection
                search
                allowAdditions
                closeOnChange
                placeholder="Choose category group"
                value={formState.cat_cg_id}
                options={catGroupOptions}
                onChange={(e, { value }) => {
                  setCatError(false);
                  dispatch(changeCategoryGroup(value));
                }}
                onAddItem={(_, { value }) =>
                  dispatch(
                    saveCategoryGroup({
                      cg_name: value,
                      cg_tx_type: formState.cg_tx_type,
                    })
                  )
                }
                loading={isAddingCg}
              />
            </Form.Field>
            <Form.Input
              width={10}
              required
              label="Name"
              placeholder="Category name"
              value={formState.cat_name}
              onChange={(e, { value }) => dispatch(changeName(value))}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={11}></Form.Field>
            <Form.Button
              width={5}
              primary
              fluid
              loading={isSaving}
              disabled={isSaving}
              content={formState.cat_id ? "Save Category" : "Add Category"}
            />
          </Form.Group>
        </Form>
      </Segment>
    </>
  );
};

export default CategoryForm;
