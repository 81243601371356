import React from "react";
import { useSelector } from "react-redux";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { selectPayeeByPage } from "../../redux/entities/payeeSelectors";
import LoaderList from "../LoaderList";
import ModalPayeeForm from "./ModalPayeeForm";
import PayeeItem from "./PayeeItem";

const PayeeList = () => {
  // Selectors
  const filteredPayees = useSelector((state) => selectPayeeByPage(state));
  const isLoading = useSelector((state) => state.ui.form.payee.isLoading);
  console.log(`filteredPayees`, filteredPayees);

  // Render
  return (
    <Segment className="transactions-list__wrapper">
      {/* <Dimmer inverted active={false}>
        <Loader active inline="centered" />
      </Dimmer> */}
      {isLoading ? (
        <LoaderList />
      ) : filteredPayees.length > 0 ? (
        filteredPayees.map((payee) => (
          <PayeeItem key={payee.py_id} payee={payee} />
        ))
      ) : (
        <div className="transactions-list__empty">No payee found.</div>
      )}

      <ModalPayeeForm />
    </Segment>
  );
};

export default PayeeList;
