import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "semantic-ui-react";
import { getAccountsNameMap } from "../../redux/entities/accountsSelectors";
import { getCategoryNameMap } from "../../redux/entities/categoriesSelectors";
import { getPayeeNameMap } from "../../redux/entities/payeeSelectors";

const AppliedTransactionFilter = ({
  appliedAccounts,
  appliedCategories,
  appliedPayees,
  applyFilters,
}) => {
  const dispatch = useDispatch();
  // Selectors
  const accountNameMap =
    useSelector((state) => getAccountsNameMap(state)) || [];
  const categoryNameMap =
    useSelector((state) => getCategoryNameMap(state)) || [];
  const payeeNameMap = useSelector((state) => getPayeeNameMap(state)) || [];

  // Events
  const removeAppliedAccount = (accId) => {
    dispatch(
      applyFilters({
        accounts: appliedAccounts.filter((acc) => acc !== accId),
        categories: appliedCategories,
        payees: appliedPayees,
      })
    );
  };
  const removeAppliedCategory = (catId) => {
    dispatch(
      applyFilters({
        accounts: appliedAccounts,
        categories: appliedCategories.filter((cat) => cat !== catId),
        payees: appliedPayees,
      })
    );
  };
  const removeAppliedPayee = (pyId) => {
    dispatch(
      applyFilters({
        accounts: appliedAccounts,
        categories: appliedCategories,
        payees: appliedPayees.filter((py) => py !== pyId),
      })
    );
  };

  return (
    <>
      {appliedAccounts.length === 0 &&
      appliedCategories.length === 0 &&
      appliedPayees.length === 0 ? null : (
        <div className="transactions-filters-applied">
          {appliedAccounts.map((accId) => (
            <Label
              key={accId}
              content={accountNameMap[accId]}
              icon="credit card"
              onRemove={() => removeAppliedAccount(accId)}
            />
          ))}
          {appliedCategories.map((catId) => (
            <Label
              key={catId}
              content={categoryNameMap[catId]}
              icon="tag"
              onRemove={() => removeAppliedCategory(catId)}
            />
          ))}
          {appliedPayees.map((pyId) => (
            <Label
              key={pyId}
              content={payeeNameMap[pyId]}
              icon="user"
              onRemove={() => removeAppliedPayee(pyId)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default AppliedTransactionFilter;
