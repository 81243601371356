import React from "react";
import { useSelector } from "react-redux";
import { Dropdown, Form, Input } from "semantic-ui-react";
import { getAccountsAsOptions } from "../../redux/entities/accountsSelectors";

const TransactionFormAccount = ({
  label,
  accountId,
  onAccountChange,
  amount,
  onAmountChange,
}) => {
  const accountsOptions = useSelector((state) => getAccountsAsOptions(state));
  const isLoadingAccs = useSelector((state) => state.ui.form.account.isLoading);

  // console.log(`accountsOptions`, accountsOptions);

  return (
    <Form.Group>
      <Form.Field width={10} className="mobile-with-margin">
        <label>{label}</label>
        <Dropdown
          required
          selection
          options={accountsOptions}
          value={accountId}
          onChange={onAccountChange}
          loading={isLoadingAccs}
        />
      </Form.Field>
      <Form.Field width={6} className="mobile-with-margin input-right no-label">
        <Input
          required
          type="number"
          label="INR"
          labelPosition="right"
          // min={0.01}
          // step={1}
          value={amount}
          onChange={onAmountChange}
        />
      </Form.Field>
    </Form.Group>
  );
};

export default TransactionFormAccount;
