import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllAccounts } from "../../redux/ui/form/accountsUISlice";
import { Divider, Header, Button, Loader } from "semantic-ui-react";
import AccountForm from "../accounts/AccountForm";
import AccountList from "../accounts/AccountList";
import { getAccountsList } from "../../redux/entities/accountsSelectors";
import { completeSetup } from "../../redux/settings/settingsSlice";

const InitialSetup = () => {
  const dispatch = useDispatch();

  // Selectors
  const isSetupCompleting = useSelector(
    (state) => state.settings.isSetupCompleting
  );
  const accounts = useSelector((state) => getAccountsList(state));
  const statusLoadingSettings = useSelector(
    (state) => state.settings.statusLoadingSettings
  );
  // console.log("accounts", accounts);

  useEffect(() => {
    //Load Accounts from database
    dispatch(loadAllAccounts());
  }, [dispatch]);

  const handleCompleteSetup = () => {
    dispatch(completeSetup());
  };

  return statusLoadingSettings === "pending" ? (
    <Loader active content="Loading..."></Loader>
  ) : (
    <div className="container-raised-desktop">
      <Header as="h2" icon="settings" content="ND Money Tracker Setup" />
      <Divider />
      <Header as="h2">Accounts</Header>
      <p>
        Create accounts that you would like to keep track of.
        <br />
        It could be cash in your wallet, bank accounts, credit cards or even a
        loan to your friend.
      </p>
      <AccountForm />

      {accounts.length > 0 && (
        <div style={{ margin: "1em" }}>
          <AccountList openInModel={true} />
          <div className="form-submit">
            <Button
              primary
              content="Finish"
              onClick={handleCompleteSetup}
              loading={isSetupCompleting}
              disabled={isSetupCompleting}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InitialSetup;
