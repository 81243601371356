/* eslint-disable import/no-anonymous-default-export */
import AccountDetail from "../components/pages/AccountDetail";
import Accounts from "../components/pages/Accounts";
import Categories from "../components/pages/Categories";
import Dashboard from "../components/pages/Dashboard";
import Payees from "../components/pages/Payees";
import Reports from "../components/pages/Reports";
import Transactions from "../components/pages/Transactions";

export default [
  {
    path: "/",
    exact: true,
    showInMenu: true,
    label: "Dashboard",
    icon: "newspaper",
    component: Dashboard,
  },
  {
    path: "/transactions/:accountId?",
    link: "/transactions",
    exact: false,
    showInMenu: true,
    label: "Transactions",
    icon: "exchange",
    component: Transactions,
  },
  {
    path: "/accountdetail/:accountId",
    exact: false,
    showInMenu: false,
    label: "Account Detail",
    icon: "credit card",
    component: AccountDetail,
  },
  {
    path: "/accounts",
    // link: "/accounts",
    exact: false,
    showInMenu: true,
    label: "Accounts",
    icon: "credit card",
    component: Accounts,
  },
  {
    path: "/categories",
    exact: false,
    showInMenu: true,
    label: "Categories",
    icon: "sitemap",
    component: Categories,
  },
  {
    path: "/payees",
    exact: false,
    showInMenu: true,
    label: "Payees",
    icon: "users",
    component: Payees,
  },
  {
    path: "/reports",
    exact: false,
    showInMenu: true,
    label: "Reports",
    icon: "line chart",
    component: Reports,
  },
];
