import EntityMap from "../../entities/EntityMap";
import {
  TransationTypeT,
  getTransactionTypeLabel,
} from "../../entities/Transaction";
import { createSelector } from "reselect";

const { Expense, Transfer, Income } = TransationTypeT;

export const getCategoryList = (state, txType) =>
  EntityMap.map(state.entities.categories[txType], (categories) => ({
    ...categories,
  })).sort(
    // sortByName
    // sort by cat_full_name
    (a, b) => {
      if (a.cat_full_name < b.cat_full_name) return -1;
      if (a.cat_full_name > b.cat_full_name) return 1;
      return 0;
    }
  );

export const getAllCategoriesList = (state) => {
  const expenseCats = getCategoryList(state, Expense);
  const incomeCats = getCategoryList(state, Income);

  return [...new Set([...expenseCats, ...incomeCats])];
};

export const getCategoryOptions = createSelector(
  getCategoryList,
  (categories) => {
    // console.log(`categories`, categories);
    const options = [];
    for (const cat of categories) {
      options.push({
        key: cat.cat_id,
        value: cat.cat_id,
        text: cat.cat_full_name,
        // description: getTransactionTypeLabel(cat.cg_tx_type),
      });
    }
    return options;
  }
);

export const getAllCategoryOptions = createSelector(
  getAllCategoriesList,
  (categories) => {
    const options = [];
    // console.log(`payees`, payees);
    for (const cat of categories) {
      options.push({
        key: cat.cat_id,
        value: cat.cat_id,
        text: cat.cat_full_name,
        description: getTransactionTypeLabel(cat.cg_tx_type),
      });
    }
    return options;
  }
);

export const getCategoryNameMap = createSelector(
  getAllCategoriesList,
  (categories) =>
    categories.reduce((cat, category) => {
      cat[category.cat_id] = category.cat_full_name;
      return cat;
    }, {})
);

// export const getAllCategoryOptions = (state) =>
//   [
//     ...new Set([
//       ...state.entities.categories[Expense],
//       ...state.entities.categories[Income],
//     ]),
//   ].map((cat) => ({
//     key: cat.cat_id,
//     value: cat.cat_id,
//     text: cat.cat_name,
//     description: getKindLabel(cat.cat_group),
//   }));

// export const getExpenseCategoryList = (state) =>
//   EntityMap.map(state.entities.categories[Expense], (categories) => ({
//     ...categories,
//   })).sort(
//     // sortByName
//     // sort by cat_name
//     (a, b) => {
//       if (a.cat_name < b.cat_name) return -1;
//       if (a.cat_name > b.cat_name) return 1;
//       return 0;
//     }
//   );

// export const getIncomeCategoryList = (state) =>
//   EntityMap.map(state.entities.categories[Income], (categories) => ({
//     ...categories,
//   })).sort(
//     // sortByName
//     // sort by cat_name
//     (a, b) => {
//       if (a.cat_name < b.cat_name) return -1;
//       if (a.cat_name > b.cat_name) return 1;
//       return 0;
//     }
//   );

// export const getExpenseCategoryAsOptions = createSelector(
//   getExpenseCategoryList,
//   (categories) => {
//     const options = [];
//     // console.log(`payees`, payees);
//     for (const cat of categories) {
//       options.push({
//         key: cat.cat_id,
//         value: cat.cat_id,
//         text: cat.cat_name,
//       });
//     }
//     return options;
//   }
// );

// export const getIncomeCategoryAsOptions = createSelector(
//   getIncomeCategoryList,
//   (categories) => {
//     const options = [];
//     // console.log(`payees`, payees);
//     for (const cat of categories) {
//       options.push({
//         key: cat.cat_id,
//         value: cat.cat_id,
//         text: cat.cat_name,
//       });
//     }
//     return options;
//   }
// );

// export const getCategoryOptions = (state) => {
//   console.log(
//     `state.entities.categories[state.ui.form.transaction.tx_type]`,
//     state.entities.categories[state.ui.form.transaction.tx_type]
//   );
//   state.entities.categories[state.ui.form.transaction.tx_type].map((cat) => ({
//     key: cat.cat_id,
//     value: cat.cat_id,
//     text: cat.cat_name,
//   }));
// };
