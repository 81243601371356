import { createSlice } from "@reduxjs/toolkit";
import EntityMap from "../../entities/EntityMap";
import { TransationTypeT } from "../../entities/Transaction";
const { Expense, Transfer, Income } = TransationTypeT;

//Initial States
const initialState = {
  [Expense]: EntityMap.fromArray([]),
  [Transfer]: EntityMap.fromArray([]),
  [Income]: EntityMap.fromArray([]),
};

const categoryGroupSlice = createSlice({
  name: "categoryGroup",
  initialState,
  reducers: {
    loadExpenseCategoryGroupSuccess: (state, { payload }) => {
      const categoryGroup = payload;
      return {
        ...state,
        [Expense]: EntityMap.fromArray(categoryGroup, "cg_id"),
      };
    },

    loadIncomeCategoryGroupSuccess: (state, { payload }) => {
      const categoryGroup = payload;
      return {
        ...state,
        [Income]: EntityMap.fromArray(categoryGroup, "cg_id"),
      };
    },

    saveCategoryGroupSuccess: (state, { payload }) => {
      const cg = payload;
      // return EntityMap.set(state, cat, "py_id");
      return {
        ...state,
        [cg.cg_tx_type]: EntityMap.set(state[cg.cg_tx_type], cg, "cg_id"),
      };
    },
  },
});

export const {
  loadExpenseCategoryGroupSuccess,
  loadIncomeCategoryGroupSuccess,
  saveCategoryGroupSuccess,
} = categoryGroupSlice.actions;

export default categoryGroupSlice.reducer;
