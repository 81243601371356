import React from "react";
import { useSelector } from "react-redux";
import {
  getNetAssets,
  getNetBalance,
} from "../../redux/entities/accountsSelectors";
import Amount from "../Amount";

const NetAssets = () => {
  //Selectors
  const netAssets = useSelector((state) => getNetAssets(state));

  return (
    <div className="net-worth__total">
      <Amount value={netAssets} />
    </div>
  );
};

export default NetAssets;
