import Currency from "./Currency";

export enum TransationTypeT {
  Expense,
  Transfer,
  Income
}

const { Expense, Transfer, Income } = TransationTypeT;
const TransactionTypeToText: { [kind in TransationTypeT]: string } = {
  [Expense]: 'Expense',
  [Transfer]: 'Transfer',
  [Income]: 'Income'
};

export const defaultKind = Expense;
export const recentListLimit = 10;
export const rowsPerPage = 15;
export const pagerSizeMobile = 5;
export const pagerSizeDesktop = 9;

export function getTransactionTypeLabel(kind: TransationTypeT) {
  return TransactionTypeToText[kind];
}

interface TransactionBaseT {
  tx_type: TransationTypeT;
  tx_acc_id: number;
  tx_cat_id: number;
  tx_payee_id?: number;
  tx_linked_acc_id?: number;
  tx_note?: string;
  tx_date: string;
}

export interface TransactionFormT extends TransactionBaseT {
  tx_id?: number;
  tx_amount: string;
  tx_linked_amount?: string;
}

export interface TransactionStateT extends TransactionBaseT {
  tx_id: number;
  tx_amount: number;
  tx_linked_amount?: number;
}

export interface TransactionDbT extends TransactionBaseT {
  tx_id?: number;
  tx_amount: number;
  tx_linked_amount?: number;
}

export function formToState(form: TransactionFormT): TransactionStateT {
  return {
    ...form,
    tx_id: form.tx_id || -1,
    tx_amount: Currency.stringToNumber(form.tx_amount),
    tx_payee_id: form.tx_payee_id || undefined,
    tx_linked_acc_id: form.tx_type === Transfer ? form.tx_linked_acc_id : undefined,
    tx_linked_amount: form.tx_type === Transfer && form.tx_linked_amount ? Currency.stringToNumber(form.tx_linked_amount):undefined,
    tx_note: form.tx_note || undefined,

  };
}
export function formToDb({
  tx_id,
  tx_type,
  tx_acc_id,
  tx_amount,
  tx_cat_id,
  tx_payee_id,
  tx_linked_acc_id,
  tx_linked_amount,
  tx_note,
  tx_date
}: TransactionFormT): TransactionDbT {
  return {
    tx_id: tx_id,
    tx_type,
    tx_acc_id,
    tx_amount: Currency.stringToNumber(tx_amount),
    tx_cat_id,
    tx_payee_id: tx_payee_id || undefined,
    tx_linked_acc_id: tx_type === Transfer ? tx_linked_acc_id : undefined,
    tx_linked_amount: tx_type === Transfer && tx_linked_amount ? Currency.stringToNumber(tx_linked_amount):undefined,
    tx_note: tx_note || undefined,
    tx_date
  };
}

export function stateToDb({
  tx_id,
  tx_type,
  tx_acc_id,
  tx_amount,
  tx_cat_id,
  tx_payee_id,
  tx_linked_acc_id,
  tx_linked_amount,
  tx_note,
  tx_date
}: TransactionStateT): TransactionDbT {
  return {
    tx_id: tx_id !== -1 ? tx_id : undefined ,
    tx_type,
    tx_acc_id,
    tx_amount,
    tx_cat_id,
    tx_payee_id,
    tx_linked_acc_id,
    tx_linked_amount,
    tx_note,
    tx_date
    }
  };

export function dbToState({
  tx_id,
  tx_type,
  tx_acc_id,
  tx_amount,
  tx_cat_id,
  tx_payee_id,
  tx_linked_acc_id,
  tx_linked_amount,
  tx_note,
  tx_date
}: TransactionDbT): TransactionStateT {
  return {
    tx_id : tx_id || -1,
    tx_type,
    tx_acc_id,
    tx_amount,
    tx_cat_id,
    tx_payee_id,
    tx_linked_acc_id,
    tx_linked_amount,
    tx_note,
    tx_date
    }
  };

export function dbToForm({
  tx_id,
  tx_type,
  tx_acc_id,
  tx_amount,
  tx_cat_id,
  tx_payee_id,
  tx_linked_acc_id,
  tx_linked_amount,
  tx_note,
  tx_date
}: TransactionDbT): TransactionFormT {
  return {
    tx_id,
    tx_type,
    tx_acc_id,
    tx_amount: Currency.numberToString(tx_amount),
    tx_cat_id,
    tx_payee_id,
    tx_linked_acc_id,
    tx_linked_amount: tx_linked_amount ? Currency.numberToString(tx_amount): "",
    tx_note,
    tx_date
    }
  };
