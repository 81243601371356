import React from "react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";
import { TransationTypeT } from "../../entities/Transaction";
import { getFilterTransactionTotal } from "../../redux/entities/transactionsSelectors";
import Amount from "../Amount";
import LoaderList from "../LoaderList";
import LoaderListRtl from "../LoaderListRtl";

const TransactionListFooter = () => {
  // Selectors
  const filterIncomeTotal = useSelector((state) =>
    getFilterTransactionTotal(TransationTypeT.Income)(state)
  );
  const filterExpenseTotal =
    useSelector((state) =>
      getFilterTransactionTotal(TransationTypeT.Expense)(state)
    ) * -1;
  const isLoading = useSelector(
    (state) => state.ui.transaction.filter.isLoading
  );
  // console.log(`filterIncomeTotal`, filterIncomeTotal);
  // console.log(`filterExpenseTotal`, filterExpenseTotal);

  // Render
  return (
    <div className="transactions-list-footer">
      {isLoading ? (
        <LoaderListRtl />
      ) : (
        <Table fixed basic="very" unstackable>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Total income</Table.Cell>
              <Table.Cell textAlign="right">
                <Amount value={filterIncomeTotal} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Total expense</Table.Cell>
              <Table.Cell textAlign="right">
                <Amount value={filterExpenseTotal} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell />
              <Table.Cell textAlign="right">
                <Amount value={filterIncomeTotal + filterExpenseTotal} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </div>
  );
};

export default TransactionListFooter;
