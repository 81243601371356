import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import {
  DateFilterRangeT,
  getDateFilterRangeEnd,
  getDateFilterRangeStart,
  getDateRangeFilterOptions,
} from "../../entities/transaction/DateFilterRange";
import { getAccountsAsOptions } from "../../redux/entities/accountsSelectors";
import { getAccountDetailState } from "../../redux/ui/accountdetail/accountDetailUISelectors";
import {
  changeAccount,
  changeFilterDate,
  changeFilterDateRangeType,
  toggleFilterCalendar,
} from "../../redux/ui/accountdetail/accountDetailUISlice";
import { openTransactionInModal } from "../../redux/ui/form/transactionsUISlice";
import Amount from "../Amount";
import LoaderTwoLine from "../LoaderTwoLine";
import ModalDateRangeSelection from "../ModalDateRangeSelection";

const AccountTransactionFilterOptions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Selectors
  const options = getDateRangeFilterOptions();
  const dateRangeType = useSelector(
    (state) => state.ui.accountDetail.filterDateRangeType
  );
  const accDetailState = useSelector((state) => getAccountDetailState(state));
  const isCalendarOpen = useSelector(
    (state) => state.ui.accountDetail.isCalendarOpen
  );
  const accId = useSelector((state) => state.ui.accountDetail.accId);
  const accountsOptions = useSelector((state) => getAccountsAsOptions(state));
  const isMobile = useSelector((state) => state.ui.isMobile);
  const isLoadingAccounts = useSelector(
    (state) => state.ui.form.account.isLoading
  );
  const isLoadingDetails = useSelector(
    (state) => state.ui.accountDetail.isLoadingDetail
  );

  // Events
  const handleDateRange = (value) => {
    // if (value === lastValue && value !== DateFilterRangeT.custom) return;
    if (value === DateFilterRangeT.custom) {
      dispatch(toggleFilterCalendar());
    } else {
      dispatch(
        changeFilterDate({
          dateStart: getDateFilterRangeStart(value),
          dateEnd: getDateFilterRangeEnd(value),
        })
      );
      dispatch(changeFilterDateRangeType(value));
    }

    // lastValue = value;
  };

  const handleAccountChange = (accId) => {
    history.push(`/accountdetail/${accId}`);
  };

  return (
    <>
      <div className="container-header">
        <Button.Group basic fluid={isMobile}>
          <Button
            icon="plus"
            labelPosition="left"
            content="New"
            onClick={() =>
              dispatch(
                openTransactionInModal({
                  ...{ tx_acc_id: accId },
                })
              )
            }
          />

          <Dropdown
            button
            className="icon"
            options={options}
            defaultValue={dateRangeType}
            onChange={(_, { value }) => handleDateRange(value)}
            labeled
            icon="calendar"
          />
        </Button.Group>
        <Button.Group basic fluid>
          <Dropdown
            button
            className="icon"
            loading={isLoadingAccounts}
            options={accountsOptions}
            value={accId}
            labeled
            icon="credit card"
            onChange={(_, { value }) => handleAccountChange(value)}
          />
        </Button.Group>
        <ModalDateRangeSelection
          isCalendarOpen={isCalendarOpen}
          changeFilterDate={changeFilterDate}
          toggleFilterCalendar={toggleFilterCalendar}
        />
      </div>

      <div className="account-detail">
        {isLoadingDetails ? (
          <LoaderTwoLine />
        ) : (
          <>
            <div className="account-detail-name">{accDetailState.accName}</div>
            <div className="account-detail-state">
              <div className="account-detail-balance">Balance:</div>
              <Amount value={accDetailState.accBal} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AccountTransactionFilterOptions;
