import { createSelector } from "reselect";
import EntityMap from "../../entities/EntityMap";
import { rowsPerPayeePage } from "../../entities/Payee";
import { getPayeePage } from "../ui/payee/filteredPayeeSelectors";

export const selectPayeesFromEntity = (state) => state.entities.payee;

export const getPayeeList = (state) =>
  EntityMap.map(state.entities.payee, (payee) => ({
    ...payee,
  })).sort(
    // sort by py_name
    (a, b) => {
      if (a.py_name < b.py_name) return -1;
      if (a.py_name > b.py_name) return 1;
      return 0;
    }
  );

export const getPayeeAsOptions = createSelector(getPayeeList, (payees) => {
  const options = [];
  // console.log(`payees`, payees);
  for (const payee of payees) {
    options.push({
      key: payee.py_id,
      value: payee.py_id,
      text: payee.py_name,
    });
  }
  return options;
});

export const getPayeeNameMap = createSelector(getPayeeList, (payees) =>
  payees.reduce((py, payee) => {
    py[payee.py_id] = payee.py_name;
    return py;
  }, {})
);

// export const selectPayeeByPage = createSelector(
//   selectPayeesFromEntity,
//   getPayeePage,
//   (payees, page) =>
//     EntityMap.map(
//       payees,
//       (payee) => payee,
//       rowsPerPayeePage,
//       (page - 1) * rowsPerPayeePage
//     )
// );

export const selectPayeeByPage = createSelector(
  getPayeeList,
  getPayeePage,
  (payees, page) =>
    payees.slice((page - 1) * rowsPerPayeePage, page * rowsPerPayeePage)
);
